import {
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAILURE,
    GET_LIST_ALL_REPORT_SUCCESS,
    GET_LIST_ALL_REPORT_FAILURE,
} from "../actions/Types";
import redexState from "./InitalState";



// get All list users
export const reports = (state = redexState.reportsInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_REPORT_SUCCESS:
            return {
                ...state,
                ...action.payload.reportInfo
            }
        case GET_LIST_ALL_REPORT_FAILURE:
            return {
                ...state,
            }
        default:
            return {...state}
    }

}