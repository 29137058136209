import {  
    GET_PROFILE_TYPE_ARRAY_SUCCESS
} from "../actions/Types";

import redexState from "./InitalState";


const InitalState = {
    profileTypeData:[]
}


// get All list users
export const profileType = (state = InitalState , action) => {
    switch (action.type) {
      
        case GET_PROFILE_TYPE_ARRAY_SUCCESS:
            return {
                ...state,
                profileTypeData:action.payload
            }        
        default:
            return {...state}
    }

}