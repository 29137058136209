/**
 * @about site edit file edit site that already created
 * 
 */

// lib
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Chips, { Chip } from 'react-chips'
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// custome & components
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';

// actions
import { getAllUserList } from "../../redux/actions/User";
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




// loader
import Loader from "../common/Loader";
import { Filter , Rightarrow } from '../Svg'
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ReportFolder from "./ReportFolder"
import ResourceFolder from "./SiteResourceFolder";
import MediaFolderList from "./SiteMediaFolder";
import SiteTaskEdit from "./SiteManagemeantEdit";
import SiteMap from "./SiteMapView";

// site edit
import SiteEditGeneral from "./siteEditGeneral"
import SiteEditOverView from "./SiteEditOverview"
import Analysis from "./SiteAnalysis";
import Management from "./SiteManagement";

// site edit screen
class SiteEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: false,
            inviteNewPopupOpen: false,
            chips: [],
            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',
            tagId: '',
            challanges: '',
            strengths: '',
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',
            user: [],
            showTab: 'General Info'
        }

    }


    onChange = chips => {
        this.setState({ chips });
    }


    componentDidMount() {
        this.props.action.getAllUserList();
        this.getSiteDetails();
        this.props.action.getAllUserList();
        this.setTab()
    }

    setTab = () => {
        let arr = this.props.location.pathname.split("/")

        if (arr.indexOf("report") != -1)
            this.setState({ showTab: "Reports" })
        else if (arr.indexOf("general") != -1)
            this.setState({ showTab: "General Info" })
        else if (arr.indexOf("overview") != -1)
            this.setState({ showTab: "Overview" })
        else if (arr.indexOf("task") != -1)
            this.setState({ showTab: "Management" })
        else if (arr.indexOf("media") != -1)
            this.setState({ showTab: "Gallery" })
        else if (arr.indexOf("client") != -1)
            this.setState({ showTab: "Clients" })
        else if (arr.indexOf("resource") != -1)
            this.setState({ showTab: "Resources" })
        else if (arr.indexOf("map") != -1)
            this.setState({ showTab: "Mapview" })

            else if (arr.indexOf("analysis") != -1)
            this.setState({ showTab: "Analytics" })
    }

    // getUser details
    getSiteDetails = () => {
        try {
            if (this.props.match.params)
            _Api(Url.ADMIN_VIEW_SITE.method, Url.ADMIN_VIEW_SITE.url, "",this.props.match.params.id  )
                    .then(resp => {
                 
                        const {
                            created,
                            firstName,
                            address,
                            city,
                            desc,
                            folder,
                            lastName,
                            password,
                            phoneNumber,
                            role,
                            street,
                            status,
                            state,
                            tags,
                            user,
                            lat,
                            long,
                            challanges,
                            strengths,
                            _id } = resp.responseData.siteData

                        this.setState({
                            challanges,
                            strengths,
                            latitude: lat,
                            longitude: long,
                            address,
                            city,
                            state,
                            street,
                            description: desc,
                            user, showUserName: resp.responseData.siteData.siteName, name: resp.responseData.siteData.siteName, isLoadingState: false, folderName: folder.folderName, created, firstName, folder, lastName, password, phoneNumber, role, status, tags, _id
                        }, () => this.setState({ isLoadingState: false }))

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }

    //handle on handleChangeAll
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' });

    }



    // redirect to
    goBack = () => {
        this.props.history.goBack()
    }
    // adding popup
    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })
       

    }
    handleClickTag = (e) => {      

        this.setState({ showTab: e })
        if (e == "General Info")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/general`);
        else if (e == "Overview")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/overview`);
        else if (e == "Reports")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/report`);

        else if (e == "Resources")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/resource`);
        else if (e == "Gallery")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/media`);
        else if (e == "Clients")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/client`);
        else if (e == "Mapview")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/map`);

        else if (e == "Management")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/task`);

            else if (e == "Analytics")
            this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/analysis`);


    } 
    render() {
        return (
            <>
                {this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header />
                    <div className="body_header folder">
                        <h6 className="headingH6"><Link to="/sites">SITES </Link>
                        <span className="cm_arrow"><Rightarrow/></span>
                        &nbsp;<Link to={{ pathname: `/sites/${this.props.match.params.folderId}`, state: { state: this.state.folderName } }}   >{this.state.folderName}</Link>&nbsp;
                        <span className="cm_arrow"><Rightarrow/></span>
                        &nbsp;{this.state.showUserName}</h6>

                    </div>

                    <div className="">
                        {/* <div className="btnTabs">
                            <button type="button" class="btn btn-primary btn-blue mR-20" onClick={this.openInviteNewPopupOpen} >Create new</button>
                        </div> */}
                        <Tabs activeKey={this.state.showTab} id="uncontrolled-tab-example" onSelect={this.handleClickTag} className="cm_tab">
                            <Tab eventKey="General Info" title="General Info" >

                            </Tab>
                            <Tab eventKey="Overview" title="Overview" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Mapview" title="Mapview" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Reports" title="Reports" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Gallery" title="Gallery" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Resources" title="Resources" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Management" title="Management" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Analytics" title="Analytics" >
                                <div>

                                </div>
                            </Tab>
                            {/* <Tab eventKey="Clients" title="Clients" >
                                <div>

                                </div>
                            </Tab> */}

                        </Tabs>
                    </div>
                    <Switch>
                        <Route exact path={`/sites/edit/:folderId/:id/overview`} component={SiteEditOverView} />
                        <Route exact path={`/sites/edit/:folderId/:id/general`} component={SiteEditGeneral} />
                        <Route exact path={"/sites/edit/:folderId/:id/report"} component={ReportFolder} />
                        <Route exact path={"/sites/edit/:folderId/:id/report/data"} component={ReportFolder} />
                        <Route exact path={"/sites/edit/:folderId/:id/report"} component={ReportFolder} />
                        <Route exact path={"/sites/edit/:folderId/:id/report/:eId"} component={ReportFolder} />
                        <Route exact path={`/sites/edit/:folderId/:id/resource`} component={ResourceFolder} />
                        <Route exact path={`/sites/edit/:folderId/:id/resource/data`} component={ResourceFolder} />                        
                        <Route exact path={`/sites/edit/:folderId/:id/resource/:eId`} component={ResourceFolder} />

                        <Route exact path={`/sites/edit/:folderId/:id/media`} component={MediaFolderList} />
                        <Route exact path={`/sites/edit/:folderId/:id/media/:mId`} component={MediaFolderList} />
                        <Route exact path={`/sites/edit/:folderId/:id/media/data`} component={MediaFolderList} />

                        <Route exact path={`/sites/edit/:folderId/:id/analysis`} component={Analysis} />
                        <Route exact path={`/sites/edit/:folderId/:id/task`} component={Management} />
                       
                        <Route exact path={`/sites/edit/:folderId/:id/task/edit/:cId`} component={SiteTaskEdit} />
                        <Route exact path={`/sites/edit/:folderId/:id/map`} component={SiteMap} />
                    </Switch>
                </div>
            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
  
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllUserList, }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteEdit);