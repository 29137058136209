import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import Select from 'react-select';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
//react router dom
import { Link } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList } from "../../redux/actions/User";
import { getAllTags } from "../../redux/actions/Get";
// inline error
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader";
import AddTag from "../../components/common/add_tag/AddTag"
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


class ResourceEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: true,
            inviteNewPopupOpen: false,
            selectedOption: '',
            created: "",
            email: "",
            firstName: "",
            folder: "",
          
            role: 1,
            status: 1,
            tags: "",
            _id: "",
       
            showDeleteModal: false,
          
            folderName:'',
            showName:'', 
         // second 
         resourceName: '',
         resourceNameError: '',
         reportDescription: '',
         reportDescriptionError: '',
         reportUrl: '',
         reportUrlError:'',
         reportFile: '',
         reportProjectId: '',
         reportSiteId: '',
         reportTagId: '',
         desc: "",
         folderId:"",
         fileType:'',
         fileData:'',
         url:"",

         fileUploadFileError: '',
         fileName:'Choose file...',
         changeFile:false,
         hideInput:'',

         showUrl:''

        }
    }

    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value,resourceNameError:'', reportDescriptionError:'', reportUrlError:'', },()=>{
            setTimeout(()=>{
                if (this.state.reportUrl !='')
                this.setState({hideInput:'file'})
            else  if (this.state.fileName == 'Choose file...' && this.state.reportUrl =='')
               this.setState({hideInput:''})
               },300)
        })
    }


    handleChange = selectedOption => {
        this.setState({ selectedOption });
    }

    componentDidMount() {
       this.props.action.getAllTags({status:"1"})
        this.getResourceDetails()


    }

    // getUser details
    getResourceDetails = () => {
        try {
            if (this.props.match.params) 
            _Api(Url.ADMIN_VIEW_RESOURCE.method, Url.ADMIN_VIEW_RESOURCE.url, "", this.props.match.params.eId )
                    .then(resp => {
                        const { created, 
                            desc,
                        resourceName,
                        tag,        
                            folderId,
                            folderName,
                            fileData,
                            fileType,
                            _id } = resp.responseData.resourceData
                        this.setState({showUrl:fileData, fileType,reportDescription: resp.responseData.resourceData.desc ,isLoadingState: false,folderName,showName:resourceName, folderName, resourceName, folderId , _id },
                           
                            )

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }
    
   
 
    
   


    handleOnSubmitForm = (e) => {
        const UrlRegx =   /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      
        if (this.state.resource == "") {
            this.setState({ resourceError: "*Please enter report name." })
        }
        if (this.state.reportDescription == "") {
            this.setState({ reportDescriptionError: "*Please enter description name." })
        }
        else if(this.state.reportUrl != "" &&  !UrlRegx.test(this.state.reportUrl))
    {
        this.setState({ reportUrlError: "*Please enter valid url." })
    }
   
        
        else if (this.state.reportFile && this.state.reportFile.type.split("/")[0] != "text" && this.state.reportFile.type.split("/")[0] != "application") {
        
        
        this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile && this.state.reportFile.type.split("/")[1] == "exe" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage") {
        
        
        this.setState({ fileUploadFileError: "*Please add a valid file.", })
        } 
        else if (this.state.reportFile && this.state.reportFile.type.split("/")[1] == "zip" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/vnd.android.package-archive") {
        
        this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }


       
     
        else {

            this.OnEditReport()
        }
    }



    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletUserId: id });
    }


    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (statusCode, userId) => {
    
      try{
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            resourceId: userId
        }

        _Api(Url.ADMIN_CHANGE_STATUS_RESOURCE.method, Url.ADMIN_CHANGE_STATUS_RESOURCE.url, req )
                .then(resp => {
                    this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '' })
                    notify("success", resp.responseData.message)
                    // this.props.history.push(`/users/categories`);
                    this.props.history.goBack();   

                }).catch(err => {
                    this.setState({ isLoadingState: false, showDeleteModal: false })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)
                })

        } catch (error) {

        }
    }

   
    onImageUpload = e => {
        try{
        let file = e.target.files[0]
        this.setState({hideInput:'url', reportFile: file,fileName:file.name, fileUploadFileError: '',changeFile:true })
    }catch(err){}
}




    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    // close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    // on edit submit resource
    OnEditReport = () => {
        this.setState({ isLoadingState: true, })

        try {



            let formData = new FormData()
            formData.append("resourceId", this.state._id);
            formData.append("resourceName", this.state.resourceName);
            formData.append("desc", this.state.reportDescription)
            formData.append("folder", this.props.match.params.eId)
           formData.append("url", this.state.reportUrl);
            formData.append("project", this.state.reportProjectId)
            formData.append("tag", this.state.reportTagId  == "" ? this.props.tagsList[0]._id :this.state.reportTagId)
            formData.append("fileData", this.state.reportFile)

            formData.append("siteId", this.state.reportSiteId)
       
        
            _Api(Url.ADMIN_EDIT_RESOURCE.method, Url.ADMIN_EDIT_RESOURCE.url,formData )
                .then(resp => {
                    notify("success", resp.responseData.message)
                    // this.getUserDetails()
                    this.setState({ isLoadingState: false, })
                    this.props.history.goBack(); 


                }).catch(err => {
                    this.setState({ isLoadingState: false, })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })


        } catch (err) {


        }
    }
  
    handleAddTag = () => {
        this.clickChild()
        this.setState({inviteNewPopupOpen:true})
    }

    render() {
        const { selectedOption } = this.state;
        return (
            <>
                {this.state.isLoadingState && <Loader />}
               
                  
                    <div className="row">
                        <div className="col-sm-8">
                            <form >
                            <div class="form-group">
                                <label for="email">Resource Name</label><span className = "impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.resourceNameError != '' ? 'is-invalid' : ''}`}
                                    id="resource"
                                    name="resourceName"
                                    placeholder="Enter Resource Name"
                                    maxLength={20}
                                    value={this.state.resourceName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.resourceError}
                                />
                            </div>


                            <div class="form-group">
                                <label for="email">Description</label><span className = "impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.reportDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder=" Enter Resource Description"
                                    maxLength={256}
                                    name="reportDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.reportDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.reportDescriptionError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">Resource</label><br/>
                                <a  href={this.state.showUrl} target="_blank" rel="noopener noreferrer" >{this.state.showUrl}</a>
                            </div>


                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">URL</label><span className="impFields">*</span><br/> 
                                    <input
                                    disabled = { this.state.hideInput == "url"} 
                                        type="url"
                                        className={`form-control ${this.state.reportUrlError != '' ? 'is-invalid' : ''}`}
                                        id="reportUrl"
                                        placeholder="Enter url"
                                        maxLength={256}
                                        name="reportUrl"
                                        autocomplete="off"
                                        required
                                        value={this.state.reportUrl}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.reportUrlError}
                                    />

                                </div>
                            </div>                         
                          
                              

                                <div class="form-group row df-end">
                                <div className="col-md-12">
                                    <InlineError
                                        message={this.state.emptyFileError}
                                    />
                                  <label className="text-center display-b" style={{height:'auto'}}>OR</label>
                                     <label for="email">Resource file</label><span className="impFields">*</span><br/>                                    
                                    <div class="custom-file">                                        
                                        <input disabled = {this.state.hideInput =="file"} id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                      
                                         accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                         />
                                         <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                    </div>

                                    <InlineError
                                        message={this.state.fileUploadFileError}
                                    />
                               
                            </div>
                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>
                                    <select class="form-control" id="sel1" name="reportTagId" onChange={this.handleOnChange}>
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <br />                         
                                <div class="form-group">
                                    <button type="button" class="btn btn-danger mR-20" onClick={this.handleDeleteModal}>Delete</button>
                                    <button type="button" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>

                            </form>
                        </div>
                    </div>
                
                <AddTag
                   setClick={click => this.clickChild = click}
                   closeModalAddTag = {()=> this.setState({inviteNewPopupOpen:true})}
                />
                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want delete this resource ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20"  onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button className="btn btn-primary btn-sm btn-blue " onClick={() => this.onUserAction("2", this.state._id)}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllUserList, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEdit);

