import {
    RESET_STORE_SUCCESS,
    RESET_STORE_FAILURE


} from "../actions/Types";




// reset store
export const resetStore = req => dispatch => {

    dispatch({type:RESET_STORE_SUCCESS})

     

}


