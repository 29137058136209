import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    ADD_NEW_RESOURCE_SUCCESS,
    ADD_NEW_RESOURCE_FAILURE,
    GET_LIST_ALL_RESOURCE_SUCCESS,
    GET_LIST_ALL_RESOURCE_FAILURE
} from "../actions/Types";

import redexState from "./InitalState";




// get All list resources
export const resources = (state = redexState.resourcesInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_RESOURCE_SUCCESS:
            return {
                ...state,
                ...action.payload.resourceInfo
            }
        case GET_LIST_ALL_RESOURCE_FAILURE:
            return {
                ...state,
            }
        default:
            return {...state}
    }

}