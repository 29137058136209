

import React, { Component } from 'react';

import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';
import dateFormat from "dateformat"
import { Spinner } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../../redux/actions/User";
import { addNewSite, getAllSiteList } from "../../../redux/actions/Site";
import { addNewProject, getAllProjectList } from "../../../redux/actions/Project";


// loader
import Loader from "../../common/Loader";
// inline error
import InlineError from "../../../components/common/InlineError";
// validation
import { validateEmail, validateMobileNo1 } from "../../../utils/Validation";
import { _getUsersList } from "../../../config/api/UserService";
import { _viewProject } from "../../../config/api/ProjectServices";


// services 
import { _actionForUser, _checkEmailAvaliblity, _getProfileType } from "../../../config/api/UserService";
// services 
import { _actionForSite } from "../../../config/api/SiteServices";


// notify
import { notify } from "../../../components/common/Toaster";

import AddTag from "../../../components/common/add_tag/AddTag"

// import AddProject from "../../../components/common/add_project/AddProject"

import AddUser from "../add_user/AddUser";
import AddProject from "../add_project/AddProject";
import { getAllTags, getAllFolders } from "../../../redux/actions/Get";
import AddFolder from "../../add_folder_common/AddFolderTag";


import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from '../../../utils/MyFilter'

import { Select, Icon } from 'antd';

// url & api services
import Url from "../../../config/env/BaseUrl";
import { _Api } from "../../../config/api/Api";




var actionArr = []
const { Option } = Select;

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this site',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,




            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',

            //validation fields  
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            // userId: '',


            mapContent: '',
            mapContentError: '',
            projectId: '',

            fileName: 'Choose file...',
            fileUploadFileError: '',
            coverImg: '',

            folderIdError: '',
            folderId: '',
            projectError: '',

            userId: [],
            clientId: [],
            clientName: '',
            clientIdError: '',
            clientUserList: [],
            extraUserData: [],


            showProfileModal: false,
            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',
            toggleState:false,
            isSpinner:true,


        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getAllUserList({ status: "1", tagType: 4, folder: "" });
        this.props.action.getAllProjectList({ status: "1", tagType: 1, folder: "" })
        this.props.setClick(this.openInviteNewPopupOpen);
       
        this.getDataProfileType();




    }



    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 4)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)
        let arr6 = await getDataAndFilter(resp.responseData.profileInfo, 6)


        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                isChecked: false,
                isOther: item1.levelName == "OTHER",
                inputValue: '',
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    isChecked: false,
                    isOther: data.levelName == "OTHER",
                    inputValue: '',
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        isChecked: false,
                        isOther: data2.levelName == "OTHER",
                        inputValue: '',
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            isChecked: false,
                            isOther: data3.levelName == "OTHER",
                            inputValue: '',
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                                isChecked: false,
                                isOther: data4.levelName == "OTHER",
                                inputValue: '',
                                children: arr6.filter(item6 => item6.parentLevel == data4._id).map((data5, index5) => ({

                                    value: `${data5._id} `,
                                    label: data5.levelName,
                                    isChecked: false,
                                    isOther: data5.levelName == "OTHER",
                                    inputValue: '',
                                }))
                            }))
                        }))
                    }))
                }))


            }
        ))


        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }


    handleProfileModal = () => {
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })
    }



    onSubmitCheck = () => {
     
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })

    }

    handleSecleted = (e) => {
        this.setState({ checked: e })
    }




    countEmailAvaliblity = (email) => {
        _checkEmailAvaliblity({ email })
            .then(resp => {

                this.setState({ emailAvaliblity: false, emailAvaliblityMessage: '' })

            }).catch(err => {

                this.setState({ emailAvaliblity: true, emailAvaliblityMessage: err.error.message })


            })
            .catch(err => { })
    }



    //handle on handleOnChange
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, projectError: '', nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' });

    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
        let req = {
            folderType: 2
        }
        this.props.action.getAllFolders(req)



    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
        this.props.closeModalAddTag &&
            this.props.closeModalAddTag(true)
    }

    // clear fields
    clearFields = () => {
        this.setState({
            projectId: '',
            name: '', description: '',
            address: '', street: '', city: '',
            state: '', latitude: '', longitude: '',
            nameError: '', descriptionError: '',
            addressError: '', streetError: '', cityError: '',
            stateError: '', latitudeError: '', longitudeError: '',

            fileName: 'Choose file...',
            fileUploadFileError: '',
            coverImg: '',
            projectError: '',
            userId: [],
            clientId: [],
            clientName: '',
            clientIdError: '',
            // clientUserList: [],
            extraUserData: [],
            checked:[]



        })


        this.getDataProfileType()

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        let { name, description, address, street, city, state, latitude, longitude } = this.state;

        if (name == "") {
            this.setState({ nameError: "*Please enter name." })
        }
        else if (description == "") {
            this.setState({ descriptionError: "*Please enter description." })
        }
        else if (this.state.folderId == "") {
            this.setState({ folderIdError: "*Please select folder" })
        }
        else if (this.state.coverImg == "") {

            this.setState({ fileUploadFileError: "*Please add a  cover image.", })
        }

        else if (this.state.coverImg != "" && this.state.coverImg.type.split("/")[0] != "image") {

            this.setState({ fileUploadFileError: "*Please add a valid cover image.", })

        }
        else if (this.state.projectId == "") {
            this.setState({ projectError: "*Please select a  project" })
        }
        // else if (this.state.mapContent == "") {
        //     this.setState({ mapContentError: "*Please enter map content." })

        // }
        else if (this.state.checked.length == 0) {
            this.setState({ profileError: "*Please add profile." })

        }



        else {




            let formData = new FormData()
            formData.append("siteName", name);
            formData.append("desc", description)
            formData.append("folder", this.state.folderId)
            formData.append("user", this.state.userId);
            formData.append("tag", this.state.tagId)
            formData.append("cover", this.state.coverImg)
            formData.append("city", city)
            formData.append("state", state)
            formData.append("lat", latitude)
            formData.append("long", longitude)
            formData.append("code", this.state.mapContent)
            formData.append("projectType", 'S')
            formData.append("project", this.state.projectId)
            formData.append("address", address)
            formData.append("clientId", this.state.clientId);
            formData.append("profile", JSON.stringify(this.state.nodeData))



            let req2 = {

            }

            if (e.target.name == "save") {

                this.props.action.addNewSite(formData, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewSite(formData, req2)
                this.clearFields();
            }
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this site" : status == 0 ? "Inactive this site" : "delete this site" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            siteId: [actionArrr].toString()
        }

        _actionForSite(req)
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })
                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditsites = (user) => {

        this.props.history.push(`/sites/edit/${this.props.match.params.id}/${user._id}/general`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.dataCount) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            folder: "this.props.match.params.id",
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }


        this.props.action.getAllSiteList(req);
    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }

    onImageUpload = e => {
        try {
            let file = e.target.files[0]
            this.setState({ coverImg: file, fileName: file.name, fileUploadFileError: '' })
        } catch (err) {

        }
    }




    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'siteName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = (e) => {
        if (e.target.name == "user")
            this.clickChild2()
        else if (e.target.name == "tag")
            this.clickChild()
        else if (e.target.name == "project")
            this.clickChild3()
        else if (e.target.name == "folder")
            this.clickChild1()
        this.setState({ inviteNewPopupOpen: false })

    }

   


    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }

    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

    }
   

    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these sites" : status == 0 ? "Inactive these sites" : "delete these sites" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })

        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.siteInfo &&
                    this.props.siteInfo.forEach(item => actionArr.push(item._id))
                this.setState({ selectAll: true })
            }
            else {
                actionArr = []
                this.setState({ selectAll: false })
            }

        } catch (err) {

        }

    }

    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }





    handleOnSelect = (data) => {
        if (this.state.userId.indexOf(data) == -1) {
            this.setState({ userId: [...this.state.userId, data] })

        }
        else if (this.state.userId.indexOf(data) != -1) {
            this.setState({ userId: this.state.userId.filter(item => item != data) })
        }

    }


    handleChangeMultiSelect = (value) => {

    }

    onSelectClientGetUsers = () => {
        try {
            if (this.state.clientId) {
                let req = { projectId: this.state.projectId, status: "1" }
                _viewProject(req)
                    .then(resp => {
                        this.setState({ clientName: resp.responseData.projectData.clientId.clientName, clientId: resp.responseData.projectData.clientId._id, extraUserData: resp.responseData.userDetails }, () => this.getClientsUsers())

                    })
                    .catch(err => {

                    })
            }
        }
        catch (err) { }
    }


    getClientsUsers = () => {
        try {
            if (this.state.clientId) {
                let req = { clientId: this.state.clientId, projectId: this.state.projectId, status: "1", role: "3" }
                _getUsersList(req)
                    .then(resp => {

                        let usersList = resp.responseData.usersInfo.userInfo.userInfo
                        // this.setState.extraUserData.filter

                        this.setState({ clientUserList: resp.responseData.usersInfo.userInfo.userInfo, isLoadingState: false })
                    })
                    .catch(err => {

                    })
            }
        }
        catch (err) { }
    }



    onSelectProject = (e) => {
        try {
            this.setState({ projectId: e.target.value, userId: [], clientName: '', isLoadingState: true }, () => {
                this.onSelectClientGetUsers()

            })

        } catch (err) {

        }

    }


    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )
            
        }
        this.setState({toggleState:!this.state.toggleState})   
        if( this.state.checked.indexOf(item.value) == -1)
        this.state.checked.push(item.value)    
    }
    
    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }




    render() {
        let { name, description, address, street, city, state, latitude, longitude, nameError, descriptionError, addressError, streetError, cityError, stateError, latitudeError, longitudeError } = this.state;

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        return (
            <>
                {/* {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />} */}

                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                <AddProject
                    setClick={click => this.clickChild3 = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}

                />

                <AddFolder
                    folderType={2}
                    setClick={click => this.clickChild1 = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />



                <div>
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                        <Modal.Header closeButton>
                            {/* {/ <Modal.Title>Modal heading</Modal.Title> /} */}
                            <h6>Create New Site</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form >

                                <div class="form-group">
                                    <label for="=Name"> Name</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        maxLength={30}
                                        name='name'
                                        value={name}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={nameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="Description">Description</label><span className="impFields">*</span>
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                        id="reportDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name='description'
                                        value={description}
                                        onChange={this.handleChangeAll}

                                    />

                                    <InlineError
                                        message={descriptionError}
                                    />
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Folder</label><span className="impFields">*</span><br />
                                        <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                            {
                                                this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                    <Option value={item._id}>
                                                        <div class="dropdown_flex">
                                                            <div><img src={item.icon} /></div>
                                                            <div><p className="txtClassUpper"  >{item.folderName}</p></div>
                                                        </div>
                                                    </Option>

                                                ))
                                            }

                                        </Select>
                                        <InlineError
                                            message={this.state.folderIdError}
                                        />

                                    </div>
                                    <div className="col-md-4"><button name="folder" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Folder</button></div>
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Cover image</label><span className="impFields">*</span><br />
                                        <div class="custom-file">
                                            <input id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}

                                                accept="image/png,image/jpeg"
                                            />
                                            <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                        </div>
                                        <InlineError
                                            message={this.state.fileUploadFileError}
                                        />
                                    </div>
                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label >Project</label><span className="impFields">*</span><br />

                                        <select
                                            className={`form-control ${this.state.projectError != '' ? 'is-invalid' : ''}`}
                                            id="sel1" name="projectId" onChange={this.onSelectProject}>
                                            {this.state.projectId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select project"}</option>}
                                            {
                                                this.props.projectInfo && this.props.projectInfo.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{`${item.projectName}`}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                        <InlineError
                                            message={this.state.projectError}
                                        />
                                    </div>
                                    <div className="col-md-4"><button type="button" name="project" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Project</button></div>
                                </div>






                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Client</label><span className="impFields">*</span>
                                        <input type="text"
                                            className={`form-control`}
                                            id="Name"
                                            value={this.state.clientName}

                                            disabled
                                        />
                                    </div>


                                </div>
                                <br />
                                <br />
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Users</label><br />
                                        <Select
                                            mode="multiple"
                                            placeholder="Please select client first"
                                            // defaultValue={}
                                            value={this.state.userId}
                                            onChange={this.handleChangeMultiSelect}
                                            onSelect={(i) => this.handleOnSelect(i)}
                                            onDeselect={(i) => this.handleOnSelect(i)}
                                        >
                                            {this.state.clientUserList.map((item, index) => (
                                                <Option value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</Option>

                                            ))}
                                        </Select>
                                    </div>

                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleChangeAll}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.tagName}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button name="tag" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>


                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="Address">Address</label>

                                        <textarea rows="4" cols="50"
                                            className={`form-control ${this.state.addressError != '' ? 'is-invalid' : ''}`}
                                            id="Address"
                                            placeholder="Enter Address"
                                            maxLength={256}
                                            name='address'
                                            value={address}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={addressError}
                                        />
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label for="Street">Street</label>
                                    <input type="text"
                                        className={`form-control ${this.state.streetError != '' ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="Street"
                                        placeholder="Street"
                                        name='street'
                                        value={street}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={streetError}
                                    />

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6">
                                        <label for="City">City</label>
                                        <input type="text"
                                            className={`form-control ${this.state.cityError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="City" placeholder="City"
                                            name='city'
                                            value={city}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={cityError}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label for="State">State</label>
                                        <input type="text"
                                            className={`form-control ${this.state.stateError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="State" placeholder="State"
                                            name='state'
                                            value={state}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={stateError}
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="email">Map content</label>
                                    <textarea rows="4" cols="50"
                                        className={`form-control ${this.state.mapContentError != '' ? 'is-invalid' : ''}`}
                                        id="email"

                                        placeholder="Map content"
                                        name="mapContent"
                                        autocomplete="off"
                                        value={this.state.mapContent}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={this.state.mapContentError}
                                    />

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6">
                                        <label for="Latitude">Latitude</label>
                                        <input type="number"
                                            className={`form-control ${this.state.latitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Latitude" placeholder="Latitude"
                                            name='latitude'
                                            value={latitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={latitudeError}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label for="Longitude">Longitude</label>
                                        <input type="number"
                                            className={`form-control ${this.state.longitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Longitude" placeholder="Longitude"
                                            name='longitude'
                                            value={longitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={longitudeError}
                                        />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button
                                        type="button"
                                        className={`btn btn-primary btn-block ${this.state.profileError != '' ? 'is-invalid' : ''}`}

                                        onClick={() => this.setState({ showProfileModal: true, inviteNewPopupOpen: false,profileError:'' })} >
                                        Add Profile
                                </button>
                                    <InlineError
                                        message={this.state.profileError}
                                    />
                                </div>



                                <br />
                                <div class="form-group text-center">

                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>


                            </form>
                        </Modal.Body>

                    </Modal>
                </div>

                <Modal show={this.state.showProfileModal} onHide={this.handleProfileModal} className="custom_modal treeview_modal" >
                    <Modal.Header closeButton>
                        {/* <h6>Profile</h6> */}
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Site Profile</h6>
                        {
                            this.state.isSpinner  &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                        
                        <Accordion defaultActiveKey="00000000">
                            {
                                this.state.nodeData.map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" value = {item3.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item5}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item5}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                            {
                                                                                                                item5.children && item5.children.map((item6, index6) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item6}`} >
                                                                                                                                {item6.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item6.isChecked} onClick={() => this.handleCheckedBox(item6)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item6.label} </span>
                                                                                                                                {item6.isOther && item6.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item6.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item6)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item6}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>
                        
                        <br />
                        <div class="form-group">
                            <button type="button" onClick={this.onSubmitCheck} name="save" class="btn btn-primary btn-blue " >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        <h6>Site Action </h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-default btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button variant="btn btn-primary btn-sm btn-blue" onClick={this.handleActionModal}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        usersInfo: state.users.userInfo,
        activeSiteDetails: state.sites.activeSite,
        inActiveSiteDetails: state.sites.inactiveSite,
        totalSiteDetails: state.sites.totalSite,
        dataCount: state.sites.dataCount,
        projectInfo: state.projects.projectInfo,
        foldersList: state.folders.folderList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllFolders, getAllProjectList, getAllSiteList, getAllUserList, addNewSite, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
