/**
 * @about tags file that create tas created by admin
 *  * create new tag
 * 
 */

// react lib
import React, { Component } from 'react';
// react bootstrap
import { Modal } from 'react-bootstrap';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { addNewTag } from "../../../redux/actions/Add";
import { getAllTags } from "../../../redux/actions/Get";

// custome components
import SideNav from '../../layout/SideNav';
import Header from '../../layout/Header';
// inline error
import InlineError from "../../common/InlineError"
// loader
import Loader from "../../common/Loader"


import { PhotoshopPicker } from 'react-color';




class AddTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addTagPopupOpen: false,
            tagName: '',
            colorName: '#0efa1a',
            tagNameError: '',
            showColorPicker:false,

        };
    }

   

     // open add tag modal
  

    openAddTagPopup = () => {
        this.setState({
            addTagPopupOpen: !this.state.addTagPopupOpen,
            isEdit:false,
            tagName: '',
            colorName: '#0efa1a',
        })
    }


   

    componentDidMount() {
        this.props.action.getAllTags();
        this.props.setClick(this.openAddTagPopup);
    }

    // close modal
    closeModal = () => {
        this.setState({
            addTagPopupOpen: false,
            tagNameError:''
        })
        this.props.closeModalAddTag && 
        this.props.closeModalAddTag(true)
    }

    //clear fields
    clearFields = () => {
        this.setState({ tagName: '', colorName: '#0efa1a' })
    }

    // handle new tag
    handleAddTag = (e) => {
        if (this.state.tagName == '') {
            this.setState({ tagNameError: '*Please enter tag name.' })
        }

        else {

            let req = {
                tagName: this.state.tagName,
                color: this.state.colorName
            }
            let req2 = {
                status:"1"
            }

            if (e.target.name == "save") {
                this.props.action.addNewTag(req, req2);
                this.closeModal()
                this.clearFields()
            }
            else {
                this.props.action.addNewTag(req, req2) ;
                this.clearFields()

            }
        }


    }

    // color 
    handleChangeComplete = (color, event) => {
        this.setState({ colorName: color.hex });
      };

    handleChangeComplete = (color, event) => {
        this.setState({ colorName: color.hex });
      };


    //handle on handleOnChange
    handleOnChange = (e) => this.setState({ [e.target.name]: e.target.value, tagNameError: '' })



    render() {
        return (
            <div>
                <Modal show={this.state.addTagPopupOpen} onHide={this.closeModal} className="custom_modal" >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6>ADD NEW</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                           
                            <div class="form-group">
                                <label htmlFor="email">Name</label><span className = "impFields">*</span>
                                <input
                                    type="email"
                                    name="tagName"
                                    className={`form-control ${this.state.tagNameError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    placeholder="Enter Tag Name"
                                    autocomplete="off" 
                                    value={this.state.tagName}
                                    maxLength={20}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.tagNameError}
                                />
                            </div>
                            <div >
                                <label htmlFor="pwd">Color</label><br />
                                {
                                     this.state.showColorPicker && 
                                     <PhotoshopPicker
                                     color={ this.state.colorName }
                                     name = "colorName"
                                     onChangeComplete={ this.handleChangeComplete }
                                     onAccept = {()=> this.setState({showColorPicker:false})}                                       
                                        onCancel = {()=> this.setState({showColorPicker:false})
                                         
                                    }
                                    
                                 />
                                 }
                                 <input
                                    type="button"
                                    name="colorName"
                                    style = {{backgroundColor:this.state.colorName}}
                                    value={this.state.colorName}                                
                                    className="buttonColorPicker col-sm-8"
                                    onChange={this.handleOnChange}
                                    onClick = {()=> this.setState({showColorPicker:true})}
                                    readOnly
                                />
                               <i class="fa fa-eyedropper cm_eyedroper"  onClick = {()=> this.setState({showColorPicker:true})}   aria-hidden="true"></i>
                            </div>
                            <br />
                            <div class="form-group">                          
                                <button type="button" ref = "saveAndAdd" class="btn btn-default mR-20" onClick={this.handleAddTag} >Save & Add New</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue mR-20" onClick={this.handleAddTag}>Save</button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>
            </div>
        );
    }
}
// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.allTagsList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ addNewTag, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);

