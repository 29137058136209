

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link, NavLink } from 'react-router-dom';
import { addNewProject, getAllProjectList } from "../../redux/actions/Project";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewReport, getAllReportList } from "../../redux/actions/Report";
import { getAllSiteList } from "../../redux/actions/Site";
import { getTagForFIlters } from "../../redux/actions/Tag";
import { Filter , Rightarrow  } from '../Svg'
import { getAllTags } from "../../redux/actions/Get";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";

// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"
import AddProject from "../../components/common/add_project/AddProject";
import AddSite from "../../components/common/add_site/AddSite";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";







var actionArr = []
class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            name: 'React',
            tagId: '',
            showDeleteModal: false,
          
            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
           
            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError:'',
            fileUploadFileError:'',
            

            statusState: 'Delete this report',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus:false,

            toggle:false,
            selectAll:false,
            fileName:'Choose file...',
            sortTag: '',
            showTagName: 'All Tag',
            showTagColor: '#ffffff',
            dataShow:false
            


        }
    }

    componentDidMount() {
      
        this.onClickPagenation();
        this.props.action.getAllTags({status:"1"});
        this.props.action.getAllSiteList({status:"1"});
        this.props.action.getAllProjectList({status:"1"})
        this.props.action.getTagForFIlters({ tagType: 6, folder:this.props.match.params.id })

    }


   


    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value,  reportNameError: '',reportDescriptionError:''});
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        try{
        this.setState({ 
            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError:'',
            fileName:'Choose file...'
            
         })
         this.fileInput.value = ""
        }
        catch(err){

        }

    }

    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
        this.setState({ reportFile: file , fileName:file.name, fileUploadFileError:''})
    }

    // on submit form
    handleOnSubmitForm = (e) => {      
        if (this.state.reportName == "") { 
            this.setState({ reportNameError: "*Please enter report name." })
        }
       else if (this.state.reportDescription == "") { 
            this.setState({ reportDescriptionError: "*Please enter description name." })
        }

      else  if (!this.state.reportFile ) { 
            this.setState({ fileUploadFileError: "*Please add a file" }) 
        }

      else if(this.state.reportFile.type.split("/")[0] != "text" && this.state.reportFile.type.split("/")[0] != "application" )
        {
            
         
         this.setState({fileUploadFileError:"*Please add a valid file.",})
     }
     else if (this.state.reportFile && this.state.reportFile.type.split("/")[1] == "zip" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/vnd.android.package-archive") {
        
        this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }

     else if(this.state.reportFile.type.split("/")[1] == "exe" ||  this.state.reportFile.type == "application/x-ms-dos-executable"  ||  this.state.reportFile.type  == "application/x-iso9660-appimage" )
     {
         
      
      this.setState({fileUploadFileError:"*Please add a valid file.",})
  }


     
     
        else {
            
            let formData = new FormData()
            formData.append("reportName", this.state.reportName);
            formData.append("desc", this.state.reportDescription)
            formData.append("folder", this.props.match.params.id)
           formData.append("url", this.state.reportUrl);
            formData.append("project", this.state.reportProjectId)
            formData.append("tag", this.state.reportTagId)
            formData.append("fileData", this.state.reportFile) 
            formData.append("site", this.state.reportSiteId) 


        let req2 = {
            folder: this.props.match.params.id,
            search: this.state.searchString,
            status:this.state.activeClass == "t" ? "": this.state.activeClass == "a"? "1":"0" ,
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo:String(this.state.pageNo),
            tag: this.state.sortTag,
            tagType:6,
        }


            if (e.target.name == "save") {

                this.props.action.addNewReport(formData, this.props.match.params.id, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewReport(formData, this.props.match.params.id, req2)
                this.clearFields();
            }
        }
    }

    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this report" : status == 0 ? "Inactive this report" : "delete this report" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id){
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)
                
            }
        })
    }

      


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

       

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            reportId: [actionArrr].toString() 
        }

    _Api(Url.ADMIN_CHANGE_STATUS_REPORT.method, Url.ADMIN_CHANGE_STATUS_REPORT.url, req )
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '',selectAll:false })
                
                notify("success", resp.responseData.message)
               this.onClickPagenation();


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll:false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }


    //edit user
    onClickEditUser = (report) => {

        this.props.history.push(`/reports/edit/${this.props.match.params.id}/${report._id}`, { state: report });

    }
    onChangeTabStstus = (tab)=>{
        this.setState({activeClass:tab, pageNo:0}, ()=>this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.props.reportData) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })   



        let req = {
            folder: this.props.match.params.id,
            search: this.state.searchString,
            status:this.state.activeClass == "t" ? "": this.state.activeClass == "a"? "1":"0" ,
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo:String(this.state.pageNo),
            tag: this.state.sortTag,
            tagType: 6,
        }



        this.props.action.getAllReportList(req);
    }


    // on search
    handleOnSearch = (e) => {
    
        if(e.target.value.length >= 3)
        this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
       else  if(e.target.value.length == "")
       this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
       

    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'reportName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }
    handleAddTag = (e) => {
        if(e.target.name=="site")
                  this.clickChild5()       
       else if(e.target.name=="tag")
             this.clickChild()
       else if(e.target.name=="project")
             this.clickChild3()
       

        this.setState({ inviteNewPopupOpen: false })
        

    }


    handleChecked = (e)=>{ 
        let num = e.target.value       
        if( actionArr.indexOf(num) == -1)
        {
            actionArr.push(num)
            this.setState({toggle:!this.state.toggle})

        }
        else{
            let arr = 
            actionArr =  actionArr.filter(item => item != num)
            this.setState({toggle:!this.state.toggle})
        }        

    }

    toggleFilterStatus = ()=>{

        this.setState({FilterStatus: !this.state.FilterStatus})
      
        // document.addEventListener('click', this.handleClickOutside);

    }
    


      handleDeleteModal2 = (id, status) => {
          if(actionArr.length == 0) return
        this.setState({StatusAction:status, statusState: status == 1 ? "Active these report" : status == 0 ? "Inactive these report" : "delete these report" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });
                
            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.reportList &&
                    this.props.reportList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else{ 
                actionArr= []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }
    sortByTag = (item) => {

        this.setState({
            sortTag: item._id,
            showTagName: item.tagName,
            showTagColor: item.color
        }, () => this.onClickPagenation())

    }






    render() {
        var pageRange =  Math.ceil( this.props.reportData / this.state.dataCount)
        setTimeout(()=> this.setState({dataShow:true}), 2000)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header
                        isSearch={true}
                        onClickSearchTest={(e) => this.handleOnSearch(e)}
                    />
                    <div className="body_header folder">
                        <h6 className = "headingH6"> <Link to="/reports/folders">Reports</Link> &nbsp;
                        <span className="cm_arrow"><Rightarrow/></span>
                        &nbsp; {this.props.location.state && this.props.location.state.state}</h6>
                        <div className="new_btn text-right">
                            <button className="btn btn-primary btn-blue" onClick={this.openInviteNewPopupOpen}>Add New</button>
                        </div>
                    </div>
                    <div className="counter_detail">
                        <div className={`counter_parent ${this.state.activeClass == 'a' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("a")}>
                            <h4>ACTIVE</h4>
                            <h4>{ this.props.reportActive && this.props.reportActive}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 'i' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("i")}>
                            <h4>INACTIVE</h4>
                            <h4 className="yellow">{ this.props.reportInactive && this.props.reportInactive}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 't' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("t")}>
                            <h4>TOTAL</h4>
                            <h4 className="green">{ this.props.reportTotal && this.props.reportTotal}</h4>
                        </div>
                        <Dropdown className="filter_dropdown" alignRight>
                        <Dropdown.Toggle className="ext-css" variant="success" id="dropdown-basic"   >
                                <>
                                    
                                    <div style={{ backgroundColor: this.state.showTagColor }} className="filter_dropdown_option"></div>
                                    &nbsp;&nbsp;
                                <div className="tagText">{this.state.showTagName}</div>
                                </>

                            </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu" alignRight>
                                <> 
                                 <Dropdown.Item onSelect={()=> this.sortByTag({ _id: '', tagName: 'All Tag', color: '#ffffff' })}  >
                                        <div className="filter_dropdown_parent" >

                                            {/* <div style={{ backgroundColor: 'white' }} className="filter_dropdown_option"></div> */}
                                            &nbsp;&nbsp;
                                                <div>All Tag
                                                </div>
                                        </div>

                                    </Dropdown.Item>

                                </>
                                {
                                    this.props.filterTagData && this.props.filterTagData.map((item, index) => (
                                        <>
                                            <Dropdown.Item  >
                                                <div className="filter_dropdown_parent" onClick={() => this.sortByTag(item)}>
                                                   
                                                <div style={{ backgroundColor: item.color }} className="filter_dropdown_option"></div>
                                                &nbsp;&nbsp; <div>{item.tagName}</div>
                                                </div>

                                            </Dropdown.Item>

                                        </>

                                    ))
                                }

                            </Dropdown.Menu>
                        </Dropdown>
                        
                    </div>
                   
                    <hr />
                    {
                          actionArr && actionArr.length !=0 &&
    
                          <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                          {actionArr.length} Select
                          </Dropdown.Toggle>
  
                          <Dropdown.Menu className="action_menu">
                              <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "1")}>Active</Dropdown.Item>   
                              <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "0")}>Inactive</Dropdown.Item>   
                              <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "2")}>Delete</Dropdown.Item>                           
                          </Dropdown.Menu>
                      </Dropdown> 
                    }
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                    <th>Sr No.</th>
                                    <th onClick={() => this.handleOnClickSort("report")}>Report Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Created By </th>                       
                                    <th>Type</th>
                                    <th onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Tag</th>
                                    <th>Action</th>
                                    <th>Status</th>
                                   
                                </tr>
                            </thead>
                            <tbody>

                                {
                                   
                                    this.props.reportList &&
                                    this.props.reportList.map((report, index) => (
                                        <tr key={index}>
                                             <td><input type="checkbox" className="checkbox"   name="time"  value={report._id}  checked = {actionArr.indexOf(report._id) != -1} onClick = {this.handleChecked}/></td>
                                            <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                            <td className="txtClassUpper">{`${report.reportName}`}</td>
                                            {/* <td><Link to={`/report-profile/${this.props.match.params.id}/${report._i}` }>{`${report.firstName} ${report.lastName}`}</Link></td> */}
                                            <td>{"Admin"}</td> 
                                            <td>{report.fileType}</td>                                            
                                            <td>{dateFormat(report.created, "mm/dd/yyyy")}</td>
                                            <td>
                                                {
                                                    report.tagName == undefined ?
                                                        <>
                                                            <i class="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                            &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                            &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                        </>
                                                        :
                                                        <div class="display-flex">
                                                            <div style={{ backgroundColor: report.color }} class="color_circle"></div>
                                                            &nbsp;&nbsp;
                                                                                <div>{report.tagName}</div>
                                                        </div>
                                                }
                                            </td>
                                            <td>
                                                <div>
                                                    <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onClickEditUser(report)}>Edit</button>&nbsp;&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-primary btn-sm btn-pink" onClick={() =>  this.handleDeleteModal(report._id, "2")}>Delete</button> &nbsp;&nbsp;&nbsp;
                                                    <a href= {report.fileData}  className="btn  btn-sm btn-default"   download>Export</a>                                                  
                                                    
                                                </div>
                                            </td>    
                                            <td>
                                                <button type="button" onClick={() => this.handleDeleteModal(report._id, report.status == "1" ? "0" : "1")} class={`btn btn-toggle ${report.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autocomplete="off">
                                                    <div class="handle"></div>
                                                </button>
                                            </td>                                     

                                           
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                        {
                            this.state.dataShow && this.props.reportData == 0 && this.props.isLoading != true && <p  className="cm_center_text" > Sorry no more content </p>
                        }




                        <div className="float-right">                                                   
                            {       this.props.reportData >=10 &&                         
                                <div class="pagination">    
                                    <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                    <p>{this.state.pageNo + 1} to { pageRange ==0 ? 1 : pageRange } </p>
                                    <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.reportData / this.state.dataCount) <= this.state.pageNo +1 } ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />
                <AddSite
                 setClick={click => this.clickChild5 = click}
                 closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                 folderTypee = {2}

                />
                <AddProject 
                setClick={click => this.clickChild3 = click}
                closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                  
                />


                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6>Add New</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleOnSubmitForm}>
                          

                            <div class="form-group">
                                <label for="email">Report Name</label><span className = "impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.reportNameError != '' ? 'is-invalid' : ''}`}
                                    id="reportName"
                                    name="reportName"
                                    placeholder="Enter Report name"
                                    maxLength={20}
                                    value={this.state.reportName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.reportNameError}
                                />
                            </div>


                            <div class="form-group">
                                <label for="email">Description</label><span className = "impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.reportDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder="Enter description"
                                    maxLength={256}
                                    name="reportDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.reportDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.reportDescriptionError}
                                />
                            </div>

                            <div class="form-group row df-end">                            
                                    <div className="col-md-12">  
                                    <label for="email">Report file</label><span className = "impFields">*</span><br/>                                  
                                    <div class="custom-file">
                                        <input id="inputGroupFile01" onChange={this.onImageUpload} type="file"  className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                      
                                      accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                         />
                                        <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                    </div>
                                    <InlineError
                                    message={this.state.fileUploadFileError} 
                                /> 




                                    {/* <input type="file"
                                    accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    className={`form-control ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                     ref="fileUploader"
                                      onChange={this.onImageUpload} 
                                    
                                      />
                                        <InlineError
                                    message={this.state.fileUploadFileError} 
                                /> */}
                                    </div>
                                    {/* <div className="col-md-4"><button type="button"  onClick = {()=> this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-12">Browse</button></div> */}

                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Project</label>
                                    <select class="form-control" id="sel1"  name="reportProjectId" onChange={this.handleOnChange}>
                                            { this.state.reportProjectId =="" &&  <option key={-1} value={"select"} disabled selected hidden  >{"Select project"}</option> }
                                            {
                                                this.props.projectInfo && this.props.projectInfo.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{`${item.projectName}`}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" name = "project" onClick={this.handleAddTag}>Add Project</button></div>

                            </div>

                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Sites</label>
                                    <select class="form-control" id="sel1" name="reportSiteId" onChange={this.handleOnChange}>
                                    { this.state.reportSiteId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select site"}</option>}
                                        {
                                            this.props.siteInfo && this.props.siteInfo.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.siteName}</option>
                                                )

                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" name="site" onClick={this.handleAddTag} >Add Sites</button></div>

                            </div>


                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>
                                    <select class="form-control" id="sel1" name="reportTagId" onChange={this.handleOnChange}>
                                        { this.state.reportTagId =="" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>}
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" name="tag" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <br />
                            <div class="form-group">                               
                                <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal">
                    <Modal.Header closeButton>
                        <h6>Report Action</h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-default  btn-sm mR-20"  onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button variant="primary" class="btn btn-primary btn-sm btn-blue " onClick={this.handleActionModal}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        reportList:state.reports.reportInfo,
        siteInfo: state.sites.siteInfo,
        reportActive:state.reports.activeTag,
        reportInactive:state.reports.inactiveTag,
        filterTagData: state.tags.filterInfo,
        reportTotal:state.reports.totalTag, 
        reportData:state.reports.dataCount   ,
        projectInfo: state.projects.projectInfo,

    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({getTagForFIlters,getAllProjectList, getAllUserList, getAllTags, addNewUser, addNewReport, getAllReportList, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);

