/**
 * @about folder view file that create and contains folders, users
 * create new folder
 * 
 */

// react lib
import React, { Component } from 'react';

// folder section
import FolderSection from "../add_folder_common/AddFolder";

class UsersCatogeries extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FolderSection
                {...this.props}
                titleName="CLIENTS"
                folderType="10"
                link="clients"
            />
        );

    }
}

export default UsersCatogeries;
