

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal} from 'react-bootstrap';
//redux
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewReport, getAllReportList } from "../../redux/actions/Report";
import { addNewMedia, getAllMediaList } from "../../redux/actions/Media";
import { getAllTags, getAllFolders } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
import { resetStore } from "../../redux/actions/Common";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";



import { _actionForMedia } from "../../config/api/MediaServices";


// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";

// video player


import { Player, BigPlayButton } from 'video-react';
import { Select, Icon } from 'antd';



var data = []
var arrData = [];

const { Option } = Select;

class MediaList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,

            tagId: '',
            showDeleteModal: false,
            showMediaModal: false,
            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,

            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            mediaName: '',
            mediaNameError: '',
            mediaDescription: '',
            mediaDescriptionError: '',
            mediaUrl: '',
            mediaFile: '',
            mediaProjectId: '',
            mediaSiteId: '',
            mediaTagId: '',
            deletedId: '',
            mediaUrlError: '',
            playData: "",
            fileUploadError:"",
            fileStatus:true,
            siteId:'',

            index:0,

            fileName:'Choose file...',
            folderId:'',
            folderIdError:'',
            toggleState:false



        }
    }
    openMediaModal = (media, index) => {
        data = this.props.mediaList
        this.setState({ playData: media, index }, () => {
            this.setState({
                showMediaModal: !this.state.showMediaModal
            })
        })

    }

    componentDidMount() {

        this.onClickPagenation();
        this.props.action.getAllTags();
        this.props.action.getAllSiteList();
        let req = {
            folderType: 7
        }
        this.props.action.getAllFolders(req)

    }


    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value,
            mediaDescriptionError: '',
            mediaNameError: '',
            fileUploadError:''  ,
                
        
        });

    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
            showMediaModal: false,
        })

        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        try{
            this.setState({ 
                mediaName: '',
                mediaNameError: '',
                mediaDescription: '',
                mediaDescriptionError: '',
                mediaUrl: '',
                mediaFile: '',
                mediaProjectId: '',
                mediaSiteId: '',
                mediaTagId: '',
                deletedId: '',
                mediaUrlError: '',
                fileName:'Choose file...',
                folderIdError:'',
                folderId:'',
                  
                           


                
    
             })

        }catch(err){

        }

    }

    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
        this.setState({ mediaFile: file, fileName:file.name, fileUploadError:"", })
    }

    // on submit form
    handleOnSubmitForm = (e) => {
      
       try{
      
        if (this.state.mediaName == "") {
            this.setState({ mediaNameError: "*Please enter media name." })
        }
      else  if (this.state.mediaDescription == "") {
            this.setState({ mediaDescriptionError: "*Please enter description." })
        }
         
       else if(this.state.mediaFile == "" ) 
       {
           this.setState({fileUploadError:"*please add a file."})
       }
       else if(this.state.mediaFile.type.split("/")[0] != "image" && this.state.mediaFile.type.split("/")[0] != "video")
       {
        
        this.setState({fileUploadError:"*Please add a valid file.", fileStatus:false})
    }

    else if (this.state.folderId == "") {
        this.setState({ folderIdError: "*Please select folder" })
    }
        else {


            let formData = new FormData()
            formData.append("mediaName", this.state.mediaName);
            formData.append("desc", this.state.mediaDescription)
            formData.append("folder", this.state.folderId)
            formData.append("url", this.state.mediaUrl);
            formData.append("project", this.state.mediaProjectId)            
            formData.append("tag", this.state.mediaTagId )
            formData.append("fileData", this.state.mediaFile)
            formData.append("site", this.props.match.params.id)
    
            let req2 = {
                site: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: this.state.pageNo,
            }


            if (e.target.name == "save") {
                this.props.action.addNewMedia(formData, req2)
                this.closeModal()
                this.clearFields()
            }
            
            else {

                this.props.action.addNewMedia(formData, req2)
                this.clearFields();
            }
        }
       }
       catch(err){

       }
    }

    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletedId: id });
    }

    
  



    //edit user
    onClickEditUser = (report) => {
        this.props.history.push(`/media/edit/${this.props.match.params.id}/${report._id}`, { state: report });

    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.totalNextPage) > this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        if (page)
            this.setState({ activeClass: page == "A" ? "a" : (page == "I" ? "i" : "t") })
        if (page == "A" || page == "I" || page == "T")
            this.setState({ pageNo: 0 })



        let req = {
    
            unassigned:1,
            search: this.state.searchString,
            status: page == undefined ? this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0" : page == "A" ? "1" : (page == "I" ? "0" : ""),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: this.state.pageNo,
        }



        this.props.action.getAllMediaList(req);
    }


    // on search
    handleOnSearch = (e) => {
        let str = e.target.value.trim()
        if (str.length >= 3)
            this.setState({ searchString: str, pageNo: 0 }, () => this.onClickPagenation())
        else if (str == "")
            this.setState({ searchString: str, pageNo: 0 }, () => this.onClickPagenation())

    }


    // on click

    handleOnClickSort = (e) => {
        
        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'reportName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({inviteNewPopupOpen:false})

    }

    actionMedia = (action)=>{
        if(action == -1)
         {
             if(this.state.index > 0)
             this.setState({index: --this.state.index})
         }

        else if(action == 1)
         {
              if(data.length > this.state.index+1)
             this.setState({index: ++this.state.index})
         }

    }


    // on change folder select
    handleChange = (value) => {
        this.setState({folderId:value, folderIdError:''});
    }


    handleTOAddItems = ()=>{
        try{
            let req= {
                dataId:arrData.toString(),
                assignId:this.props.match.params.id,
                assignType:'P',
                dataType:3

            }
            _Api(Url.ADMIN_ADD_ITEMS.method, Url.ADMIN_ADD_ITEMS.url, req )
            .then(rsp=>{
                this.setState({isLoadingState:false, selectAll:false})
               
                arrData = [];

               this.props.history.goBack();


            })
            .catch(err=>{
            })
            
        }catch(err){

        }
    }


    handleONselect = (data)=>{

        if(arrData.indexOf(data._id) == -1)
        {
            arrData.push(data._id)
        }
        else    if(arrData.indexOf(data._id) != -1)
        {
            let arr = arrData.filter(item=> item != data._id)
            arrData = arr;
            
        }

        this.setState({toggleState:!this.state.toggleState})
        
    }



   // to re set folder & re set store that pre data not show
   componentWillUnmount() {
    this.props.action.resetStore();
}




    render() {

       return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
              
              
                    <div className="body_header">
                        <div className="new_btn text-right btn_parent">
                            <button  type="button" className="btn btn-primary btn-blue btn_child" onClick={()=> this.props.history.goBack()}>Back</button>
                        </div>
                    </div>

                    <div className="counter_detail">  

                    
                    {
                         
                         arrData && arrData.length !=0 &&
 
                         <Dropdown>
                         <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                         {arrData.length} Select
                         </Dropdown.Toggle>
 
                         <Dropdown.Menu className="action_menu">
                             <Dropdown.Item onSelect={this.handleTOAddItems}>Add to project</Dropdown.Item>                           
                         </Dropdown.Menu>
                     </Dropdown>                         
                     
                 }
                 </div>

                    <div className="media_parent">
                        {
                            this.props.mediaList &&
                            this.props.mediaList.map((media, index) => (
                                <div className="media_container" key={index}  >
                                    <Dropdown className="header_dropdown_btn more_dropdown_btn">
                                    <input type="checkbox" onClick={()=> this.handleONselect(media)} class="checkbox muti_media" name="time" value="test"/>                                       
                                    </Dropdown>                                    
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <img src={media.thumbnail} onClick={() => this.openMediaModal(media, index)} className="img-fluid" />
                                        <p className="img_heading txtClassUpper">{media.mediaName}</p>                                        
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    {
                            this.props.mediaList.length == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }}> Sorry no more content </p>
                    }
                
                {/* ----------------------modal for media-------------- */}
                <Modal show={this.state.showMediaModal} onHide={this.closeModal} className="media_modal" centered>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="media_parent">
                           {data.length !=0 &&
                            <div className="img_div">
                             {data && data[this.state.index].mediaType == "I" ?
                         <img src={data && data[this.state.index].fileData} className="img-fluid" />
                         :
                         <Player  >
                             <source src={this.state.playData && this.state.playData.fileData} />
                             <BigPlayButton position="center" />
                         </Player>


                     }
                            <div className="carousel_btn">
                                 <a class="left carousel-control ">
                                     <i class="fa fa-chevron-left" aria-hidden="true" onClick = {()=> this.actionMedia(-1)}></i>
                                 </a>
                                 <a class="right carousel-control">
                                 <i class="fa fa-chevron-right" aria-hidden="true"  onClick = {()=> this.actionMedia(1)}></i>
                                 </a>
                            </div>
                         </div>
                        }
                            {
                                data.length !=0 &&
                                <div className="desc_div">
                                <p>{data && data[this.state.index].mediaName} </p> 
                                 <div className="icon_parent">
                                         <a href = {data && data[this.state.index].fileData} download>
                                         <div className="icon">
                                             <i class="fa fa-download" aria-hidden="true"></i>
                                             <p>Download</p>
                                        </div>
                                         </a>
                                         
                                 </div>
                                 <div className="text">
                                       <p>
                                           {
                                              data[this.state.index].desc
                                           }
                                       </p>
                                 </div>
                            </div>
                            }
                        </div>
                </Modal.Body>
                </Modal>
                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        <h6>Add New</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div class="form-group">
                                <label for="name">Media Name</label><span className = "impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.mediaNameError != '' ? 'is-invalid' : ''}`}
                                    id="mediaName"
                                    name="mediaName"
                                    placeholder="Enter media name"
                                    maxLength={20}
                                    value={this.state.mediaName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.mediaNameError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="desc">Description</label><span className = "impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.mediaDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="mediaDescription"
                                    placeholder="Enter Description"
                                    maxLength={256}
                                    name="mediaDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.mediaDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.mediaDescriptionError}
                                />
                            </div>                           
                          
                            <div class="form-group row df-end">
                                    <div className="col-md-12">
                                    <label for="email">Report file</label><span className="impFields">*</span><br />
                                    <div class="custom-file">
                                        <input id="inputGroupFile01" onChange={this.onImageUpload} type="file"  className={`custom-file-input  ${this.state.fileUploadError != '' ? 'is-invalid' : ''}`}
                                      
                                      accept="image/*, video/*"
                                         />
                                        <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                    </div>
                                    <InlineError
                                    message={this.state.fileUploadError} 
                                /> 

                             </div>
                             </div>                            
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Folder</label><span className="impFields">*</span><br />
                                    <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                        {
                                            this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                <Option value={item._id}>
                                                    <div class="dropdown_flex">
                                                        <div><img src={item.icon} /></div>
                                                        <div><p>{item.folderName}</p></div>
                                                    </div>
                                                </Option>

                                            ))
                                        }

                                    </Select>
                                    <InlineError
                                    message={this.state.folderIdError}
                                />
                                    
                                </div>
                            </div>           


                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>
                                    <select class="form-control" id="sel1" name="mediaTagId" onChange={this.handleOnChange}>
                                    <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <br />
                            <div class="form-group text-center">
                                {/* <a onClick={this.closeModal} className="mR-20">cancel</a> */}
                                <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                            </div>

                        </form>

                      
                    </Modal.Body>

                </Modal>
                <AddTag
                            setClick={click => this.clickChild = click}
                            closeModalAddTag = {()=> this.setState({inviteNewPopupOpen:true})}
                            
                        />


              

            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        mediaList: state.medias.mediaInfo,
        siteInfo: state.sites.siteInfo,
        mediaActive: state.medias.activeTag,
        mediaInactive: state.medias.inactiveTag,
        mediaTotal: state.medias.totalTag,
        mediaData: state.medias.dataCount,
        foldersList: state.folders.folderList


    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getAllFolders, getAllUserList, getAllTags, addNewUser, addNewReport, getAllReportList, addNewMedia, getAllMediaList,getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);

