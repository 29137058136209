/**
 * @about folder view file that create and contains folders
 * create new folder
 * resources folder section
 * 
 */
// react lib
import React, { Component } from 'react';
// folder section
import FolderSection from "../add_folder_common/AddFolder";

class ResourceFolder extends Component { 
    render() {
        return (
            <FolderSection
                {...this.props}
                titleName = "RESOURCES"
                folderType = "5"
                link = "resources"
            />
         );
    }
}
export default ResourceFolder;
