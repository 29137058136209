

import React, { Component } from 'react';
import { Dropdown, Modal, } from 'react-bootstrap';
import dateFormat from "dateformat"
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewSite, getAllSiteList } from "../../redux/actions/Site";
import { getAllTags, getAllFolders } from "../../redux/actions/Get";
import { resetStore } from "../../redux/actions/Common";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";
import AddTag from "../../components/common/add_tag/AddTag"
import { Select} from 'antd';
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


var actionArr = []
const { Option } = Select;
class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this site',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,




            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',

            //validation fields  
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',


            mapContent: '',
            mapContentError: '',

            folderIdError:'',
            folderId:'',










        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags();
        this.props.action.getAllUserList();
        let req = {
            folderType: 2
        }
        this.props.action.getAllFolders(req)      


    }

    



    //handle on handleOnChange
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '',mapContentError:'' });
      
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
    
        this.setState({ name: '', description: '', address: '', street: '', city: '', state: '', latitude: '', longitude: '', nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' ,
        folderIdError:'',
        folderId:'',
    })

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        let { name, description, address, street, city, state, latitude, longitude } = this.state;

        if (name == "") {
            this.setState({ nameError: "*Please enter name." })
        }
        else if (description == "") {
            this.setState({ descriptionError: "*Please enter description." })
        }
        else if (this.state.folderId == "") {
            this.setState({ folderIdError: "*Please select folder" })
        }

        else if (this.state.mapContent == "") {
            this.setState({ mapContentError: "*Please enter map content." })

        }
       
        

        else {

            let req = {
                siteName: name,
                desc: description,
                folder: this.state.folderId,
                tag: this.state.tagId,
                code:this.state.mapContent,
                project: this.props.match.params.id,
                user: [this.state.userId].toString(),
                street: street,
                city: city,
                state: state,
                lat: latitude,
                long: longitude,
                address: address,


            }

            let req2 = {
                project: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
            }
          


            if (e.target.name == "save") {

                this.props.action.addNewSite(req, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewSite(req, req2)
                this.clearFields();
            }
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this site" : status == 0 ? "Inactive this site" : "delete this site" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    

 


    //edit user
    onClickEditsites = (user) => {

        this.props.history.push(`/sites/edit/${this.props.match.params.id}/${user._id}/general`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.dataCount) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {        
            
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
            unassigned:1,
        }




        this.props.action.getAllSiteList(req);
    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'firstName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })

    }

    


    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }
    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }



    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these sites" : status == 0 ? "Inactive these sites" : "delete these sites" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.siteInfo &&
                    this.props.siteInfo.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }



    // on change folder select
    handleChange = (value) => {
        this.setState({folderId:value, folderIdError:''});
    }

    handleTOAddItems = ()=>{
        try{
            let req= {
                dataId:actionArr.toString(),
                assignId:this.props.match.params.id,
                assignType:'P',
                dataType:5

            }
        
            _Api(Url.ADMIN_ADD_ITEMS.method, Url.ADMIN_ADD_ITEMS.url, req )
            .then(rsp=>{
                this.setState({isLoadingState:false, selectAll:false})
                actionArr = []
            

               this.props.history.goBack();


            })
            .catch(err=>{
            })
            
        }catch(err){

        }
    }



   // to re set folder & re set store that pre data not show
   componentWillUnmount() {
    this.props.action.resetStore();
}




    render() {
        let { name, description, address, street, city, state, latitude, longitude, nameError, descriptionError, addressError, streetError, cityError, stateError, latitudeError, longitudeError } = this.state;

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                
                    <div className="body_header">
                        {/* <h6 className="headingH6" > <Link to="/sites">SITES</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}
                        <div className="new_btn text-right btn_parent">
                            <button type="button" className="btn btn-primary btn-blue btn_child" onClick={()=> this.props.history.goBack()}>Back</button>
                        </div>
                    </div>
                    <div className="counter_detail">
                        
                        { 
                             actionArr && actionArr.length !=0 &&
    
                             <Dropdown>
                             <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                              {actionArr.length} Select
                             </Dropdown.Toggle>
     
                             <Dropdown.Menu className="action_menu">
                                 <Dropdown.Item onSelect={this.handleTOAddItems}>Add to project</Dropdown.Item>                           
                             </Dropdown.Menu>
                         </Dropdown>        
                        }
                    </div>



                    
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>

                                    <th>Sr No.</th>
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("firstName")}>Site Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("email")} >Client<span className="sort_icon"><i class={`fa fa-sort-alpha-${this.state.sortToggleEmail ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                   
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.props.siteInfo &&
                                    this.props.siteInfo.map((sites, index) => (
                                        <tr key={index}>
                                            <td><input type="checkbox" className="checkbox" name="time" value={sites._id} checked={actionArr.indexOf(sites._id) != -1} onClick={this.handleChecked} /></td>

                                            <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                            <td className="txtClassUpper">{`${sites.siteName}`}</td>                                            
                                            <td>{sites.userName == undefined ? <><i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;</> :sites.userName}</td>
                                        
                                    
                                            <td>{dateFormat(sites.created, "mm/dd/yyyy")}</td>
                                          

                                            {/* <td>{user.status == 1 ? <span className="cm_active" onClick = {()=> this.onUserAction("0", user._id)}>Active</span> : <span style={{ color: 'red' }} className="cm_active" onClick = {()=> this.onUserAction("1", user._id)}>InActive</span>}</td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                        {
                            this.props.dataCount == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="cm_center_text"  > Sorry no more content </p>
                        }





                        <div className="float-right">
                            {this.props.dataCount >= 10 &&
                                <div class="pagination">
                                    <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                    <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                    <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.dataCount / this.state.dataCount) <= this.state.pageNo + 1} ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>

                                </div>

                            }
                        </div>
                    </div>

            
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                <div>
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal">
                        <Modal.Header closeButton>
                            {/* {/ <Modal.Title>Modal heading</Modal.Title> /} */}
                            <h6>Create New Site</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form >
                              
                                <div class="form-group">
                                    <label for="=Name"> Name</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        maxLength={30}
                                        name='name'
                                        value={name}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={nameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="Description">Description</label><span className="impFields">*</span>
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                        id="reportDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name='description'
                                        value={description}
                                        onChange={this.handleChangeAll}

                                    />

                                    <InlineError
                                        message={descriptionError}
                                    />
                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleOnChange}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">User</label>
                                        <select class="form-control" id="sel1" name="userId" onChange={this.handleChangeAll}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select user"}</option>
                                            {
                                                this.props.usersInfo && this.props.usersInfo.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12">Add User</button></div>

                                </div>
                                <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Folder</label><span className="impFields">*</span><br />
                                    <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                        {
                                            this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                <Option value={item._id}>
                                                    <div class="dropdown_flex">
                                                        <div><img src={item.icon} /></div>
                                                        <div><p>{item.folderName}</p></div>
                                                    </div>
                                                </Option>

                                            ))
                                        }

                                    </Select>
                                    <InlineError
                                    message={this.state.folderIdError}
                                />
                                    
                                </div>
                                {/* s<div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div> */}

                            </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="Address">Address</label>

                                         <textarea rows="4" cols="50"
                                            className={`form-control ${this.state.addressError != '' ? 'is-invalid' : ''}`}
                                            id="Address" 
                                            placeholder="Enter Address"
                                            maxLength={256}
                                            name='address'
                                            value={address}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={addressError}
                                        />
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label for="Street">Street</label>
                                    <input type="text"
                                        className={`form-control ${this.state.streetError != '' ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="Street"
                                        placeholder="Street"
                                        name='street'
                                        value={street}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={streetError}
                                    />

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6">
                                        <label for="City">City</label>
                                        <input type="text"
                                            className={`form-control ${this.state.cityError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="City" placeholder="City"
                                            name='city'
                                            value={city}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={cityError}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label for="State">State</label>
                                        <input type="text"
                                            className={`form-control ${this.state.stateError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="State" placeholder="State"
                                            name='state'
                                            value={state}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={stateError}
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                <label for="email">Map content</label> <span className="impFields">*</span>
                                <textarea rows="4" cols="50"
                                    className={`form-control ${this.state.mapContentError != '' ? 'is-invalid' : ''}`}
                                    id="email"

                                    placeholder="Map content"
                                    name="mapContent"
                                    autocomplete="off"
                                    value={this.state.mapContent}
                                    onChange={this.handleChangeAll}
                                />
                                <InlineError
                                    message={this.state.mapContentError}
                                />

                            </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6">
                                        <label for="Latitude">Latitude</label>
                                        <input type="text"
                                            className={`form-control ${this.state.latitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Latitude" placeholder="Latitude"
                                            name='latitude'
                                            value={latitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={latitudeError}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label for="Longitude">Longitude</label>
                                        <input type="text"
                                            className={`form-control ${this.state.longitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Longitude" placeholder="Longitude"
                                            name='longitude'
                                            value={longitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={longitudeError}
                                        />
                                    </div>
                                </div>



                                <br />
                                <div class="form-group text-center">

                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>


                            </form>
                        </Modal.Body>

                    </Modal>
                </div>

               

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        usersInfo: state.users.userInfo,
        activeSiteDetails: state.sites.activeSite,
        inActiveSiteDetails: state.sites.inactiveSite,
        totalSiteDetails: state.sites.totalSite,
        dataCount: state.sites.dataCount,
        foldersList: state.folders.folderList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getAllFolders, getAllSiteList, getAllUserList, addNewSite, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

