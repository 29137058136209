/**
 * @About
 * This file is all about project management
 */
//lib
import React, { Component } from 'react';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';


//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// actions getAllTaskList
import { addNewTask } from "../../redux/actions/Task";
import { addNewProject } from '../../redux/actions/Project'

// loader
import Loader from "../common/Loader";

// inline error
import InlineError from "../../components/common/InlineError";

// notify
import { notify } from "../../components/common/Toaster";

//show html
import renderHTML from 'react-render-html';
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            uploadSmartSheet: false,
            smartSheet: '',
            smartsheetError: '',
            taskCodeData: '',
            isLoadingState:true,
            isEdit:false,
            isSpinner: true
        }
    }

    componentDidMount() {
        this.getSiteDetails()
    }

    getSiteDetails = () => {
        try {
            
            _Api(Url.ADMIN_VIEW_PROJECT.method, Url.ADMIN_VIEW_PROJECT.url, '', this.props.match.params.id   )
                .then(resp => {
                    if (resp.responseData.projectData.taskCode && resp.responseData.projectData.taskCode != "undefined") {
                        let ds = resp.responseData.projectData.taskCode.replace("<IFRAME", "<iframe")
                        this.setState({ taskCodeData: ds.replace("</IFRAME>", "</iframe>") ,smartSheet:resp.responseData.projectData.taskCode, isLoadingState:false })
                    }
                    this.setState({isLoadingState:false})
                })
        } catch (err) {

        }
    }

    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, smartsheetError: '', });
    }

    //open popup
    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({
            smartSheet: '',
            smartsheetError: ''
        })
    }

    // on submit form
    handleOnSubmitForm = (e) => {
        // var regexAllTags = /<([a-zA-Z1-6]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/
        if (!this.state.isEdit && this.state.smartSheet == "") {
            this.setState({ smartsheetError: "*Please enter smartsheet content." })
        }
        // else if(this.state.smartSheet != "" && !regexAllTags.test(this.state.smartSheet))
        // this.setState({ smartsheetError: "*Please enter valid smartsheet content." })

        else {
            this.setState({ isLoadingState: true})
            let formData = new FormData()
            formData.append("projectId", this.props.match.params.id);
            formData.append("taskCode", this.state.smartSheet == "" ? undefined :this.state.smartSheet)
            _Api(Url.ADMIN_EDIT_PROJECT.method,  Url.ADMIN_EDIT_PROJECT.url, formData).then(resp => {
                notify("success", resp.responseData.message)
                this.setState({taskCodeData:'', isLoadingState: false, inviteNewPopupOpen: false })
                this.getSiteDetails()

            }).catch(err => {
                this.setState({ isLoadingState: false, })
                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)

            })
        }
    }

    //edit user
    onClickEditUser = (task) => {
        this.props.history.push(`task/edit/${task._id}`, { state: task });
    }
    handleEdit = ()=>{
        this.setState({isEdit:true,  inviteNewPopupOpen: !this.state.inviteNewPopupOpen })
    }

    render() {
        return (
            <>
                <div class="row">
                    <div className="col-sm-12">
                        {this.state.isLoadingState && <Loader />}
                        <i class="fa fa-pencil-square-o float-right edit_pencil" onClick={this.handleEdit} aria-hidden="true"></i>
                        <br /><br />
                        {
                             this.state.isLoadingState ?  <center> <Spinner animation="border" role="status">
                             <span className="sr-only">Loading...</span>
                         </Spinner> </center> : this.state.taskCodeData != "" ?
                                <div className="management_edit">
                                    {renderHTML(this.state.taskCodeData)}

                                    {/* {this.state.taskCodeData} */}
                                </div>
                                :
                                <div className="upload_parent">
                                    <img src={require('../../assets/img/Pdf_icon.png')} />
                                    <h6>or use the “Upload” button</h6>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-info upload_btn" onClick={this.openInviteNewPopupOpen}>Add Smartsheet</button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered >
                    <Modal.Header closeButton>
                        <h6>{this.state.isEdit ? "Edit SmartSheet" : "Add Smartsheet"}</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div class="form-group">
                                {/* <label for="email">Add Smart Sheet Content</label><span className="impFields">*</span> */}
                                <textarea type="text" rows="4" cols='50'
                                    className={`form-control ${this.state.smartsheetError != '' ? 'is-invalid' : ''}`}
                                    name="smartSheet"
                                    placeholder="Enter smartsheet content"
                                    autocomplete="off"
                                    value={this.state.smartSheet}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.smartsheetError}
                                />
                            </div>
                            <br />
                            <div class="form-group">
                                {
                                   
                                            <button name="save" type="button" className="btn btn-primary btn-blue" onClick={this.handleOnSubmitForm}>Save</button>
                                       
                                }
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        // taskList: state.tasks.taskInfo,
        usersInfo: state.users.userInfo,
        activeUserDetails: state.users.activeUser,
        inActiveUserDetails: state.users.inactiveUser,
        totalUserDetails: state.users.totalUser,
        dataCount: state.tasks.dataCount
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ addNewTask, addNewProject }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);































