import {
    IS_LOADING_START,
    IS_LOADING_STOP,

    GET_LIST_ALL_USER_SUCCESS,
    GET_LIST_ALL_USER_FAILURE,
    GET_TAG_LIST_FILTER

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";


// api services
import { _getTagsFilters } from "../../config/api/GetServices";


// action for getUser list
export const getTagForFIlters = req => dispatch => {
    //  if(req == undefined) return;
    
    dispatch({ type: IS_LOADING_START })
    _getTagsFilters(req)
        .then(resp => {

            let payload = {
                filterInfo: resp.responseData
            }
            dispatch({ type: GET_TAG_LIST_FILTER, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {


            dispatch({ type: IS_LOADING_STOP })

        })

}




