/**
 * @about this file contains action types
 * which type of action fire or which type
 * 
 */

// used for global loading and loader
export const IS_LOADING_START = "IS_LOADING_START";
export const IS_LOADING_STOP = "IS_LOADING_STOP";

// local loading
export const IS_LOADER_START = "IS_LOADER_START"
export const IS_LOADER_STOP = "IS_LOADER_STOP"


// for login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// logout
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT"
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE"


// for tags
export const IS_ADD_TAG_SUCCESS = "IS_ADD_TAG_SUCCESS";
export const IS_ADD_TAG_FAILURE = "IS_ADD_TAG_FAILURE";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_FAILURE = "GET_TAG_LIST_FAILURE";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILURE = "EDIT_TAG_FAILURE";


// for folder
export const ADD_FOLDER_SUCCESS = "ADD_FOLDER_SUCCESS";
export const ADD_FOLDER_FAILURE = "ADD_FOLDER_FAILURE";
export const GET_FOLDER_SUCCESS = "ADD_FOLDER_SUCCESS";
export const GET_FOLDER_FAILURE = "GET_FOLDER_FAILURE";



// get all user list
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";
export const GET_LIST_ALL_USER_SUCCESS = "GET_LIST_ALL_USER_SUCCESS";
export const GET_LIST_ALL_USER_FAILURE = "GET_LIST_ALL_USER_FAILURE";


// get all user list
export const ADD_NEW_COMMUNITY_SUCCESS = "ADD_NEW_COMMUNITY_SUCCESS";
export const ADD_NEW_COMMUNITY_FAILURE = "ADD_NEW_COMMUNITY_FAILURE";
export const GET_LIST_ALL_COMMUNITY_SUCCESS = "GET_LIST_ALL_COMMUNITY_SUCCESS";
export const GET_LIST_ALL_COMMUNITY_FAILURE = "GET_LIST_ALL_COMMUNITY_FAILURE";

// get all report list
export const ADD_NEW_REPORT_SUCCESS = "ADD_NEW_REPORT_SUCCESS";
export const ADD_NEW_REPORT_FAILURE = "ADD_NEW_REPORT_FAILURE";
export const GET_LIST_ALL_REPORT_SUCCESS = "GET_LIST_ALL_REPORT_SUCCESS";
export const GET_LIST_ALL_REPORT_FAILURE = "GET_LIST_ALL_REPORT_FAILURE";

// get all resources list
export const ADD_NEW_RESOURCE_SUCCESS = "ADD_NEW_RESOURCE_SUCCESS";
export const ADD_NEW_RESOURCE_FAILURE = "ADD_NEW_RESOURCE_FAILURE";
export const GET_LIST_ALL_RESOURCE_SUCCESS = "GET_LIST_ALL_RESOURCE_SUCCESS";
export const GET_LIST_ALL_RESOURCE_FAILURE = "GET_LIST_ALL_RESOURCE_FAILURE";


// get all media list
export const ADD_NEW_MEDIA_SUCCESS = "ADD_NEW_MEDIA_SUCCESS";
export const ADD_NEW_MEDIA_FAILURE = "ADD_NEW_MEDIA_FAILURE";
export const GET_LIST_MEDIA_REPORT_SUCCESS = "GET_LIST_MEDIA_REPORT_SUCCESS";
export const GET_LIST_MEDIA_REPORT_FAILURE = "GET_LIST_MEDIA_REPORT_FAILURE";

// get all map list
export const ADD_NEW_MAP_SUCCESS = "ADD_NEW_MAP_SUCCESS";
export const ADD_NEW_MAP_FAILURE = "ADD_NEW_MAP_FAILURE";
export const GET_LIST_MAP_SUCCESS = "GET_LIST_MAP_SUCCESS";
export const GET_LIST_MAP_FAILURE = "GET_LIST_MAP_FAILURE";

// restore 
export const RESET_STORE_SUCCESS = "RESET_STORE_SUCCESS";
export const RESET_STORE_FAILURE = "RESET_STORE_FAILURE";



// get all site list
export const ADD_NEW_SITE_SUCCESS = "ADD_NEW_SITE_SUCCESS";
export const ADD_NEW_SITE_FAILURE = "ADD_NEW_SITE_FAILURE";
export const GET_LIST_SITE_SUCCESS = "GET_LIST_SITE_SUCCESS";
export const GET_LIST_SITE_FAILURE = "GET_LIST_SITE_FAILURE";
export const GET_LIST_ALL_SITE_SUCCESS = "GET_LIST_ALL_SITE_SUCCESS";
export const GET_LIST_ALL_SITE_FAILURE = "GET_LIST_ALL_SITE_FAILURE";


// get all project list
export const ADD_NEW_PROJECT_SUCCESS = "ADD_NEW_PROJECT_SUCCESS";
export const ADD_NEW_PROJECT_FAILURE = "ADD_NEW_PROJECT_FAILURE";
export const GET_LIST_PROJECT_SUCCESS = "GET_LIST_PROJECT_SUCCESS";
export const GET_LIST_PROJECT_FAILURE = "GET_LIST_PROJECT_FAILURE";
export const GET_LIST_ALL_PROJECT_SUCCESS = "GET_LIST_ALL_PROJECT_SUCCESS";
export const GET_LIST_ALL_PROJECT_FAILURE = "GET_LIST_ALL_PROJECT_FAILURE";


export const ADD_NEW_TASK_SUCCESS = "ADD_NEW_TASK_SUCCESS";
export const ADD_NEW_TASK_FAILURE = "ADD_NEW_TASK_FAILURE";
export const GET_LIST_TASK_SUCCESS = "GET_LIST_TASK_SUCCESS";
export const GET_LIST_TASK_FAILURE = "GET_LIST_TASK_FAILURE";
export const GET_LIST_ALL_TASK_SUCCESS = "GET_LIST_ALL_TASK_SUCCESS";
export const GET_LIST_ALL_TASK_FAILURE = "GET_LIST_ALL_TASK_FAILURE";


//****************************************************************************/


// delete user
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"

// search user
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE"

// change status of active or in active
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS"
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR"
export const GET_USER_BY_STATUS_SUCCESS = "GET_USER_BY_STATUS_SUCCESS"
export const GET_USER_BY_STATUS_FAILURE = "GET_USER_BY_STATUS_FAILURE"

// get user profile by user
export const GET_USER_PROFILE_BY_ADMIN_SUCCESS = "GET_USER_PROFILE_BY_ADMIN_SUCCESS"
export const GET_USER_PROFILE_BY_ADMIN_FAILURE = "GET_USER_PROFILE_BY_ADMIN_FAILURE"

// profile



export const GET_PROFILE_TYPE_ARRAY_SUCCESS = "GET_PROFILE_TYPE_ARRAY_SUCCESS";

// log out

export const GET_TAG_LIST_FILTER = "GET_TAG_LIST_FILTER"
