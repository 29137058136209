
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ResourceProjectList from "./ProjectResourceList"
import ProjectResourceEdit from "./ProjectResourceEdit";
import ResourceData from './ProjectResourceImport';

class ResourceList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() {
        return (
            <Switch>
                <Route exact path={`/projects/edit/:folderId/:id/resource`} component={ResourceProjectList} />
                <Route exact path={`/projects/edit/:folderId/:id/resource/data`} component={ResourceData} />
                <Route exact path={`/projects/edit/:folderId/:id/resource/:eId`} component={ProjectResourceEdit} />
            </Switch>
        );

    }
}

export default ResourceList;

