
 

    import {      
        GET_LIST_MAP_SUCCESS,
        GET_LIST_MAP_FAILURE,
    } from "../actions/Types";

    import redexState from "./InitalState";


    
    
    // get All list users
    export const maps = (state = redexState.mapsInitalState, action) => {

        switch (action.type) {
          
            case GET_LIST_MAP_SUCCESS:
                return {
                    ...state,
                    ...action.payload.mapInfo
                }
            case GET_LIST_MAP_FAILURE:
                return {
                    ...state,
                }
            default:
                return {...state}
        }
    
    }