import {
    GET_FOLDER_SUCCESS,
    GET_FOLDER_FAILURE
    } from "../actions/Types";
    
    import redexState from "./InitalState";


   
    
    // get All list folders
    export const folders = (state = redexState.folderInitalState, action) => {
        switch (action.type) {
            case GET_FOLDER_SUCCESS:
                return {
                    ...state,
                    ...action.payload,
    
                }
            case GET_FOLDER_FAILURE:
                return {
                    ...state,
                   
                }
    
            default:
                return {...state}
        }
    
    }