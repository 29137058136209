
import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    ADD_NEW_RESOURCE_SUCCESS,
    ADD_NEW_RESOURCE_FAILURE,
    GET_LIST_ALL_RESOURCE_SUCCESS,
    GET_LIST_ALL_RESOURCE_FAILURE


} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";
import {getTagForFIlters} from "./Tag"

// api services
import { _addResources, _getResources,  } from "../../config/api/ResourcesServices";

// url
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




// get resource
export const getAllResourcesList = req => dispatch => {
    dispatch({ type: IS_LOADING_START })
    getTagForFIlters({tagType:req.tagType, folder:req.folder})(dispatch)
    _Api(Url.ADMIN_GET_RESOURCE.method, Url.ADMIN_GET_RESOURCE.url, req )
        .then(resp => {
            let payload = {
                resourceInfo: resp.responseData
            }
            dispatch({ type: GET_LIST_ALL_RESOURCE_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })

        })

}




// add resource
export const addNewResource = (req, id, req2) => dispatch => {   
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_RESOURCE.method, Url.ADMIN_ADD_RESOURCE.url, req )
        .then(resp => {
            getAllResourcesList(req2)(dispatch)
            dispatch({ type: IS_LOADING_STOP })
           setTimeout(()=>{ notify("success", resp.responseData.message)},300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}
