

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewReport, getAllReportList } from "../../redux/actions/Report";
import { addNewMedia, getAllMediaList } from "../../redux/actions/Media";
import { getAllTags, getAllFolders } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
import { resetStore } from "../../redux/actions/Common";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";
// validation
import { validateEmail, validateMobileNo1 } from "../../utils/Validation";

// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"

// video player


import { Player, BigPlayButton , ControlBar } from 'video-react';
import { Select, Icon } from 'antd';
import AddFolder from "../add_folder_common/AddFolderTag";
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


var data = []

const { Option } = Select;

class MediaList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,

            tagId: '',
            showDeleteModal: false,
            showMediaModal: false,
            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,

            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            mediaName: '',
            mediaNameError: '',
            mediaDescription: '',
            mediaDescriptionError: '',
            mediaUrl: '',
            mediaFile: '',
            mediaProjectId: '',
            mediaSiteId: '',
            mediaTagId: '',
            deletedId: '',
            mediaUrlError: '',
            playData: "",
            fileUploadError: "",
            fileStatus: true,
            siteId: '',

            index: 0,

            fileName: 'Choose file...',
            folderId: '',
            folderIdError: '',
            showIcon:true,
            dataShow:false



        }
    }
 
    openMediaModal = (media, index) => {
        data = this.props.mediaList
        this.setState({ playData: media, index }, () => {
            this.setState({
                showMediaModal: !this.state.showMediaModal
            })
        })

    }

    componentDidMount() {

        this.onClickPagenation();
        this.props.action.getAllTags({status:"1"});
        this.props.action.getAllSiteList({status:"1"});
        let req = {
            folderType: 7
        }
        this.props.action.getAllFolders(req)

    }


    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            mediaDescriptionError: '',
            mediaNameError: '',
            fileUploadError: '',


        });

    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
            showMediaModal: false,
        })

        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        try {
            this.setState({
                mediaName: '',
                mediaNameError: '',
                mediaDescription: '',
                mediaDescriptionError: '',
                mediaUrl: '',
                mediaFile: '',
                mediaProjectId: '',
                mediaSiteId: '',
                mediaTagId: '',
                deletedId: '',
                mediaUrlError: '',
                fileName: 'Choose file...',
                folderIdError: '',
                // folderId: '',





            })

        } catch (err) {

        }

    }

    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
        this.setState({ mediaFile: file, fileName: file.name, fileUploadError: "", })
    }

    // on submit form
    handleOnSubmitForm = (e) => {

        try {

            if (this.state.mediaName == "") {
                this.setState({ mediaNameError: "*Please enter media name." })
            }
            else if (this.state.mediaDescription == "") {
                this.setState({ mediaDescriptionError: "*Please enter description." })
            }

            else if (this.state.mediaFile == "") {
                this.setState({ fileUploadError: "*please add a file." })
            }
            else if (this.state.mediaFile.type.split("/")[0] != "image" && this.state.mediaFile.type.split("/")[0] != "video") {

                this.setState({ fileUploadError: "*Please add a valid file.", fileStatus: false })
            }

            else if (this.state.folderId == "") {
                this.setState({ folderIdError: "*Please select folder" })
            }
            else {


                let formData = new FormData()
                formData.append("mediaName", this.state.mediaName);
                formData.append("desc", this.state.mediaDescription)
                formData.append("folder", this.state.folderId)
                formData.append("url", this.state.mediaUrl);
                formData.append("project", this.props.match.params.id)
                formData.append("tag", this.state.mediaTagId)
                formData.append("fileData", this.state.mediaFile)
                let req2 = {
                    project: this.props.match.params.id,
                    search: this.state.searchString,
                    status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                    sortType: this.state.sortType,
                    sortField: this.state.sortField,
                    pageNo: this.state.pageNo,
                }


                if (e.target.name == "save") {

                    this.props.action.addNewMedia(formData, req2)
                    this.closeModal()
                    this.clearFields()

                }
                else {

                    this.props.action.addNewMedia(formData, req2)
                    this.clearFields();
                }
            }
        }
        catch (err) {

        }
    }

    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletedId: id });
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (statusCode, mediaId) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            mediaId: mediaId
        }

        _Api(Url.ADMIN_DELETE_MEDIA.method, Url.ADMIN_DELETE_MEDIA.url, req )
            .then(resp => {
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '' })
                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                this.setState({ isLoadingState: false, showDeleteModal: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditUser = (media) => {
        this.props.history.push(`/media/edit/${this.props.match.params.id}/${media._id}`, { state: media });

    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.totalNextPage) > this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        if (page)
            this.setState({ activeClass: page == "A" ? "a" : (page == "I" ? "i" : "t") })
        if (page == "A" || page == "I" || page == "T")
            this.setState({ pageNo: 0 })



        let req = {
            project: this.props.match.params.id,
            search: this.state.searchString,
            status: page == undefined ? this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0" : page == "A" ? "1" : (page == "I" ? "0" : ""),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: this.state.pageNo,
        }



        this.props.action.getAllMediaList(req);
    }


    // on search
    handleOnSearch = (e) => {
        let str = e.target.value.trim()
        if (str.length >= 3)
            this.setState({ searchString: str, pageNo: 0 }, () => this.onClickPagenation())
        else if (str == "")
            this.setState({ searchString: str, pageNo: 0 }, () => this.onClickPagenation())

    }


    // on click

    handleOnClickSort = (e) => {

        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'reportName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = (e) => {
        try{
         if(e.target.name == "folder")
         this.clickChild1()
         else if(e.target.name == "tag")
         this.clickChild()
         this.setState({ inviteNewPopupOpen: false })
        }
        catch(err){} 
     }

    actionMedia = (action) => {   
        if (action == -1) {
            if (this.state.index > 0)
                this.setState({ index: --this.state.index, showIcon:true })
        }
        else if (action == 1) {
            if (data.length > this.state.index + 1)
                this.setState({ index: ++this.state.index, showIcon:true })
        }

    }


    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }


  play = ()=> {
      this.setState({showIcon:false})
    this.player.play();
  }

  componentWillUnmount() {
    this.props.action.resetStore();
}


    render() {
        setTimeout(()=> this.setState({dataShow:true}), 2000)

        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <AddFolder
                  folderType ={7}
                  setClick={click => this.clickChild1 = click}
                  closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />



                <div className="body_header ">
                    {/* <h6 className = "headingH6" > <Link to="/media">MEDIA</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}
                    <div className="new_btn text-right btn_parent">
                        <div className="btn_child">
                            <button style={{ margin: 17 , marginRight:0}} type="button" className="btn btn-primary btn-sm btn-blue" onClick={this.openInviteNewPopupOpen}>Add New</button>
                            &nbsp;&nbsp; <button style={{ margin: 17 , marginLeft:0 }} type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.props.history.push("media/data")}>Import </button>
                        </div>

                    </div>
                </div>

                <div className="media_parent">
                    {
                        this.props.mediaList &&
                        this.props.mediaList.map((media, index) => (
                            <div className="media_container" key={index}  >
                                <Dropdown className="header_dropdown_btn more_dropdown_btn">
                                    <Dropdown.Toggle className="no_btn" id="dropdown-basic">
                                        <span className="more_dots"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul className="profile_menu edit_menu">
                                            <li><span className="linkcss" onClick={() => this.handleDeleteModal(media._id)}><p>Delete</p></span></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <div className="media center"> */}
                                <div class="embed-responsive embed-responsive-16by9">
                                    <img src={media.thumbnail} onClick={() => this.openMediaModal(media, index)} className="img-fluid" />
                                    <p className="img_heading txtClassUpper">{media.mediaName}</p>
                                   
                                </div>
                            </div>
                        ))
                    }
                </div>

                {
                    this.state.dataShow && this.props.mediaList.length == 0 && this.props.isLoading != true && <p className="cm_center_text"  > Sorry no more content </p>
                }

                {/* ----------------------modal for media-------------- */}
                <Modal show={this.state.showMediaModal} onHide={this.closeModal} className="media_modal" centered>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="media_parent">
                            {data.length != 0 &&
                                <div className="img_div">
                                    {/* <img src={require('../../assets/img/dummy_about_project.jpg')} className="img-fluid"/> */}
                                    {data && data[this.state.index].mediaType == "I" ?
                                        <img src={data && data[this.state.index].fileData} className="img-fluid" />
                                        :
                                        <Player onPause = {()=> this.setState({showIcon:true})} onPlay={()=> this.setState({showIcon:false})} onClick={()=> alert("okkkk")} ref={player => {
                                            this.player = player;
                                          }} >
                                            <source src={this.state.playData && this.state.playData.fileData} />
                                            <BigPlayButton  position="center" autoHide={true}/>
                                            <ControlBar autoHide={true} className="my-class" />
                                        </Player>


                                    }
                                    <div className="carousel_btn">
                                        {/* <!-- Left and right controls --> */}
                                        { this.state.showIcon && data && data[this.state.index].mediaType != "I" && <i onClick={this.play} class="fa fa-play-circle" aria-hidden="true"></i>}
                                        <a class="left carousel-control ">
                                            <i class="fa fa-chevron-left" aria-hidden="true" onClick={() => this.actionMedia(-1)}></i>
                                        </a>
                                        <a class="right carousel-control">
                                            <i class="fa fa-chevron-right" aria-hidden="true" onClick={() => this.actionMedia(1)}></i>
                                        </a>
                                    </div>
                                </div>
                            }
                            {
                                data.length != 0 &&
                                <div className="desc_div">
                                    <p>{data && data[this.state.index].mediaName} </p>
                                    <div className="icon_parent">
                                        <a href={data && data[this.state.index].fileData} download>
                                            <div className="icon">
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                                <p>Download</p>
                                            </div>
                                        </a>

                                    </div>
                                    <div className="text">
                                        <p>
                                            {
                                                data[this.state.index].desc
                                            }
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>


                    </Modal.Body>

                </Modal>
                {/* ----------------------modal for media end-------------- */}
                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6>Add New</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                        

                            <div class="form-group">
                                <label for="name">Media Name</label><span className="impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.mediaNameError != '' ? 'is-invalid' : ''}`}
                                    id="mediaName"
                                    name="mediaName"
                                    placeholder="Enter media name"
                                    maxLength={20}
                                    value={this.state.mediaName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.mediaNameError}
                                />
                            </div>




                            <div class="form-group">
                                <label for="desc">Description</label><span className="impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.mediaDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="mediaDescription"
                                    placeholder="Enter Description"
                                    maxLength={256}
                                    name="mediaDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.mediaDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.mediaDescriptionError}
                                />
                            </div>



                            <div class="form-group row df-end">
                                <div className="col-md-12">
                                    <label for="email">Media file</label><span className="impFields">*</span><br />
                                    <div class="custom-file">
                                        <input id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadError != '' ? 'is-invalid' : ''}`}

                                            accept="image/*, video/*"
                                        />
                                        <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                    </div>
                                    <InlineError
                                        message={this.state.fileUploadError}
                                    />

                                </div>
                            </div>



                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Folder</label><span className="impFields">*</span><br />
                                    <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                        {
                                            this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                <Option value={item._id}>
                                                    <div class="dropdown_flex">
                                                        <div><img src={item.icon} /></div>
                                                        <div><p>{item.folderName}</p></div>
                                                    </div>
                                                </Option>

                                            ))
                                        }

                                    </Select>
                                    <InlineError
                                        message={this.state.folderIdError}
                                    />

                                </div>
                                <div className="col-md-4"><button name="folder" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Folder</button></div>

                            </div>




                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>
                                    <select class="form-control" id="sel1" name="mediaTagId" onChange={this.handleOnChange}> 
                                    {this.state.mediaTagId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option> }
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button name="tag" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <br />
                            <div class="form-group text-center">
                                {/* <a onClick={this.closeModal} className="mR-20">cancel</a> */}
                                <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                            </div>

                        </form>


                    </Modal.Body>

                </Modal>
                
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}

                />


                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Media</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want delete this media ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button className="btn btn-primary btn-sm btn-blue "  onClick={() => this.onUserAction("2", this.state.deletedId)}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        mediaList: state.medias.mediaInfo,
        siteInfo: state.sites.siteInfo,
        mediaActive: state.medias.activeTag,
        mediaInactive: state.medias.inactiveTag,
        mediaTotal: state.medias.totalTag,
        mediaData: state.medias.dataCount,
        foldersList: state.folders.folderList


    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getAllFolders, getAllUserList, getAllTags, addNewUser, addNewReport, getAllReportList, addNewMedia, getAllMediaList, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);

