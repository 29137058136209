/**
 * @about site edit file edit site that already created
 * 
 */

// lib
import React, { Component } from 'react';
//redux
import { connect } from "react-redux";
import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';
import { bindActionCreators } from "redux";
// errror show
import InlineError from "../../components/common/InlineError";
// actions
import { getAllUserList } from "../../redux/actions/User";

import { getAllTags } from "../../redux/actions/Get";
// services 
import {  _getProfileType } from "../../config/api/UserService";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader";
import AddTag from "../../components/common/add_tag/AddTag"
import { Select } from 'antd';
import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from "../../utils/MyFilter"
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";
import { Spinner } from 'react-bootstrap';



// site edit screen
const { Option } = Select;
class ProjectGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: true,
            inviteNewPopupOpen: false,
            chips: [],
            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',
            tagId: '',
            challanges: '',
            strengths: '',
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',
            user: [],
            showTagName: '',
            fileName: '',
            fileUploadFileError: '',
            userDetails: [],
            userDetails1: [],
            clientIdError: '',
            clientUserList: [],
            coverImg: '',
            clientName: '',



            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',
            isSpinner:false,

            toggleState:false




        }

    }


    onChange = chips => {
        this.setState({ chips });
    }


    componentDidMount() {
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getAllUserList({ status: "1" });
        this.getProjectApi();

        // this.getDataProfileType()

    }

    handleSecleted = (e) => {
        this.setState({ checked: e })
    }



    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 4)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)
        let arr6 = await getDataAndFilter(resp.responseData.profileInfo, 6)



        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                isChecked: false,
                isOther: item1.levelName == "OTHER",
                inputValue: '',
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    isChecked: false,
                    isOther: data.levelName == "OTHER",
                    inputValue: '',
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        isChecked: false,
                        isOther: data2.levelName == "OTHER",
                        inputValue: '',
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            isChecked: false,
                            isOther: data3.levelName == "OTHER",
                            inputValue: '',
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                                isChecked: false,
                                isOther: data4.levelName == "OTHER",
                                inputValue: '',
                                children: arr6.filter(item6 => item6.parentLevel == data4._id).map((data5, index5) => ({

                                    value: `${data5._id} `,
                                    label: data5.levelName,
                                    isChecked: false,
                                    isOther: data5.levelName == "OTHER",
                                    inputValue: '',
                                }))
                            }))
                        }))
                    }))
                }))


            }
        ))



        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }

    getProjectApi = () => {
        try {
            if (this.props.match.params)
            _Api(Url.ADMIN_VIEW_PROJECT.method, Url.ADMIN_VIEW_PROJECT.url, '', this.props.match.params.id   )
                    .then(resp => {
                        const {
                            desc,
                            folder,
                            tag,
                            user,
                            profile,
                            cover,
                            _id } = resp.responseData.projectData
                           

                        this.setState({
                            clientId: resp.responseData.projectData.clientId._id,
                            clientName: resp.responseData.projectData.clientId.clientName,
                            // profile:resp.responseData.projectData.profile,
                            checked: profile  == undefined || profile.length == 0 ? []:profile.split(','),
                            fileName: cover,
                            tagId: tag == undefined ? '' : tag._id,
                            showTagName: tag == undefined ? '' : tag.tagName,
                            description: desc,
                            user, showUserName: resp.responseData.projectData.projectName,
                            name: resp.responseData.projectData.projectName,
                            isLoadingState: false, folderName: folder.folderName,
                            folder,
                            userDetails1: resp.responseData.userDetails,
                            _id,
                            isSpinner:false,
                            nodeData:JSON.parse(profile),
                        }, () => this.onSelectClientGetUsers())

                    })

                    .catch(err=> {})
                    .catch(err => {

                    })

        } catch (error) {

        }
    }

    //handle on handleChangeAll
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' });

    }

    // on submit
    onSaveSite = () => {

        try {

            if (this.state.name == "") {
                this.setState({ nameError: "*Please enter site name." })
            }
            else if (this.state.description == "") {
                this.setState({ descriptionError: "*Please enter description." })
            }

            else if (this.state.coverImg != "" && this.state.coverImg.type.split("/")[0] != "image") {

                this.setState({ fileUploadFileError: "*Please add a valid cover image.", })
            }

            else {
                this.setState({ isLoadingState: true })
                let formData = new FormData()
                formData.append("projectName", this.state.name);
                formData.append("projectId", this.state._id);
                formData.append("clientId", this.state.clientId);
                formData.append("desc", this.state.description)
                formData.append("userIds", this.state.userDetails);
                formData.append("tag", this.state.tagId)
                formData.append("cover", this.state.coverImg)
                formData.append("profile",JSON.stringify(this.state.nodeData))

               

                _Api(Url.ADMIN_EDIT_PROJECT.method,  Url.ADMIN_EDIT_PROJECT.url, formData)
                    .then(resp => {
                    notify("success", resp.responseData.message)
                    this.setState({ isLoadingState: false, })
                    // this.props.history.push(`/projects/${this.props.match.params.folderId}`, { state: this.state.folderName });

                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({ isLoadingState: false, })
                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)
                })
            }


        } catch (err) {
            this.setState({ isLoadingState: false })
        }
    }

    onImageUpload = e => {
        try {
            let file = e.target.files[0]
            this.setState({ coverImg: file, fileName: URL.createObjectURL(file), fileUploadFileError: '' })
        } catch (err) {

        }
    }




    // redirect to
    goBack = () => {
        this.props.history.goBack()
    }
    // adding popup
    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })

    }


    handleOnSelect = (data) => {
        if (this.state.userDetails.indexOf(data) == -1) {
            this.setState({ userDetails: [...this.state.userDetails, data] })

        }
        else if (this.state.userDetails.indexOf(data) != -1) {
            this.setState({ userDetails: this.state.userDetails.filter(item => item != data) })
        }

    }

    onSelectClients = (e) => {
        try {
            this.setState({ clientId: e.target.value, userDetails: [] }, () => {
                this.onSelectClientGetUsers()

            })

        } catch (err) {

        }

    }

    handleChangeMultiSelect = (value) => {

    }

    onSelectClientGetUsers = () => {
        try {
            if (this.state.clientId) {
                let req = { clientId: this.state.clientId, status: "1" }
                _Api(Url.ADMIN_GET_USERS.method, Url.ADMIN_GET_USERS.url,req)
                    .then(resp => {
                        this.setState({ userDetails: this.state.userDetails1, clientUserList: resp.responseData.usersInfo.userInfo.userInfo, isLoadingState: false })

                    })
                    .catch(err => {

                    })
            }
        }
        catch (err) { }
    }




    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )
            
        }
        this.setState({toggleState:!this.state.toggleState})       
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }


    render() {
        return (
            <>
                {this.state.isLoadingState && <Loader />}

                <div className="col-sm-12">
                    {/* <a href="#" > <i class="fa fa-pencil float-right edit_pencil" aria-hidden="true"></i></a> */}
                </div>
                    <div className="row">
                    <div className="col-sm-6">
                            <form>
                                <div class="form-group">
                                    <label for="email">Name</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        maxLength={30}
                                        name='name'
                                        value={this.state.name}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={this.state.nameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Description</label> <span className="impFields">*</span><br />
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                        id="reportDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name='description'
                                        value={this.state.description}
                                        onChange={this.handleChangeAll}

                                    />

                                    <InlineError
                                        message={this.state.descriptionError}
                                    />
                                </div><br />



                                <div class="form-group row df-end">
                                    <div className="col-md-8" >
                                        <label for="email">Cover image</label><span className="impFields">*</span><br />
                                        <img
                                            src={this.state.fileName}
                                            style={{}}
                                            className="upload_fileimg"
                                        />

                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            aria-hidden="true"
                                            accept="image/png,image/jpeg"
                                            className={`form-control ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                            id="sel1"
                                            ref="fileUploader"
                                            files={this.state.iconImag}
                                            onChange={this.onImageUpload}
                                        />


                                        <InlineError
                                            message={this.state.fileUploadFileError}
                                        />


                                    </div>
                                    <div className="col-md-4"><button type="button" onClick={() => this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-12">Browse</button></div>

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Client</label>
                                        <input type="text"
                                            className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                            id="Name"

                                            value={this.state.clientName}

                                            disabled
                                        />
                                    </div>


                                </div>
                                <br />
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">User</label><br />
                                        <Select
                                            mode="multiple"
                                            placeholder="Please select "
                                            // defaultValue={}
                                            value={this.state.userDetails}
                                            onChange={this.handleChangeMultiSelect}
                                            onSelect={(i) => this.handleOnSelect(i)}
                                            onDeselect={(i) => this.handleOnSelect(i)}
                                        >
                                            {this.state.clientUserList.map((item, index) => (
                                                <Option value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</Option>

                                            ))}
                                        </Select>
                                    </div>

                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-9">
                                        <label >Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleChangeAll}>

                                            {this.state.showTagName ? <option key={-2} selected hidden value={this.state.tagId} >{this.state.showTagName}</option> : <option key={-1} value={"select"} selected hidden  >{"Select tag"}</option>}
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.tagName}</option>
                                                    )

                                                )
                                            }
                                        </select>
                                    </div>
                                    <AddTag
                                        setClick={click => this.clickChild = click}
                                        closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                                    />
                                    <div className="col-md-3"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>
                                </div>
                              
                                <br />
                                <div class="form-group float-right">
                                    <button type="button" class="btn btn-pink mR-20" onClick={this.goBack}>Exit</button>
                                    <button type="button" class="btn btn-primary btn-blue" onClick={this.onSaveSite}>Save</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-4 offset-sm-1">
                               <p>Project Profile</p>
                               {
                            this.state.isSpinner  &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                                    <Accordion defaultActiveKey="00000000">
                            {
                                Object.values(this.state.nodeData).map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" placeholder = "Enter here..." className="form-control " value = {item3.inputValue} onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item5}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item5}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                            {
                                                                                                                item5.children && item5.children.map((item6, index6) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item6}`} >
                                                                                                                                {item6.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item6.isChecked} onClick={() => this.handleCheckedBox(item6)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item6.label} </span>
                                                                                                                                {item6.isOther && item6.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item6.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item6)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item6}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>

                          </div>

                    </div>
            

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllTags, getAllUserList, }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGeneral);