import {
    IS_LOADING_START,
    IS_LOADING_STOP,
} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// login api service function
import { _addTags, _addFolder } from "../../config/api/AddServices";
import { getSiteFolders, getProjectFolder } from "./Get";

//action 
import { getAllTags, getAllFolders } from "./Get";


// url
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";





// action for add new tag
export const addNewTag = (req, req2) => dispatch => {
    try {
        dispatch({ type: IS_LOADING_START })
        _Api(Url.ADMIN_ADD_TAG.method, Url.ADMIN_ADD_TAG.url, req).then(resp => {
           getAllTags(req2)(dispatch);
            notify("success", resp.responseData.message)
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)
        })

    } catch (error) {
        notify("err", error.message)

    }

}


// action for add new tag
export const addNewFolder = (req, typeFolder, isCall = true, req2, route = 2) => dispatch => {
    try {

        dispatch({ type: IS_LOADING_START })
        _Api(Url.ADMIN_ADD_FOLDER.method, Url.ADMIN_ADD_FOLDER.url, req)
            .then(resp => {
                if (isCall)
                    getAllFolders({ folderType: typeFolder })(dispatch)
                else {
                    if (route == 1)
                        getProjectFolder({ ...req2 })(dispatch)
                    else
                        getSiteFolders({ ...req2 })(dispatch);
                }

                notify("success", resp.responseData.message)
                dispatch({ type: IS_LOADING_STOP })
            }).catch(err => {
                dispatch({ type: IS_LOADING_STOP })
                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)
            })

    } catch (error) {
        notify("err", error.message)

    }

}



