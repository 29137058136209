
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ResourceSiteList from "./SiteResourceList"
import SiteResourceEdit from "./SiteResourceEdit";
import SiteImportResource from "./SiteResourceImport";

class ResourceList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Switch>
                <Route exact path={`/sites/edit/:folderId/:id/resource`} component={ResourceSiteList} />
                <Route exact path={`/sites/edit/:folderId/:id/resource/data`} component={SiteImportResource} />
                <Route exact path={`/sites/edit/:folderId/:id/resource/:eId`} component={SiteResourceEdit} />
            </Switch>
        );

    }
}

export default ResourceList;

