
/**
 * @about add services api file that contains Get services function
 * Get user
 * Get tags
 * Get folders
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="


// Get tags by admin
export const _getTags = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_TAG,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// Get folders by admin by admin
export const _getFolders = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_FOLDER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// Get reports by admin by admin
export const _getReports = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_REPORTS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// Get reports by admin by admin
export const _getSiteFolders = (request) => {
       return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_GET_SITE_FOLDER}?siteId=${request.siteId}&folderType=${request.folderType}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}




export const _getTagsFilters = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_FILTER_TAG,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}




// Get reports by admin by admin
export const _getProjectFolders = (request) => {
      return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_GET_PROJECT_FOLDER}?projectId=${request.projectId}&folderType=${request.folderType}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



export const _getDashboardInfo = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.ADMIN_GET_DASHBOARD,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}





