/**
 * @about folder view file that create and contains folders
 * create new folder
 * report folder section
 * 
 */
// react lib
import React, { Component } from 'react';
// folder section
import FolderSection from "../add_folder_common/AddFolder";

class Report extends Component { 
    render() {
        return (
            <FolderSection
                {...this.props}
                titleName = "REPORTS"
                folderType = "6"
                link = "reports"
            />
         );
    }
}
export default Report;
