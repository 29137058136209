
/**
 * @about Project services
 * get Project
 * add Project
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="


// add users
export const _addProject = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_PROJECT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add tags by admin
export const _getProjectList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_PROJECT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}






// action for user
export const _actionForProject = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_CHANGE_STATUS_PROJECT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

// action for user
export const _viewProject = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_VIEW_PROJECT}?projectId=${request.projectId}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err)) 
    })

}








// action for user
export const _editProject = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_EDIT_PROJECT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}













