import {
    GET_TAG_LIST_FAILURE,
    GET_TAG_LIST_SUCCESS,
    GET_TAG_LIST_FILTER
} from "../actions/Types";

import redexState from "./InitalState";





// get All list tags
export const tags = (state = redexState.tagsInitalState, action) => {
    switch (action.type) {
        case GET_TAG_LIST_SUCCESS:
            return {
                ...state,
                ...action.payload.tagInfo,

            }
        case GET_TAG_LIST_FAILURE:
            return {
                ...state,

            }
        case GET_TAG_LIST_FILTER:
            return {
                ...state,
                ...action.payload.filterInfo,
            }

        default:
            return {...state}
    }

}