/** 
 * @about entery level of project
 * connect provider 
 * imports extra css & lib
 */

// react lib
import React from 'react';
import ReactDOM from 'react-dom';
// react redux provider
import { Provider } from "react-redux";
// store
import Store from "./redux/store/Store";

// routes
import Routes from './Routes';
// service workder
import * as serviceWorker from './serviceWorker';

// notification
import { notify } from "./components/common/Toaster"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// bootstrap & css
import 'react-image-gallery/styles/css/image-gallery.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';

// css
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


ReactDOM.render(
    <Provider store={Store}>
        <Routes />
        <ToastContainer />
    </Provider>
    , document.getElementById('root'));
serviceWorker.unregister();
