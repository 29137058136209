export const DevSetting = {
    api: {
        url: 'https://devapi.drawbridgesolution.com/drawbridge/api/v1/'
    }
}


export const ProdSetting = {
    api: {
        url: 'https://devapi.drawbridgesolution.com/drawbridge/api/v1/'
    }
}

export const StagSetting = {
    api: {
        url: 'https://stagapi.drawbridgesolution.com/drawbridge/api/v1/'
    }
}

export const localhostSetting = {
    api: {
        url: 'http://192.168.1.10:5004/drawbridge/api/v1/'
    }
}