import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    IS_LOGGED_IN,
    IS_LOGGED_OUT,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAILURE,
} from "../actions/Types";

import redexState from "./InitalState";



// login
export const login = (state = redexState.AuthInitalState, action) => {
    switch (action.type) {
        case LOG_OUT_SUCCESS:
            localStorage.clear()
            return {
                ...state,
                isLoggedIn: false,
                accessToken: ''
            }
        default:
            return {...state}
    }

}