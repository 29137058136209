import {combineReducers}from "redux"

import {
    RESET_STORE_SUCCESS,
    RESET_STORE_FAILURE
} from "../actions/Types";

// reducers
import {login} from "./Auth";
import {loading} from "./Common";
import {users} from "./Users";
import {tags} from "./Tags";
import {folders} from "./Folders";
import {community} from "./Community"
import {reports} from "./Report";
import {medias} from "./Media"
import {resources} from "./Resources";
import {maps} from "./Map";
import {sites} from "./Site";
  import {projects} from "./Project";
  import {tasks} from "./Task";
  import {profileType} from "./ProfileData"


const appReducer = combineReducers({
    login,
    loading,
    users,
    tags,
    folders,
    community,
    reports,
    medias,
    resources,
    maps,   
    sites ,
    projects,
    tasks,
    profileType
})

  export default (state, action) => {
    if (action.type === RESET_STORE_SUCCESS) {
      state = undefined;
    }
  
  
    return appReducer({...state}, action);
}