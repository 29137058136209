import {
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAILURE,
    GET_LIST_ALL_SITE_SUCCESS,
    GET_LIST_ALL_SITE_FAILURE

} from "../actions/Types";

import redexState from "./InitalState";




// get All list users
export const sites = (state = redexState.sitsInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_SITE_SUCCESS:
            return {
                ...state,
                ...action.payload.siteInfo
            }
        case GET_LIST_ALL_SITE_FAILURE:
            return {
                ...state,
            }
        default:
            return {...state}
    }

}