

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Tabs, Tab } from 'react-bootstrap';
import { Rightarrow  } from "../Svg"
import { Link } from 'react-router-dom';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { getAllTags } from "../../redux/actions/Get";
import { addNewProject, getAllProjectList } from "../../redux/actions/Project";
// loader
import Loader from "../common/Loader";
import ProjectGeneral from "./ProjectGeneral";


// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ProjectReport from "./ProjectReportFolder"
import ProjectResource from "./ProjectResourceFolder"
import ProjectMedia from "./ProjectMediaFolder";
import ProjectTaskManagement from "./ProjectTaskManagement";

import ProjectSite from "./ProjectSiteFolder";
import Analysis from "./ProjectAnalysis";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


var actionArr = []

class ProjectEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this user',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,

            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',

            //validation fields  
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',

            showTab: ''





        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags();
        this.props.action.getAllUserList();
        this.setTab()
        this.getProjectApi()


    }

    setTab = () => {
        let arr = this.props.location.pathname.split("/")

        if (arr.indexOf("report") != -1)
            this.setState({ showTab: "Reports" })
        else if (arr.indexOf("general") != -1)
            this.setState({ showTab: "General Info" })
        else if (arr.indexOf("overview") != -1)
            this.setState({ showTab: "Overview" })
        else if (arr.indexOf("task") != -1)
            this.setState({ showTab: "Management" })
        else if (arr.indexOf("media") != -1)
            this.setState({ showTab: "Gallery" })
        else if (arr.indexOf("client") != -1)
            this.setState({ showTab: "Clients" })
        else if (arr.indexOf("resource") != -1)
            this.setState({ showTab: "Resources" })
        else if (arr.indexOf("map") != -1)
            this.setState({ showTab: "Mapview" }) 
        else if (arr.indexOf("sites") != -1)
            this.setState({ showTab: "Site" })
            else if (arr.indexOf("analysis") != -1)
            this.setState({ showTab: "Analytics" })
    }



    getProjectApi = () => {
        try {
            if (this.props.match.params)
            _Api(Url.ADMIN_VIEW_PROJECT.method, Url.ADMIN_VIEW_PROJECT.url, '', this.props.match.params.id   )
                    .then(resp => {
                        const {
                            created,
                            firstName,
                            address,
                            city,
                            desc,
                            folder,
                            lastName,
                            password,
                            phoneNumber,
                            role,
                            street,
                            status,
                            state,
                            tags,
                            user,
                            lat,
                            long,
                            challanges,
                            strengths,
                            _id } = resp.responseData.projectData

                        this.setState({
                            challanges,
                            strengths,
                            latitude: lat,
                            longitude: long,
                            address,
                            city,
                            state,
                            street,
                            description: desc,
                            user, showUserName: resp.responseData.projectData.projectName, name: resp.responseData.projectData.projectName, isLoadingState: false, folderName: folder.folderName, created, firstName, folder, lastName, password, phoneNumber, role, status, tags, _id
                        }, () => this.setState({ isLoadingState: false }))

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }



    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' });
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({ userFirstName: '', userLastName: '', userEmail: '', userPhoneNumber: '', userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' })

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        let { name, description, address, street, city, state, latitude, longitude } = this.state;

        if (name == "") {
            this.setState({ nameError: "*Please enter name." })
        }
        else if (description == "") {
            this.setState({ descriptionError: "*Please enter description." })
        }
        else {

            let req = {
                projectName: name,
                desc: description,
                folder: this.props.match.params.id,
                tag: this.state.tagId,
                project: '',
                user: [this.state.userId],
                street: street,
                city: city,
                state: state,
                lat: latitude,
                long: longitude,
                address: address,
                projectType: 'S'


            }

            let req2 = {
                folder: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
            }
            if (e.target.name == "save") {

                this.props.action.addNewProject(req, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewProject(req, req2)
                this.clearFields();
            }
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this project" : status == 0 ? "Inactive this project" : "delete this project" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }    



    //edit user
    onClickEditUser = (user) => {

        this.props.history.push(`/user-profile/${this.props.match.params.id}/${user._id}`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.dataCount) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            folder: this.props.match.params.id,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }

        this.props.action.getAllProjectList(req);
    }


  


    handleClickTag = (e) => {
        let url = `/projects/edit/${this.props.match.params.folderId}/${this.props.match.params.id}`
        this.setState({ showTab: e })
        if (e == "General Info")
            this.props.history.push(`${url}/general`);
        else if (e == "Overview")
            this.props.history.push("overview");
        else if (e == "Site")
            this.props.history.push(`${url}/sites`);

        else if (e == "Reports")
            this.props.history.push(`${url}/report`);

        else if (e == "Resources")
            this.props.history.push(`${url}/resource`);

        else if (e == "Gallery")
            this.props.history.push(`${url}/media`);

        else if (e == "Clients")
            this.props.history.push(`${url}/client`);

        else if (e == "Mapview")
            this.props.history.push(`${url}/map`);

        else if (e == "Management")
            this.props.history.push(`${url}/task`);
        else if (e == "Analytics")
            this.props.history.push(`${url}/analysis`);
    }
    






    render() {

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)
        let { name, description, address, street, city, state, latitude, longitude, nameError, descriptionError, addressError, streetError, cityError, stateError, latitudeError, longitudeError } = this.state;

        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header
                       />
                   

                    <div className="">
                    <div className="body_header v">
                        <h6 className="headingH6"><Link to="/projects">PROJECTS </Link>
                        <span className="cm_arrow"><Rightarrow/></span>
                        &nbsp;<Link to={{ pathname: `/projects/${this.props.match.params.folderId}`, state: { state: this.state.folderName } }}   >{this.state.folderName}</Link>&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;{this.state.showUserName}</h6>

                    </div>

                        <Tabs activeKey={this.state.showTab} id="uncontrolled-tab-example" className="cm_tab" onSelect={this.handleClickTag}>
                            <Tab eventKey="General Info" title="General Info" >

                            </Tab>
                            <Tab eventKey="Site" title="Sites">
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Reports" title="Reports" >
                                <div>
                                    <div className="">
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Gallery" title="Gallery" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Resources" title="Resources" >
                                <div>


                                </div>
                            </Tab>
                            <Tab eventKey="Management" title="Management" >
                                <div>

                                </div>
                            </Tab>
                            <Tab eventKey="Analytics" title="Analytics" >
                                <div>
                                
                                </div>
                            </Tab>

                        </Tabs>

                    </div>
                    <div>
                        <Switch>
                            <Route exact path={"/projects/edit/:folderId/:id/general"} component={ProjectGeneral} />
                            <Route exact path={`/projects/edit/:folderId/:id/report`} component={ProjectReport} />
                            <Route exact path={`/projects/edit/:folderId/:id/report/data`} component={ProjectReport} />
                            <Route exact path={`/projects/edit/:folderId/:id/report/:eId`} component={ProjectReport} />

                            <Route exact path={`/projects/edit/:folderId/:id/resource`} component={ProjectResource} />
                            <Route exact path={`/projects/edit/:folderId/:id/resource/data`} component={ProjectResource} />
                            <Route exact path={`/projects/edit/:folderId/:id/resource/:eId`} component={ProjectResource} />
                            
                            <Route exact path={`/projects/edit/:folderId/:id/media`} component={ProjectMedia} />
                            <Route exact path={`/projects/edit/:folderId/:id/media/data`} component={ProjectMedia} />

                            <Route exact path={`/projects/edit/:folderId/:id/analysis`} component={Analysis} />

                            <Route exact path={`/projects/edit/:folderId/:id/task`} component={ProjectTaskManagement} />
                            

                            <Route exact path={`/projects/edit/:folderId/:id/sites`} component={ProjectSite} />
                            <Route exact path={`/projects/edit/:folderId/:id/sites/data`} component={ProjectSite} />
                            <Route exact path={`/projects/edit/:folderId/:id/sites/:eId`} component={ProjectSite} />
                        </Switch>


                    </div>



                </div>


            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        projectInfo: state.projects.projectInfo,
        activeProjectDetails: state.projects.activeProject,
        inActiveProjectDetails: state.projects.inactiveProject,
        totalProjectDetails: state.projects.totalProject,
        dataCount: state.projects.dataCount,
        usersInfo: state.users.userInfo,

    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ addNewProject, getAllProjectList, getAllUserList, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEdit);

