/**************************************************************************
 * @about Addfolder file that create and contains folders
 * create new folder
 * Create folder, by type 
 * Type of folders-
 * 1=Projects, 2=sites, 3=Communities, 4=Users,
 * 5=Resources, 6=Reports, 7=Media, 8=Sifter, 9=Map
 * 
 *************************************************************************/

// react lib
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
// react-bootstrap
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';

// my components
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { addNewFolder } from "../../redux/actions/Add";
import { getAllFolders, getAllTags } from "../../redux/actions/Get";
import { resetStore } from "../../redux/actions/Common";

// services
import { _deleteFolder, _editFolder } from "../../config/api/AddServices";

// inline error
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader"
// add tag
import AddTag from "../../components/common/add_tag/AddTag"

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


// folder custome component
class FolderSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addTagPopupOpen: false,
            folderName: '',
            folderNameError: '',
            tagName: '',
            iconImag: '',
            isTagPopup: false,
            stateLoading: false,
            iconImagError: '',
            isEdit: false,
            editFolderId: '',
            showDeleteModal: false,
            isChangeIcon: false,
            deleteFolderId: '',
            showTagName: '',
            showTagId: '',
            showImg: "https://drawbridge-dev.s3.amazonaws.com/folders/icon_1576297686638_folder.png",
            dataShow:false
            
        }
    }

    componentDidMount() {
       
        this.props.setClick(this.openAddTagPopup);
    }

    //handle on handleOnChange
    handleOnChange = (e) => {  
        e.preventDefault()    
        let re =  /^[A-Za-z\s]+$/
        if(re.test(e.target.value) || e.target.value == "")        
        this.setState({ [e.target.name]: e.target.value, folderNameError: '' })
    }

    handleOnChangeTag = (e)=>{    
        this.setState({ [e.target.name]: e.target.value, folderNameError: '' })

    }


    // open popup
    openAddTagPopup = () => {
        let req = {
            folderType: this.props.folderType && this.props.folderType
        }
        this.props.action.getAllFolders(req)
        this.props.action.getAllTags({status:"1"})
        
        this.setState({
            addTagPopupOpen: !this.state.addTagPopupOpen,
            isEdit: false,
            folderName: '',

        })
    }

    // close modal
    closeModal = () => {
        this.setState({
            addTagPopupOpen: false,
            folderNameError: '',
        })
        this.clearFields()
        this.props.closeModalAddTag && 
        this.props.closeModalAddTag(true)
    }

    // redirect user
    redirect = (id, name) => {
        try {
            this.props.history.push(`/${this.props.link && this.props.link}/${id}`, { state: name });
        } catch (err) { }
    }

    // on image upload
    onImageUpload = e => {
        try{
            let file = e.target.files[0]
            this.setState({ iconImag: file, iconImagError: '', showImg: URL.createObjectURL(file), isChangeIcon: true })
        }catch(err){
 
        }
     }

   

    //clear fields
    clearFields = () => {
        try {
            this.setState({isChangeIcon: false, 
                folderName: '', 
                tagName: '', iconImag: '', 
                showImg: "https://drawbridge-dev.s3.amazonaws.com/folders/icon_1576297686638_folder.png",
                showTagName:'',
                isEdit: false,
            })
            this.fileInput.value = ""
        }
        catch (err) {

        }
    }


    // on submit add folder
    handleAddFolder = (e) => {       
        try {
            if (this.state.folderName == '') {
                this.setState({ folderNameError: '*Please enter folder name.' })
            }
            else if (this.state.iconImag != "" && this.state.iconImag.type.split("/")[0] != "image") {

                this.setState({ iconImagError: "*Please add a valid file.", })
            }
            else {
                const data = new FormData();
                data.append('folderName', this.state.folderName);
                data.append('folderType', this.props.folderType && this.props.folderType);
                data.append('tagId', this.state.tagName);
                data.append('icon', this.state.iconImag);               
                
                if (e.target.name == "save") {
                    this.props.action.addNewFolder(data, this.props.folderType && this.props.folderType);
                    this.closeModal()
                    this.clearFields()
                }
                else {
                    this.props.action.addNewFolder(data, this.props.folderType && this.props.folderType);
                    this.clearFields()

                }
            }

        } catch (err) {

        }

    }
    // handle on delete folder
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deleteFolderId: id });
    }

    // on delete folder click
    onDeleteFolder = () => {
        this.setState({ stateLoading: true, showDeleteModal: false })
        try {
            let req = {
                folderId: this.state.deleteFolderId
            }
            _Api(Url.ADMIN_DELETE_FOLDER.method, Url.ADMIN_DELETE_FOLDER.url, req)
                .then(resp => {
                    this.setState({ stateLoading: false })
                    let reqq = {
                        folderType: this.props.folderType && this.props.folderType
                    }
                    this.props.action.getAllFolders(reqq)
                    notify("success", resp.responseData.message)
                }).catch(err => {
                    this.setState({ stateLoading: false })

                })

        }
        catch (err) {
            this.setState({ stateLoading: false })

        }
    }


    // on click edit folder 
    onEditClick = (folder) => {
        try {
            this.setState({ isEdit: true, folderName: folder.folderName, iconImag: folder.icon, showImg: folder.icon, editFolderId: folder._id }, () => this.setState({ addTagPopupOpen: true }))

            if (folder.tagId) {
                this.setState({ showTagName: folder.tagId.tagName, showTagId: folder.tagId._id })

            }
        } catch (err) {

        }

    }

    // on handle edit folder
    handleEditFolder = () => {    
        try {
            if (this.state.folderName == '') {
                this.setState({ folderNameError: '*Please enter folder name.' })
            }
            else if (this.state.isChangeIcon && this.state.iconImag != "" && this.state.iconImag.type.split("/")[0] != "image") {

                this.setState({ iconImagError: "*Please add a valid file.", })
            }
            else {
                this.setState({ addTagPopupOpen: false, stateLoading: true })

                const data = new FormData();
                data.append('folderName', this.state.folderName);
                data.append('folderId', this.state.editFolderId);
                data.append('tagId', this.state.tagName);
                data.append('icon', this.state.iconImag);            

                _Api(Url.ADMIN_EDIT_FOLDER.method, Url.ADMIN_EDIT_FOLDER.url, data)
                    .then(resp => {
                        let req = {
                            folderType: this.props.folderType && this.props.folderType
                        }
                        this.setState({ addTagPopupOpen: false, stateLoading: false, isChangeIcon: false })
                        this.props.action.getAllFolders(req)
                        this.clearFields()
                        setTimeout(() => {
                            notify("success", resp.responseData.message)
                        }, 300)
                    })
                    .catch(err => {
                        this.setState({ addTagPopupOpen: false, stateLoading: false })
                        if (err.error)
                            notify("err", err.error.message)
                        else if (err)
                            notify("err", err.message)
                    })
            }

        } catch (err) {

        }
    }

    // on click add tag
    handleAddTag = () => {
        this.clickChild()
        this.setState({ addTagPopupOpen: false })
    }

    // to re set folder & re set store that pre data not show
    componentWillUnmount() {
        this.props.action.resetStore();
    }

    render() {
        // setTimeout(()=> this.setState({dataShow:true}), 2000)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.stateLoading && <Loader />}
              
                    <AddTag
                        setClick={click => this.clickChild = click}
                        closeModalAddTag={() => this.setState({ addTagPopupOpen: true })}
                    />

                    {
                        this.state.dataShow && this.props.foldersList.length == 0 && this.props.isLoading != true && <p className="cm_center_text"> No folder available </p>
                    }
    
                <Modal show={this.state.addTagPopupOpen} onHide={this.closeModal} className="custom_modal" centered >
                    <Modal.Header closeButton>
                     <h6>{this.state.isEdit ? "Edit Folder" : "New Folder"}</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            
                           
                            <div class="form-group">
                                <label for="email">Name</label><span className="impFields">*</span>
                                <input type="text"
                                    className={`form-control ${this.state.folderNameError != '' ? 'is-invalid' : ''}`}
                                    name="folderName"
                                    placeholder="Enter name"
                                    maxLength={20}
                                    autocomplete="off"
                                    value={this.state.folderName}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.folderNameError}
                                />
                            </div>

                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>

                                    <select
                                        class="form-control"
                                        id="sel1"
                                         name="tagName"
                                         onChange={this.handleOnChangeTag}
                                    >


                                        {this.state.isEdit && this.state.showTagName !="" ? <option key={-2} value={this.state.tagName} >{this.state.showTagName}</option> : <option key={-1} value={"select"} selected hidden  >{"Select tag"}</option>}

                                        {

                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                )
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8" >
                                    <label class="fileContainer" for="email">Icon</label> <br />
                                    <img
                                        src={this.state.showImg}
                                        style={{}}
                                        className="upload_fileimg"
                                    />

                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        aria-hidden="true"
                                        accept="image/png,image/jpeg"
                                        className={`form-control ${this.state.iconImagError != '' ? 'is-invalid' : ''}`}
                                        id="sel1"
                                        ref="fileUploader"
                                        files={this.state.iconImag}
                                        onChange={this.onImageUpload}
                                    />


                                    <InlineError
                                        message={this.state.iconImagError}
                                    />


                                </div>
                                <div className="col-md-4"><button type="button" onClick={() => this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-12">Browse</button></div>

                            </div>
                            <br />
                            <div class="form-group">
                                {
                                    this.state.isEdit ?
                                        <button name="save" type="button" class="btn btn-primary btn-blue" onClick={this.handleEditFolder}>Save</button>
                                        :
                                        <>
                                            <button type="button" class="btn btn-default mR-20" onClick={this.handleAddFolder}>Save & Create New</button>
                                            <button name="save" type="button" class="btn btn-primary btn-blue" onClick={this.handleAddFolder}>Save</button>
                                        </>
                                }
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>


                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want delete this folder ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
                        </button>
                        <button className="btn btn-primary btn-sm btn-blue" onClick={() => this.onDeleteFolder()}>
                            Yes
                        </button>
                    </Modal.Footer>
                </Modal>




            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        foldersList: state.folders.folderList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {

        action: bindActionCreators({ addNewFolder, getAllFolders, getAllTags, resetStore }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderSection);
