

import React, { Component } from 'react';
import { Dropdown, Modal,} from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewReport, getAllReportList } from "../../redux/actions/Report";
import { getAllTags } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
import { resetStore } from "../../redux/actions/Common";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";

import AddTag from "../../components/common/add_tag/AddTag"


var actionArr = []
class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            linkBtnPopupOpen:false,
            chooseExtingPopupOpen:false,
            tagId: '',
            showDeleteModal: false,

            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,

            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError: '',
            fileUploadFileError: '',

            statusState: 'Delete this report',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,
            selectAll: false



        }
    }

    componentDidMount() {

        this.onClickPagenation();
        this.props.action.getAllTags();
        this.props.action.getAllSiteList();
    }


   


    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, reportNameError: '', reportDescriptionError: '' });
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }

    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }
    openLinkBtnPopupOpen = () => {
        this.setState({
            linkBtnPopupOpen: !this.state.linkBtnPopupOpen
        })
    }
    openChooseExtingPopupOpen = () =>{
        this.setState({
            chooseExtingPopupOpen: !this.state.chooseExtingPopupOpen
        })
    }
    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
            linkBtnPopupOpen:false,
            chooseExtingPopupOpen:false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        try {
            this.setState({
                reportName: '',
                reportNameError: '',
                reportDescription: '',
                reportDescriptionError: '',
                reportUrl: '',
                reportFile: '',
                reportProjectId: '',
                reportSiteId: '',
                reportTagId: '',
                deletedId: '',
                reportUrlError: '',

            })
            this.fileInput.value = ""
        }
        catch (err) {

        }

    }

    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
        this.setState({ reportFile: file, fileUploadFileError: '' })
    }

    // on submit form
    handleOnSubmitForm = (e) => {
        if (this.state.reportName == "") {
            this.setState({ reportNameError: "*Please enter report name." })
        }
        else if (this.state.reportDescription == "") {
            this.setState({ reportDescriptionError: "*Please enter description name." })
        }
        else if (!this.state.reportFile) {
            this.setState({ fileUploadFileError: "*Please add a file" })
        }
        else if (this.state.reportFile.type.split("/")[0] != "text" && this.state.reportFile.type.split("/")[0] != "application") {
            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile.type.split("/")[1] == "exe" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage") {
                      this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }


        else {

            let formData = new FormData()
            formData.append("reportName", this.state.reportName);
            formData.append("desc", this.state.reportDescription)
            formData.append("folder", this.props.match.params.id)
            formData.append("url", this.state.reportUrl);
            formData.append("project", this.state.reportProjectId)
            formData.append("tag", this.state.reportTagId)
            formData.append("fileData", this.state.reportFile)
            formData.append("site", this.state.reportSiteId)


            let req2 = {
                folder: this.props.match.params.rId,
                site: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
            }


            if (e.target.name == "save") {

                this.props.action.addNewReport(formData, this.props.match.params.id, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewReport(formData, this.props.match.params.id, req2)
                this.clearFields();
            }
        }
    }

    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this report" : status == 0 ? "Inactive this report" : "delete this report" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        })
    }

    //edit user
    onClickEditUser = (report) => {

        this.props.history.push(`${this.props.match.params.id}/${report._id}/edit`, { state: report });

    }
    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.props.reportData) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })



        let req = {
            // folder: this.props.match.params.rId,
            
            unassigned:1,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }



        this.props.action.getAllReportList(req);
    }


    // on search
    handleOnSearch = (e) => {

        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'reportName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }
    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })
    }


    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }


    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
  


    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these report" : status == 0 ? "Inactive these report" : "delete these report" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.reportList &&
                    this.props.reportList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }


    handleTOAddItems = ()=>{
        this.setState({isLoadingState:true})
        try{
            let req= {
                dataId:actionArr.toString(),
                assignId:this.props.match.params.id,
                assignType:'S',
                dataType:1

            }

            _Api(Url.ADMIN_ADD_ITEMS.method, Url.ADMIN_ADD_ITEMS.url, req )
            .then(rsp=>{
                this.setState({isLoadingState:false})
                actionArr =[]
               this.props.history.goBack();

            })
            .catch(err=>{
                this.setState({isLoadingState:true})
            })
            
        }catch(err){

        }
    }




   // to re set folder & re set store that pre data not show
   componentWillUnmount() {
    this.props.action.resetStore();
}


    render() {
        var pageRange = Math.ceil(this.props.reportData / this.state.dataCount)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}


                <div className="body_header">
                    {/* <h6 className = "headingH6"> <Link to="/reports/folders">Reports</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}
                    <div className="new_btn text-right btn_parent">
                        {/* <button className="btn btn-primary btn-blue btn_child" onClick={this.openInviteNewPopupOpen}>Add New</button> */}
                       <div className="btn_child">                       
                   <button className="btn btn-primary btn-blue " onClick={()=> this.props.history.goBack()}>Back</button>                      &nbsp;&nbsp;

                     
                       </div>
                    </div>
                    
                </div>
                <div className="counter_detail">
                  
                    {
                        actionArr && actionArr.length !=0 &&

                        <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                        {actionArr.length} Select
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="action_menu">
                            <Dropdown.Item onSelect={this.handleTOAddItems}>Add to site</Dropdown.Item>                           
                        </Dropdown.Menu>
                    </Dropdown>



                        // <div scope="col" id="filter_text" className={this.state.FilterStatus ? 'filter_text open' : 'filter_text'} >
                        //     <span className="more_dots" onClick={this.toggleFilterStatus} ><i class="fa fa-ellipsis-h" aria-hidden="true"></i> </span>
                        //     <ul>
                        //         <li onClick={() => this.handleTOAddItems(actionArr, "1")}> Active </li>
                        //         <li onClick={() => this.handleDeleteModal2(actionArr, "0")}>InActive </li>
                        //         <li onClick={() => this.handleDeleteModal2(actionArr, "2")}>Delete</li>

                        //     </ul>

                        // </div>
                    }
                </div>


                <div className="table-responsive">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                <th>Sr No.</th>
                                <th onClick={() => this.handleOnClickSort("report")}>Report Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                <th>Created By </th>
                                <th>Type</th>
                                <th onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                             

                            </tr>
                        </thead>
                        <tbody>

                            {

                                this.props.reportList &&
                                this.props.reportList.map((report, index) => (
                                    <tr key={index}>
                                        <td><input type="checkbox" className="checkbox" name="time" value={report._id} checked={actionArr.indexOf(report._id) != -1} onClick={this.handleChecked} /></td>
                                        <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                        <td  className="txtClassUpper">{`${report.reportName}`}</td>                                       
                                        <td>{"Admin"}</td>
                                        <td>{report.fileType}</td>
                                        <td>{dateFormat(report.created, "mm/dd/yyyy")}</td>                                     
                                       


                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        this.props.reportData == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="textContent" > Sorry no more content </p>
                    }




                    <div className="float-right">
                        {this.props.reportData >= 10 &&
                            <div class="pagination">
                                <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange} </p>
                                <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.reportData / this.state.dataCount) <= this.state.pageNo + 1} ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>
                            </div>
                        }
                    </div>
                </div>


                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />
              <Modal show={this.state.linkBtnPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <div class="text-center">
                        <button type="button" class="btn btn-primary btn-blue" onClick={this.openInviteNewPopupOpen}>Create New</button><br/><br/>
                        <Link onClick={this.openChooseExtingPopupOpen} className="link-btn">Choose Existing Folder</Link>
                    </div>
                    </Modal.Body>
              </Modal>
              <Modal show={this.state.chooseExtingPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body> 
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" className="checkbox" name="time" /></th>
                                    <th>Sr No.</th>
                                   <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                       <tr>
                                            <td><input type="checkbox" className="checkbox" name="time" /></td>
                                            <td>1</td>
                                            <td>Aana</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" className="checkbox" name="time" /></td>
                                            <td>2</td>
                                            <td>Aana</td>
                                        </tr>
                                 
                            </tbody>
                         
                        </table>
                        <div class="text-center">
                                <button type="button" class="btn btn-default mR-20">Save</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue">Export</button>
                       </div>
                    </div>
                    </Modal.Body>
              </Modal>
                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                    <h6>Add New</h6>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleOnSubmitForm}>
                           

                            <div class="form-group">
                                <label for="email">Report Name</label><span className="impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.reportNameError != '' ? 'is-invalid' : ''}`}
                                    id="reportName"
                                    name="reportName"
                                    placeholder="Enter Report name"
                                    maxLength={20}
                                    value={this.state.reportName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.reportNameError}
                                />
                            </div>


                            <div class="form-group">
                                <label for="email">Description</label><span className="impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.reportDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder="Enter description"
                                    maxLength={256}
                                    name="reportDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.reportDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.reportDescriptionError}
                                />
                            </div>

                            {/* 
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">URL</label>
                                    <input
                                        type="url"
                                        className={`form-control ${this.state.reportUrlError != '' ? 'is-invalid' : ''}`}
                                        id="reportUrl"
                                        placeholder="Enter url"
                                        maxLength={20}
                                        name="reportUrl"
                                        autocomplete="off"
                                        required
                                        value={this.state.reportUrl}
                                        onChange={this.handleOnChange} reportDescription

                                    />

                                </div>
                            </div> */}
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    {/* <label className="text-center display-b" >OR</label> */}
                                    <input type="file"
                                        accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        className={`form-control ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                        ref="fileUploader"
                                        onChange={this.onImageUpload}

                                    />
                                    <InlineError
                                        message={this.state.fileUploadFileError}
                                    />
                                </div>
                                <div className="col-md-4"><button type="button" onClick={() => this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-12">Browse</button></div>

                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Project</label>
                                    <select class="form-control" id="sel1" name="reportProjectId" onChange={this.handleOnChange}>
                                        {/* {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        } */}
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12" >Add Project</button></div>

                            </div>

                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Sites</label>
                                    <select class="form-control" id="sel1" name="reportSiteId" onChange={this.handleOnChange}>
                                    <option key={-1} value={"select"} disabled selected hidden  >{"Select site"}</option>
                                        {
                                            this.props.siteInfo && this.props.siteInfo.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.siteName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12" >Add Sites</button></div>

                            </div>


                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Tag</label>
                                    <select class="form-control" id="sel1" name="reportTagId" onChange={this.handleOnChange}>
                                        <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                            </div>
                            <br />
                            <div class="form-group">
                                <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>

               

            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        reportList: state.reports.reportInfo,
        reportActive: state.reports.activeTag,
        reportInactive: state.reports.inactiveTag,
        reportTotal: state.reports.totalTag,
        reportData: state.reports.dataCount

    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore,  getAllUserList, getAllTags, addNewUser, addNewReport, getAllReportList, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);

