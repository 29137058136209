import _ from 'lodash';

import { DevSetting, ProdSetting, StagSetting, localhostSetting } from './settings'

let defaultSetting = {
  api: {
    url: 'https://devapi.drawbridgesolution.com/drawbridge/api/v1/',
    mode: 'cors',
  },
  socket: ''
}

let siteSetting = defaultSetting;

console.log("Env : ", process.env.REACT_APP_ENV);

switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, ProdSetting);
    break;
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, StagSetting);
    break;
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, localhostSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, DevSetting);
}

export default siteSetting;

