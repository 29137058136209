import {
    GET_LIST_ALL_COMMUNITY_SUCCESS,
    GET_LIST_ALL_COMMUNITY_FAILURE
} from "../actions/Types";

import redexState from "./InitalState";





// get All list users
export const community = (state = redexState.communityInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_COMMUNITY_SUCCESS:
            return {
                ...state,
                ...action.payload.comInfo
            }
        case GET_LIST_ALL_COMMUNITY_FAILURE:
            return {
                ...state,
            }
        default:
            return state
    }

}