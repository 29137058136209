export default {

    'ADMIN_LOGIN_URL': `admin/login`,
    'ADMIN_FORGOT_PASSWORD': `admin/forgotPassword`,

    // client & users
    'ADMIN_ADD_USER': { url: `admin/addUser`, method: 'POST' },
    'ADMIN_GET_USERS': { url: `admin/getUsers`, method: 'POST' },
    'ADMIN_VIEW_USER': { url: `admin/viewUser?userId=`, method: 'GET' },
    'ADMIN_EDIT_USER': { url: `admin/editUser`, method: 'POST' },
    'ADMIN_ACTION_FOR_USER': { url: `admin/changeUserStatus`, method: 'POST' },
    'ADMIN_CHECK_EMAIL': { url: `admin/checkEmail?email=`, method: 'GET' },
    'ADMIN_GET_PROFILE_TYPE': { url: `profile/getAllLevelProfile?profileType=`, method: 'GET' },

    // dashboard
    'ADMIN_GET_DASHBOARD': { url: `admin/dashboard`, method: "GET" },

    'ADMIN_ADD_TAG': { url: `tag/addTag`, method: "POST" },
    'ADMIN_GET_TAG': { url: `tag/getTags`, method: "POST" },

    'ADMIN_ADD_FOLDER': { url: `folder/addFolder`, method: 'POST' },
    'ADMIN_GET_FOLDER': { url: `folder/getFolders`, method: 'POST' },

    'ADMIN_ADD_MEDIA': { url: `media/addMedia`, method: 'POST' },
    'ADMIN_GET_MEDIA': { url: `media/getMedia`, method: 'POST' },
    'ADMIN_DELETE_MEDIA': { url: `media/changeMediaStatus`, method: 'POST' },

    // project
    'ADMIN_ADD_PROJECT': { url: `project/addProject`, method: 'POST' },
    'ADMIN_GET_PROJECT': { url: `project/getProject`, method: 'POST' },
    'ADMIN_EDIT_PROJECT': { url: `project/editProject`, method: 'POST' },
    'ADMIN_VIEW_PROJECT': { url: `project/viewProject?projectId=`, method: 'GET' },
    'ADMIN_CHANGE_STATUS_PROJECT': { url: `project/changeProjectStatus`, method: 'POST' },

    // reports
    'ADMIN_ADD_REPORT': { url: `report/addReport`, method: 'POST' },
    'ADMIN_GET_REPORTS': { url: `report/getReports`, method: 'POST' },
    'ADMIN_CHANGE_STATUS_REPORT': { url: `report/changeReportStatus`, method: 'POST' },
    'ADMIN_VIEW_REPORT': { url: `report/viewReport?reportId=`, method: 'GET' },
    'ADMIN_EDIT_REPORT': { url: `report/editReport`, method: 'POST' },

    //resources
    'ADMIN_ADD_RESOURCE': { url: `resource/addResource`, method: 'POST' },
    'ADMIN_GET_RESOURCE': { url: `resource/getResource`, method: 'POST' },
    'ADMIN_VIEW_RESOURCE': { url: `resource/viewResource?resourceId=`, method: 'GET' },
    'ADMIN_CHANGE_STATUS_RESOURCE': { url: `resource/changeResourceStatus`, method: 'POST' },
    'ADMIN_EDIT_RESOURCE': { url: `resource/editResource`, method: 'POST' },

    //site
    'ADMIN_ADD_SITE': { url: `site/addSite`, method: 'POST' },
    'ADMIN_GET_SITE': { url: `site/getSite`, method: 'POST' },
    'ADMIN_VIEW_SITE': { url: `site/viewSite?siteId=`, method: 'GET' },
    'ADMIN_CHANGE_STATUS_SITE': { url: `site/changeSiteStatus`, method: 'POST' },
    'ADMIN_EDIT_SITE': { url: `site/editSite`, method: 'POST' },

//     'ADMIN_ADD_SITE': `${url}site/addSite`,
// 'ADMIN_GET_SITE': `${url}site/getSite`,
// 'ADMIN_VIEW_SITE': `${url}site/viewSite`,
// 'ADMIN_CHANGE_STATUS_SITE': `${url}site/changeSiteStatus`,
// 'ADMIN_EDIT_SITE': `${url}site/editSite`,


    // tag
    'ADMIN_ADD_TAG': { url: `tag/addTag`, method: 'POST' },
    'ADMIN_GET_TAG': { url: `tag/getTags`, method: 'POST' },
    'ADMIN_EDIT_TAG': { url: `tag/editTag`, method: 'POST' },
    'ADMIN_CHANGE_TAG_STATUS': { url: `tag/changeTagStatus`, method: 'POST' },

    // folder
    'ADMIN_DELETE_FOLDER': { url: `folder/deleteFolder`, method: 'POST' },
    'ADMIN_EDIT_FOLDER': { url: `folder/editFolder`, method: 'POST' },

    //media
    'ADMIN_DELETE_MEDIA': {url:`media/changeMediaStatus`, method:'POST'},


    // extra
    'ADMIN_ADD_ITEMS': {url:`admin/assignData`, method:'POST'},


}