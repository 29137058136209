
/**
 * @about user services
 * get user
 * add user
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="


// add users
export const _addUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_USER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add tags by admin
export const _getUsersList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_USERS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}




// add tags by admin
export const _getUserWithSiteId = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_USERS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add tags by admin
export const _deleteUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_USERS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// action for user
export const _actionForUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ACTION_FOR_USER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

// action for user
export const _viewUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_VIEW_USER}?userId=${request.userId}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err)) 
    })

}


// action for user
export const _ViewPrticularUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_GET_ONLY_UNSELECTED_USER}?clientId=${request.clientId}&projectId=${request.projectId}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// action for user
export const _getProfileType = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_GET_PROFILE_TYPE}?profileType=${request.profileType}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// action for user
export const _checkEmailAvaliblity = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: `${Url.ADMIN_CHECK_EMAIL}?email=${request.email}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}






// action for user
export const _editUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_EDIT_USER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}













