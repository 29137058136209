import {
    IS_LOADING_START,
    IS_LOADING_STOP,

    GET_LIST_MAP_SUCCESS,
    GET_LIST_MAP_FAILURE,

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";


// api services
import {_addMap, _getMapList } from "../../config/api/MapServices";


// action for getUser list
export const getAllMapList = req => dispatch => {
    dispatch({ type: IS_LOADING_START })
    _getMapList(req)
        .then(resp => {
 

            let payload = {
                mapInfo: resp.responseData
            }
            dispatch({ type: GET_LIST_MAP_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {

            dispatch({ type: IS_LOADING_STOP })

        })

}




// add users
export const addNewMap = (req, req2) => dispatch => {
   
    dispatch({ type: IS_LOADING_START })
    _addMap(req)
        .then(resp => {
            getAllMapList(req2)(dispatch)
            dispatch({ type: IS_LOADING_STOP })
           setTimeout(()=>{ notify("success", resp.responseData.message)},300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}