import siteSetting from './index';

const url = siteSetting.api.url;

export default {
        





    'ADMIN_LOGIN_URL': `${url}admin/login`,
'ADMIN_ADD_USER': `${url}admin/addUser`,
'ADMIN_VIEW_USER': `${url}admin/viewUser`,
'ADMIN_EDIT_USER': `${url}admin/editUser`,
'ADMIN_GET_USERS': `${url}admin/getUsers`,
'ADMIN_ACTION_FOR_USER': `${url}admin/changeUserStatus`,
'ADMIN_CHECK_EMAIL': `${url}admin/checkEmail`,
'ADMIN_CHECK_TOKEN': `${url}admin/verifyLink`,
'ADMIN_ADD_FOLDER': `${url}folder/addFolder`,
'ADMIN_GET_FOLDER': `${url}folder/getFolders`,
'ADMIN_EDIT_FOLDER': `${url}folder/editFolder`,
'ADMIN_DELETE_FOLDER': `${url}folder/deleteFolder`,
'ADMIN_ADD_TAG': `${url}tag/addTag`,
'ADMIN_GET_TAG': `${url}tag/getTags`,
'ADMIN_EDIT_TAG': `${url}tag/editTag`,
'ADMIN_CHANGE_TAG_STATUS': `${url}tag/changeTagStatus`,
'ADMIN_GET_SITE_FOLDER': `${url}admin/viewParticularSiteFolder`,
'ADMIN_GET_PROJECT_FOLDER': `${url}admin/viewParticularProjectFolder`,
'ADMIN_CHANGE_STATUS_REPORT': `${url}report/changeReportStatus`,
'ADMIN_ADD_REPORT': `${url}report/addReport`,
'ADMIN_GET_REPORTS': `${url}report/getReports`,
'ADMIN_VIEW_REPORT': `${url}report/viewReport`,
'ADMIN_EDIT_REPORT': `${url}report/editReport`,
'ADMIN_DELETE_MEDIA': `${url}media/changeMediaStatus`,
'ADMIN_ADD_MEDIA': `${url}media/addMedia`,
'ADMIN_GET_MEDIA': `${url}media/getMedia`,
'ADMIN_ADD_RESOURCE': `${url}resource/addResource`,
'ADMIN_GET_RESOURCE': `${url}resource/getResource`,
'ADMIN_VIEW_RESOURCE': `${url}resource/viewResource`,
'ADMIN_CHANGE_STATUS_RESOURCE': `${url}resource/changeResourceStatus`,
'ADMIN_EDIT_RESOURCE': `${url}resource/editResource`,
'ADMIN_ADD_SITE': `${url}site/addSite`,
'ADMIN_GET_SITE': `${url}site/getSite`,
'ADMIN_VIEW_SITE': `${url}site/viewSite`,
'ADMIN_CHANGE_STATUS_SITE': `${url}site/changeSiteStatus`,
'ADMIN_EDIT_SITE': `${url}site/editSite`,
'ADMIN_ADD_PROJECT': `${url}project/addProject`,
'ADMIN_GET_PROJECT': `${url}project/getProject`,
'ADMIN_VIEW_PROJECT': `${url}project/viewProject`,
'ADMIN_CHANGE_STATUS_PROJECT': `${url}project/changeProjectStatus`,
'ADMIN_EDIT_PROJECT': `${url}project/editProject`,
'ADMIN_VIEW_PARTICULAR_SITE_USER': `${url}site/viewParticularSiteUsers`,
'ADMIN_ADD_ITEMS': `${url}admin/assignData`,
'ADMIN_GET_DASHBOARD': `${url}admin/dashboard`,
'ADMIN_GET_FILTER_TAG': `${url}admin/tagsByData`,
'ADMIN_RESET_PASSWORD': `${url}admin/resetPassword`,
'ADMIN_CHANGE_PASSWORD': `${url}admin/changePassword`,
'ADMIN_FORGOT_PASSWORD': `${url}admin/forgotPassword`,
'ADMIN_GET_ONLY_UNSELECTED_USER': `${url}admin/getClientSiteUsers`,
'ADMIN_GET_PROFILE_TYPE': `${url}profile/getAllLevelProfile`,




    


    
  
    // 'ADMIN_LOGIN_URL': `${url}admin/login`,
    // 'ADMIN_FORGOT_PASSWORD': `${url}admin/forgotPassword`,
    // 'ADMIN_ADD_USER':`${url}admin/addUser`,
    // 'ADMIN_ADD_FOLDER':`${url}admin/addFolder`,
    // 'ADMIN_GET_FOLDER':`${url}admin/getFolders`,
    // 'ADMIN_EDIT_FOLDER':`${url}admin/editFolder`,
    // 'ADMIN_ADD_TAG':`${url}admin/addTag`,
    // 'ADMIN_GET_TAG':`${url}admin/getTags`,
    // 'ADMIN_EDIT_TAG':`${url}admin/editTag`,
    // 'ADMIN_GET_USERS':`${url}admin/getUsers`,
    // 'ADMIN_ACTION_FOR_USER':`${url}admin/changeUserStatus`,
    // 'ADMIN_VIEW_USER':`${url}admin/viewUser`,
    // 'ADMIN_EDIT_USER':`${url}admin/editUser`,
    // 'ADMIN_ADD_COMMUNITY':`${url}admin/addCommunity`, 
    // 'ADMIN_GET_COMMUNITY':`${url}admin/getCommunities`,
    // 'ADMIN_EDIT_COMMUNITY':`${url}admin/editCommunity`, 
    // 'ADMIN_CHECK_EMAIL':`${url}admin/checkEmail`, 
    // 'ADMIN_CHECK_TOKEN': `${url}admin/verifyLink`,
    // 'ADMIN_DELETE_FOLDER': `${url}admin/deleteFolder`, 
    // 'ADMIN_RESET_PASSWORD': `${url}admin/resetPassword`,
    // 'ADMIN_CHANGE_TAG_STATUS': `${url}admin/changeTagStatus`, 
    // 'ADMIN_ADD_REPORT': `${url}admin/addReport`,
    // 'ADMIN_GET_REPORTS': `${url}admin/getReports`, 
    // 'ADMIN_GET_SITE_FOLDER': `${url}admin/viewParticularSiteFolder`, 
    // 'ADMIN_GET_PROJECT_FOLDER': `${url}admin/viewParticularProjectFolder`, 
    // 'ADMIN_VIEW_REPORT': `${url}admin/viewReport`,
    // 'ADMIN_CHANGE_STATUS_REPORT': `${url}admin/changeReportStatus`,
    // 'ADMIN_VIEW_REPORT': `${url}admin/viewReport`,    
    // 'ADMIN_EDIT_REPORT': `${url}admin/editReport`,   
    // 'ADMIN_DELETE_MEDIA': `${url}admin/changeMediaStatus`,
  
    // 'ADMIN_ADD_MEDIA': `${url}admin/addMedia`,
    // 'ADMIN_GET_MEDIA': `${url}admin/getMedia`,   
    // 'ADMIN_DELETE_MEDIA': `${url}admin/changeMediaStatus`,
    // 'ADMIN_ADD_RESOURCE': `${url}admin/addResource`,
    // 'ADMIN_GET_RESOURCE': `${url}admin/getResource`, 
    // 'ADMIN_VIEW_RESOURCE': `${url}admin/viewResource`,  
    // 'ADMIN_CHANGE_STATUS_RESOURCE': `${url}admin/changeResourceStatus`,
    // 'ADMIN_EDIT_RESOURCE': `${url}admin/editResource`,
    // 'ADMIN_ADD_MAP': `${url}admin/addMap`,
    // 'ADMIN_GET_MAP': `${url}admin/getMap`, 
    // 'ADMIN_VIEW_MAP': `${url}admin/viewMap`,  
    // 'ADMIN_CHANGE_STATUS_MAP': `${url}admin/changeMapStatus`,
    // 'ADMIN_EDIT_MAP': `${url}admin/editMap`,
    // 'ADMIN_ADD_SITE': `${url}admin/addSite`,
    // 'ADMIN_GET_SITE': `${url}admin/getSite`, 
    // 'ADMIN_VIEW_SITE': `${url}admin/viewSite`,  
    // 'ADMIN_CHANGE_STATUS_SITE': `${url}admin/changeSiteStatus`,
    // 'ADMIN_EDIT_SITE': `${url}admin/editSite`,
    // 'ADMIN_ADD_PROJECT': `${url}admin/addProject`,
    // 'ADMIN_GET_PROJECT': `${url}admin/getProject`, 
    // 'ADMIN_VIEW_PROJECT': `${url}project/viewProject`,  
    // 'ADMIN_CHANGE_STATUS_PROJECT': `${url}admin/changeProjectStatus`,
    // 'ADMIN_EDIT_PROJECT': `${url}admin/editProject`,
    // 'ADMIN_ADD_TASK': `${url}admin/editSite`,
    // 'ADMIN_GET_TASK': `${url}admin/getTasks`, 
    // 'ADMIN_VIEW_TASK': `${url}admin/viewTask`,  
    // 'ADMIN_CHANGE_STATUS_TASK': `${url}admin/changeTaskStatus`,
    // 'ADMIN_EDIT_TASK': `${url}admin/editTask`,   
    // 'ADMIN_VIEW_PARTICULAR_SITE_USER':`${url}admin/viewParticularSiteUsers`,
    // 'ADMIN_ADD_ITEMS':`${url}admin/assignData`,
    // 'ADMIN_GET_FILTER_TAG':`${url}admin/tagsByData`, 
    // 'ADMIN_CHANGE_PASSWORD':`${url}admin/changePassword`, 
    // 'ADMIN_GET_DASHBOARD':`${url}admin/dashboard`,
    // 'ADMIN_GET_ONLY_UNSELECTED_USER':`${url}admin/getClientSiteUsers`,
    // 'ADMIN_GET_PROFILE_TYPE': `${url}profile/getAllLevelProfile`,      

    
  

}
