
/**
 * @about Dashboard
 *  
 */

// react lib 
import React, { Component } from 'react';
// svg images
import { Cummunity, Site, Project } from '../Svg';
// char view
import { Line } from 'react-chartjs-2';
// api services
import { _getDashboardInfo } from "../../config/api/GetServices";
// loader
import Loader from "../common/Loader";
//custome cumponents
import Header from '../layout/Header';
import SideNav from "../layout/SideNav";

// url
import Url from "../../config/env/BaseUrl";
import {_Api} from "../../config/api/Api";





const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};


class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCount: '',
            communityCount: '',
            projectCount: '',
            siteCount: '',
            stateLoader: true
        };
    }

    componentDidMount() {
        
        _Api(Url.ADMIN_GET_DASHBOARD.method,Url.ADMIN_GET_DASHBOARD.url,)
            .then(resp => {
                const {
                    userCount,
                    communityCount,
                    projectCount,
                    siteCount,
                } = resp.responseData.dashboard
                this.setState({
                    userCount,
                    communityCount,
                    projectCount,
                    siteCount,
                    stateLoader: false
                })
            })
            .catch(err => {})
    }
    

    render() {
        return (
            <>
                <SideNav {...this.props} />
                <div className="main-body">
                    {this.state.stateLoader && <Loader />}
                    <Header {...this.props} />
                    <br />
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 offset-xl-1">
                            <div className="dynamic_record">
                                <div>
                                    <div className="icon user_bg">
                                        <Cummunity />
                                    </div>
                                </div>
                                <div className="text_left">
                                    <h4>{this.state.userCount}</h4>
                                    <p>Client</p>
                                </div>
                                <div className="text_right">
                                    <h5 className="site_text">+10%</h5>
                                    <p>vs last week</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 offset-xl-1">
                            <div className="dynamic_record">
                                <div>
                                    <div className="icon cummunity_bg">
                                        <Cummunity />
                                    </div>
                                </div>
                                <div className="text_left">
                                    <h4>{this.state.communityCount}</h4>
                                    <p>User</p>
                                </div>
                                <div className="text_right">
                                    <h5 className="cummunity_text">+10%</h5>
                                    <p>vs last week</p>
                                </div>
                            </div>
                        </div>                        

                    </div>
                    <div className="row">
                    <div className="col-xl-4 col-lg-6 offset-xl-1">
                            <div className="dynamic_record">
                                <div>
                                    <div className="icon project_bg">
                                        <Project />
                                    </div>
                                </div>
                                <div className="text_left">
                                    <h4>{this.state.projectCount}</h4>
                                    <p>Project</p>
                                </div>
                                <div className="text_right">
                                    <h5>+10%</h5>
                                    <p>vs last week</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 offset-xl-1">
                            <div className="dynamic_record">
                                <div>
                                    <div className="icon site_bg">
                                        <Site />
                                    </div>
                                </div>
                                <div className="text_left">
                                    <h4>{this.state.siteCount}</h4>
                                    <p>Site</p>
                                </div>
                                <div className="text_right">
                                    <h5 className="user_text">+10%</h5>
                                    <p>vs last week</p>
                                </div>

                            </div>
                        </div>


                      </div>
                   
                </div>
            </>
        );
    }
}

export default DashBoard;
