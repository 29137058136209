
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';


import ReportListSite from "./SiteReport"
import SiteReportEdit from "./SiteReportEdit";
import DataReport from "./SiteImportReport";


class ReportFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Switch>
                <Route exact path={"/sites/edit/:folderId/:id/report"} component={ReportListSite} />
                <Route exact path={"/sites/edit/:folderId/:id/report/data"} component={DataReport} />              
                <Route exact path={"/sites/edit/:folderId/:id/report/:eId"} component={SiteReportEdit} />
            </Switch>
        );

    }
}

export default ReportFolder;

