
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';


import ProjectReportList from "./ProjectReportList"
import projectReportEdit from "./ProjectReportEdit"
import ReportData from "./ProjectReportImport"


class ReportFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() {
        return (
            <Switch>
                <Route exact path={`/projects/edit/:folderId/:id/report`} component={ProjectReportList} />
                <Route exact path={`/projects/edit/:folderId/:id/report/data`} component={ReportData} />
                <Route exact path={`/projects/edit/:folderId/:id/report/:eId`} component={projectReportEdit} />
                {/* <Route exact path={`/projects/edit/:folderId/:id/report/:rId/:eId/edit`} component={SiteReportEdit} /> */}
            </Switch>
        );

    }
}

export default ReportFolder;

