import {
    IS_LOADING_START,
    IS_LOADING_STOP,

    GET_LIST_ALL_USER_SUCCESS,
    GET_LIST_ALL_USER_FAILURE,
    GET_PROFILE_TYPE_ARRAY_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";
import {getTagForFIlters} from "./Tag"


// api services
import { _getUsersList, _addUser,_getUserWithSiteId, _getProfileType } from "../../config/api/UserService";
import { getDataAndFilter } from "../../utils/MyFilter"



// url
import Url from "../../config/env/BaseUrl";
import {_Api} from "../../config/api/Api";


// action for getUser list
export const getAllUserList = req => dispatch => {
   try{
    getTagForFIlters({tagType:req.tagType, folder:req.folder})(dispatch)
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_GET_USERS.method, Url.ADMIN_GET_USERS.url,req)
        .then(resp => {
            let payload = {
                userInfo: resp.responseData.usersInfo.userInfo
            }
            dispatch({ type: GET_LIST_ALL_USER_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })

        })
   }
   catch(err){
       
   }

}


// action for getUser list
export const getUserWithSite = req => dispatch => {
    dispatch({ type: IS_LOADING_START })   
    _getUserWithSiteId(req)
        .then(resp => {

            let payload = {
                userInfo: resp.responseData.usersInfo.userInfo
            }
            dispatch({ type: GET_LIST_ALL_USER_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {

            dispatch({ type: IS_LOADING_STOP })

        })

}




// add users
export const addNewUser = (req, req2) => dispatch => {
   
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_USER.method, Url.ADMIN_ADD_USER.url,req)
        .then(resp => {
            getAllUserList(req2)(dispatch)            
            dispatch({ type: IS_LOADING_STOP })
           setTimeout(()=>{ notify("success", resp.responseData.message)},300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}

const getInfo = async (req)=>{
    try{    
        let resp = await _getProfileType(req)
        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({
    
                    value: `${data._id}`,
                    label: data.levelName,
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({
    
                        value: `${data2._id} `,
                        label: data2.levelName,
                    }))
                }))    
            }
        ))

        setTimeout(() => {
            fnArr.forEach((item, index) => {
    
                if (item.children.length == 0) {
                    delete fnArr[index].children
                }
    
                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                    })
            })

        //    setTimeout(()=>{ return 100000}, 300)   
        return 10
    
        }, 2000)

        return fnArr

    }catch(err){}    

}

// add users
export const getProfileData =  (req, req2) =>  dispatch => {   
  try{
    getInfo(req).then(resp=> dispatch({ type: GET_PROFILE_TYPE_ARRAY_SUCCESS, payload:resp })
    )    
   
  }catch(err){}

}


