
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ProjectMedia from "./ProjectMediaList"
import MediaData from "./ProjectMediaImport";

class ResourceList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

   
    render() {
        return (
            <Switch>
                <Route  exact path={`/projects/edit/:folderId/:id/media`} component={ProjectMedia} />
               <Route  exact path={`/projects/edit/:folderId/:id/media/data`} component={MediaData} />
            </Switch>
        );

    }
}

export default ResourceList;

