/**
 * @about folder view file that create and contains folders, users
 * create new folder
 * 
 */

// react lib
import React, { Component } from 'react';
import { Select } from 'antd';
import InlineError from "../../components/common/InlineError";
import { validateEmail } from '../../utils/Validation'
import { Spinner } from 'react-bootstrap';


import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';

//action
import { getAllTags } from "../../redux/actions/Get";
import { notify } from '../common/Toaster';
import { resetStore } from "../../redux/actions/Common";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// loader
import Loader from "../common/Loader";
import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from "../../utils/MyFilter"


// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




const { Option } = Select;
class UsersCatogeries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientName: '',
            primaryContactName: '',
            email: '',
            primaryContactNumber: '',
            tags: '',
            clientNameError: '',
            primaryContactNameError: '',
            emailError: '',
            primaryConatctNumberError: '',
            showTagNamee: '',
            tagName: '',
            eId: '',
            _id: '',
            tagId: '',
            userId: '',
            roleType: "",
            role: 1,
            status: 1,
            stateLoader: true,
            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',
            isEditable: false,
            isSpinner:false,
            toggleState:false


        }
    }



    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, clientNameError: '', emailError: '', primaryContactNameError: '', primaryConatctNumberError: '' })
    }
    componentDidMount() {
        // this.props.action.getAllUserList();
        this.props.action.getAllTags({ status: "1" })
        this.getUserDetails()
        // this.getDataProfileType()

    }



   getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 1)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)


        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                            }))
                        }))
                    }))
                }))


            }
        ))



        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }


    // getUser details
    getUserDetails = () => {
        try {
            if (this.props.match.params)

                _Api(Url.ADMIN_VIEW_USER.method, Url.ADMIN_VIEW_USER.url, "", this.props.match.params.eId)
                    .then(resp => {
                        const {
                            phoneNumber,
                            contactName,
                            clientName,
                            email,
                            tagName,
                            eId,
                            profile,

                            tags,
                            role,
                            status
                        } = resp.responseData.userData
                        this.setState({
                            role,
                            // checked: profile,
                            checked: profile == undefined || profile.length == 0 ? [] : profile,
                            nodeData:profile == undefined || profile.length == 0 ? [] : profile,
                            tagId: tags,
                            clientName: clientName,
                            stateLoader: false,
                            primaryContactName: contactName,
                            email: email,
                            primaryContactNumber: phoneNumber,
                            showTagNamee: tagName,
                            userId: eId,
                            isSpinner:false
                        })

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }
    handleOnSubmitForm = () => {
        if (this.state.clientName == "") {
            this.setState({ clientNameError: "*Please enter client name" })
        }
        else if (this.state.primaryContactName == "") {
            this.setState({ primaryContactNameError: '*Pleas enter primary contact name' })
        }
        else if (this.state.email == "") {
            this.setState({ emailError: '*Please enter primary contact email' })
        }
        else if (!validateEmail(this.state.email).status) {
            this.setState({ emailError: "*Please enter valid primary contact email." })
        }
        else if (this.state.primaryContactNumber == "") {
            this.setState({ primaryConatctNumberError: '*Please enter primary contact number' })
        }
        // else if (!validateMobileNo1(this.state.primaryConatctNumber).status) {
        //     this.setState({ primaryConatctNumberError: "*Please enter valid primary conatct number" })
        // }
        this.onEditUser()
    }


    // on edit submit user
    onEditUser = () => {
        this.setState({ stateLoader: true, })

        try {
            let req = {
                userId: this.props.match.params.eId,
                clientName: this.state.clientName,
                contactName: this.state.primaryContactName,
                email: this.state.email,
                phoneNumber: this.state.primaryContactNumber,
                profile: this.state.checked,
                tags: this.state.tagId,
                role: this.state.role

            }
            _Api(Url.ADMIN_EDIT_USER.method, Url.ADMIN_EDIT_USER.url, req)
                .then(resp => {
                    notify("success", "Client edit successfully")
                    // this.getUserDetails()
                    this.setState({ stateLoader: false, isEditable: false })
                    // this.props.history.push(`/clients/${this.props.match.params.folderId}`, { state: this.state.folderName });


                }).catch(err => {
                    this.setState({ stateLoader: false, })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })


        } catch (err) {

        }
    }

    // to re set folder & re set store that pre data not show
    componentWillUnmount() {
        this.props.action.resetStore();
    }

    handleSecleted = (e) => {
        this.setState({ checked: e })
    }

    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : item2.isChecked ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : item3.isChecked ? item3.isChecked:!item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : item4.isChecked ? item4.isChecked:!item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : item5.isChecked ? item5.isChecked:!item5.isChecked
                                        
                                    
                                    } )                                    
                                }                               
                            
                            } )                            
                        }    
                    
                    } )                    
                }            
            } )            
        }

        this.setState({toggleState:!this.state.toggleState})       
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        
        this.setState({toggleState:!this.state.toggleState})
    }

    render() {
        return (
            <>


                <div className="col-sm-12">
                    {
                        !this.state.isEditable
                        &&
                        <a onClick={() => this.setState({ isEditable: !this.state.isEditable })} >
                            <i className="fa fa-pencil float-right edit_pencil" aria-hidden="true" ></i>
                        </a>
                    }
                </div>
                {this.state.stateLoader && <Loader />}
                <div className="row">

                    <div className="col-sm-5 ">
                        <form className="view_mode">
                            <div className="form-group">
                                <label htmlFor="email">Client Name</label>{this.state.isEditable && <span className="impFields">*</span>}
                                {this.state.isEditable && <>
                                    <input type="text"
                                        className={`form-control ${this.state.clientNameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        maxLength={30}
                                        name='clientName'
                                        value={this.state.clientName}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.clientNameError}
                                    />
                                </>
                                }
                                {/*----for view-----*/}
                                <p>{!this.state.isEditable && this.state.clientName} </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"> Contact Name</label>{this.state.isEditable && <span className="impFields">*</span>}
                                {this.state.isEditable &&
                                    <>
                                        <input type="text"
                                            className={`form-control ${this.state.primaryContactNameError != '' ? 'is-invalid' : ''}`}
                                            id="Name"
                                            placeholder="Primary contact name"
                                            maxLength={30}
                                            name='primaryContactName'
                                            value={this.state.primaryContactName}
                                            onChange={this.handleOnChange}
                                        />
                                        <InlineError
                                            message={this.state.primaryContactNameError}
                                        />
                                    </>
                                }
                                <p>{!this.state.isEditable && this.state.primaryContactName} </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"> Contact Email </label>{this.state.isEditable && <span className="impFields">*</span>}
                                {
                                    this.state.isEditable && <>
                                        <input type="text"
                                            className={`form-control ${this.state.emailError != '' ? 'is-invalid' : ''}`}
                                            id="Name"
                                            placeholder="primary contact email"
                                            maxLength={30}
                                            disabled
                                            name='email'
                                            value={this.state.email}

                                        />
                                        <InlineError
                                            message={this.state.emailError}
                                        />
                                    </>
                                }
                                <p>{!this.state.isEditable && this.state.email} </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Contact Number</label>{this.state.isEditable && <span className="impFields">*</span>}
                                {
                                    this.state.isEditable &&
                                    <>
                                        <input type="text"
                                            className={`form-control ${this.state.primaryConatctNumberError != '' ? 'is-invalid' : ''}`}
                                            id="Name"
                                            placeholder="Primary ContacNumber"
                                            maxLength={30}
                                            name='primaryContactNumber'
                                            value={this.state.primaryContactNumber}
                                            onChange={this.handleOnChange}
                                        />
                                        <InlineError
                                            message={this.state.primaryConatctNumberError}
                                        />
                                    </>
                                }
                                <p>{!this.state.isEditable && this.state.primaryContactNumber} </p>
                            </div>

                            <div className="form-group ">
                                <label >Tag</label>
                                {
                                    this.state.isEditable &&
                                    <select className="form-control" id="sel1" name="tagId" onChange={this.handleOnChange}  >
                                        {this.state.showTagNamee ? <option key={-2} selected hidden value={this.state.reportTagId} >{this.state.showTagNamee}</option> : <option key={-1} value={"select"} selected hidden  >{"Select tag"}</option>}
                                        {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.tagName}</option>
                                                )
                                            )
                                        }
                                    </select>
                                }
                               <p>{!this.state.isEditable && this.state.showTagNamee} </p>
                            </div>
                          


                        </form>
                    </div>


                    <div className="col-sm-5 offset-sm-1">
                        <h6>Client Profile</h6>
                        {
                            this.state.isSpinner  &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                        <Accordion defaultActiveKey="00000000">
                            {
                                this.state.nodeData.map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" value = {item3.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>


                    </div>

                </div>
                {
                    this.state.isEditable &&
                    <>
                        <div className="form-group float-right">
                            <button type="button" className="btn btn-pink mR-20" onClick={() => this.setState({ isEditable: false })} >Cancel</button>
                            <button type="button" className="btn btn-primary btn-blue" onClick={this.handleOnSubmitForm}>Save</button>
                        </div>

                    </>
                }

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
        profileTypeData: state.profileType.profileTypeData
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ resetStore, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersCatogeries);