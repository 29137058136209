import {    
    GET_LIST_ALL_USER_SUCCESS,
    GET_LIST_ALL_USER_FAILURE,
} from "../actions/Types";

import redexState from "./InitalState";




// get All list users
export const users = (state = redexState.usersInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_USER_SUCCESS:
            return {
                ...state,
                ...action.payload.userInfo
            }
        case GET_LIST_ALL_USER_FAILURE:
            return {
                ...state,
            }
        default:
            return state
    }

}