/**
 * @about folder view file that create and contains folders
 * create new folder
 * 
 */

// react lib
import React, { Component } from 'react';

// folder section
import FolderSection from "../add_folder_common/AddFolder";


class SiteFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <FolderSection
                {...this.props}
                titleName="Sites"
                folderType="2"
                link = "sites"
            />
        );

    }
}

export default SiteFolder;
