/**
 * @about resources services
 * get resources
 * view resources
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg==" 


// add resources by admin
export const _addResources = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_RESOURCE,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

// delete resources by admin
export const _deleteResources = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_CHANGE_STATUS_RESOURCE,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// Get resources by admin by admin
export const _getResources = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_GET_RESOURCE,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}






// view resources by admin
export const _viewResources = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",  
            url: `${Url.ADMIN_VIEW_RESOURCE}?resourceId=${request.resourceId}`,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add resources by admin
export const _editResources = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_EDIT_RESOURCE,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}