
/**
 * @about app js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import ProjectSiteList from "./ProjectSiteList";
import ProjectSiteEdit from "./ProjectSiteEdit";
import SiteData from "./ProjectSiteImport";



class ReportFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() {
        return (
            <Switch>
                <Route exact path={`/projects/edit/:folderId/:id/sites`} component={ProjectSiteList} />
                <Route exact path={`/projects/edit/:folderId/:id/sites/data`} component={SiteData} />
                <Route exact path={`/projects/edit/:folderId/:id/sites/:eId`} component={ProjectSiteEdit} />
            </Switch>
        );

    }
}

export default ReportFolder;

