
import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_LIST_ALL_PROJECT_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// api services
import { _addProject, _getProjectList } from "../../config/api/ProjectServices";
import { getTagForFIlters } from "./Tag"


// url
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";



// action for getAll users
export const getAllProjectList = req => dispatch => {
    dispatch({ type: IS_LOADING_START })
    getTagForFIlters({ tagType: req.tagType, folder: req.folder })(dispatch)
    _Api(Url.ADMIN_GET_PROJECT.method, Url.ADMIN_GET_PROJECT.url, req)
        .then(resp => {
            let payload = {
                projectInfo: resp.responseData
            }
            dispatch({ type: GET_LIST_ALL_PROJECT_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })

        })
}




// add new project
export const addNewProject = (req, req2) => dispatch => {
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_PROJECT.method, Url.ADMIN_ADD_PROJECT.url, req)
        .then(resp => {
            getAllProjectList(req2)(dispatch)
            dispatch({ type: IS_LOADING_STOP })
            setTimeout(() => { notify("success", resp.responseData.message) }, 300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}
