import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserIcon, PasswordIcon, AngleIcon } from '../Svg';
import { isEmail } from '../../utility';

import InlineError from "../common/InlineError"


class Success extends Component {
    state = {
        email: '',
        emailError: ''
    }





    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            emailError: ''
        })
    }




    handleValidate = () => {

        let { email } = this.state;


    }




    handleSubmit = e => {
        e.preventDefault();

        if (this.state.email == '')
            this.setState({ emailError: '*Please enter username or email.' })

        else {
            alert("send")
        }
    }

    render() {

        let { email } = this.state;

        return (
            <div className="auth_body_wrapper" style={{ backgroundImage: `url(${require('../../assets/img/login_bg.png')})` }}>
                <div className="container xl_container text-center">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <React.Fragment>
                                <div className="login_logo mb-5">
                                    <img src={require('../../assets/img/logo.png')} />
                                </div>
                                <p className="mb-12">
                                Success! You will receive detailed instructions on resetting your password to your
                                <h5>{this.props.location.state && this.props.location.state.state}</h5>
                                    email address in the shortest time possible.                                   
                              </p>

                                <div className="form-group">
                                    <Link className="btn btn-primary" to="/login">Back to sign in</Link>
                                </div>

                            </React.Fragment>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Success;