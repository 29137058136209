
/**
 * @about Routes js file contains routing of app
 * routing app
 * react-router-dom
 */
// react lib
import React, { Component } from 'react';
// roucting lib
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getProfileData } from "./redux/actions/User";
// create history
import { createBrowserHistory } from 'history';

// custome components & screens & pages
import Dashboard from "./components/dashboard/DashBoard";
import Projects from "./components/projects/Projects";
import Sites from "./components/sites/Sites";
import Resources from "./components/resources/Resources";

import ResourcesList from "./components/resources/ResourcesList";
import ResourcesEdit from "./components/resources/ResourcesEdit";
import Report from "./components/report/Report";
import ReportEdit from "./components/report/ReportEdit"
import Media from "./components/media/Media";
import Tags from "./components/tags/Tages";
import Login from './components/login/Login';
import ForgotPassword from './components/login/ForgotPassword';
import Success from "./components/login/Success";
import ReportList from "./components/report/ReportList";
import MediaList from "./components/media/MediaList";
import ResetPassword from "./components/login/ResetPassword"
import SiteList from "./components/sites/SiteList";
import SiteEdit from "./components/sites/SiteEdit";
import ProjectList from "./components/projects/ProjectList2"
import ProjectEdit from "./components/projects/ProjectEdit";
import ChangePassword from './components/login/ChangePassword';
import Client from "./components/client/Client";
import ClientList from "./components/client/ClientList";
import ClientDetails from "./components/client/ClientDetails";
import ClientListEdit from './components/client/ClientListEdit';







const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn === true ? (
          <Component {...rest} {...props} />
        ) : (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          )
      }
    />
  )
}





export const history = createBrowserHistory();

history.listen(({ pathname }) => {
  window.scrollTo(0, 0)
})

class Routes extends Component {


  componentDidMount(){
    let req = {
      profileType: 1

  }
    this.props.action.getProfileData(req)
  }
 
  render() {
    return (
      <Router history={history}>
        <Switch> ResetPassword
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:id" component={ResetPassword} />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/dashboard"
            component={Dashboard}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/changePassword"
            component={ChangePassword}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects"
            component={Projects}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/:id"
            component={ProjectList}
          />  {}
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/general"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/report"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/report/data"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/report/:eId"
            component={ProjectEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/resource"
            component={ProjectEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/resource/data"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/resource/:eId"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/media"

            component={ProjectEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/media/data"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/task"
            component={ProjectEdit}
          />
           <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/analysis"

            component={ProjectEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/task/edit/:cId"
            component={ProjectEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/map"
            component={ProjectEdit}
          />


          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/sites"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/sites/data"
            component={ProjectEdit}
          />



          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/projects/edit/:folderId/:id/sites/:eId"
            component={ProjectEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites"
            component={Sites}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/:id"
            component={SiteList}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id"

            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/general"

            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/report"
            component={SiteEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/report/data"
            component={SiteEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/report"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/report/:eId"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/resource"
            component={SiteEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/resource/:eId"
            component={SiteEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/media"

            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/media/:mId"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/media/data"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/client"

            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/client/data"

            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/map"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/client/:eId"
            component={SiteEdit}
          /> 
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/task"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/analysis"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/task/edit/:cId"
            component={SiteEdit}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/sites/edit/:folderId/:id/overview"
            component={SiteEdit}
          />



          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/resources/folders"
            component={Resources}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/resources/:id"
            component={ResourcesList}
          />

        


          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/resources/edit/:folderId/:id"
            component={ResourcesEdit}
          />    

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/reports/folders"
            component={Report}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/reports/:id"
            component={ReportList}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/reports/edit/:folderId/:id"
            component={ReportEdit}
          />

        <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients"
            component={Client}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id"
            component={ClientList}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/edit/:folderId/:id"
            component={ReportEdit}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id/:eId/details"
            component={ClientDetails}
          />
           <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:folderId/:id"
            component={ClientListEdit}
          />

            <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id/:eId/details/profile"
            component={ClientDetails}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id/:eId/details/project"
            component={ClientDetails}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id/:eId/details/users"
            component={ClientDetails}
          />

         <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/clients/:id/:eId/details/users/:eIdd"
            component={ClientDetails}
          />

          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/media"
            component={Media}
          />
          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/media/:id"
            component={MediaList}
          />


          <PrivateRoute
            exact
            loggedIn={localStorage.getItem("isLoggedIn") == "true"}
            path="/tags"
            component={Tags}
          />
       


          <Route path="/success" component={Success} />

        </Switch>
      </Router>
    )
  }
}

// get store data
const mapStateToProps = state => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.login.isLoggedIn,
    tagsList: state.tags.allTagsList
  }
}

// dispatch action
const mapDispatchToProps = dispatch => {
  return {
    action: bindActionCreators({ getProfileData }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

