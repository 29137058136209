import React, { Component } from 'react';

import InlineError from '../../components/common/InlineError';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { _changePassword } from '../../config/api/AuthApi'
import { notify } from '../../components/common/Toaster'
import Loader from '../../components/common/Loader'

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoader:false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
            err: ''
        }
    }


    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, oldPasswordError: '', newPasswordError: '', confirmPasswordError: '' })
    }


    handleChangePasswordSubmit = (e) => {


        let { oldPassword, newPassword, confirmPassword } = this.state
        if (this.state.oldPassword == '') {
            this.setState({ oldPasswordError: '*Please enter old password' })
        }
        else if (newPassword == '') {
            this.setState({ newPasswordError: '*Please enter new password' })
        }
        else if (confirmPassword == "") {
            this.setState({ confirmPasswordError: '*Please enter confirm password' })
        }
          else if (newPassword == oldPassword) {
            this.setState({ newPasswordError: 'New and old password can not be same' })
        }
        else if (newPassword.length < 5) {
            this.setState({ newPasswordError: ' Password must be  6 or 6 characters long' })
        }
       else if (newPassword !== confirmPassword) {
            this.setState({ confirmPasswordError: 'New password and confirm password does not match' })
        }
        else {
            this.setState({
                isLoader: true,
            })
            let req = {
                oldPass: oldPassword,
                password: newPassword
            }
            _changePassword(req)
                .then(resp => {
                    this.setState({
                        isLoader: false,
                    })
                    if (resp.responseData.message == "Old password does not match.") {
                        notify("err", resp.responseData.message)
                        this.setState({ isLoader: false })
                        // return

                    }
                    else  {
                        notify("success", resp.responseData.message)
                        this.props.history.goBack();
                    }
                   
                }).catch(err => {
                    this.setState({ isLoader: false })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })

            // } catch (err) {
            // this.setState({ isLoader: false })
            // notify("err", err.message)

            // }


        }
    }


    render() {
        return (
            <>
 {this.state.isLoader && <Loader/>}
             <SideNav />
                <div className="main-body">
                    <Header />
                    <div className="body_header folder"></div>



          

            <div className="body_container">
               
               

                <div className="cm_breadcumd">
                    <p>Change Password</p>
                </div>

                <div className="change_password">
                    <form className="cm_box_view">

                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">Old Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control ${this.state.oldPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="Enter Old Password"
                                    name="oldPassword"
                                    value={this.state.oldPassword}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.oldPasswordError}
                                />


                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">New Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control ${this.state.newPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="Enter New Password"
                                    name="newPassword"
                                    value={this.state.newPassword}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.newPasswordError}
                                />

                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">Confirm Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control ${this.state.confirmPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="Enter Confirm Password "
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.confirmPasswordError}
                                // message={this.state.err}
                                />
                            </div>
                        </div>
                        <br />
                        <button type="button" className="btn btn-primary" onClick={this.handleChangePasswordSubmit}>Save</button>

                    </form>
                </div>
            </div>
            </div>
            </>
        )
    }
}

export default ChangePassword;