

import React, { Component } from 'react';

import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';
import dateFormat from "dateformat"
import { Spinner } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../../redux/actions/User";
import { addNewSite, getAllSiteList } from "../../../redux/actions/Site";
import { addNewProject, getAllProjectList } from "../../../redux/actions/Project";
import { _actionForProject } from "../../../config/api/ProjectServices"

// loader
import Loader from "../../common/Loader";
// inline error
import InlineError from "../../common/InlineError";
// validation
import { validateEmail, validateMobileNo1 } from "../../../utils/Validation";

// services 
import { _actionForUser, _checkEmailAvaliblity, _getUsersList, _getProfileType } from "../../../config/api/UserService";
// services 
import { _actionForSite, } from "../../../config/api/SiteServices";
import { getAllTags, getAllFolders } from "../../../redux/actions/Get";


// notify
import { notify } from "../../common/Toaster";

import AddTag from "../../common/add_tag/AddTag"
import { Select, Icon } from 'antd';

import AddFolder from "../../add_folder_common/AddFolderTag";

import CheckboxTree from 'react-checkbox-tree';

import { getDataAndFilter } from "../../../utils/MyFilter"

// url & api services
import Url from "../../../config/env/BaseUrl";
import { _Api } from "../../../config/api/Api";




var actionArr = []
const { Option } = Select;

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this project',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,




            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',

            //validation fields  
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: [],

            mapContent: '',
            mapContentError: '',
            fileName: 'Choose file...',
            fileUploadFileError: '',
            coverImg: '',

            folderIdError: '',
            folderId: '',
            clientId: '',
            clientIdError: '',
            clientUserList: [],
            toggleState:false,
            nodeData:[],
            isSpinner:true,

        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getAllUserList({ status: "1", tagType: 4, folder: "" });
        this.props.setClick(this.openInviteNewPopupOpen);
       
        this.getDataProfileType();




    }



    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 2)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)
        let arr6 = await getDataAndFilter(resp.responseData.profileInfo, 6)


        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                isChecked: false,
                isOther: item1.levelName == "OTHER",
                inputValue: '',
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    isChecked: false,
                    isOther: data.levelName == "OTHER",
                    inputValue: '',
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        isChecked: false,
                        isOther: data2.levelName == "OTHER",
                        inputValue: '',
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            isChecked: false,
                            isOther: data3.levelName == "OTHER",
                            inputValue: '',
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                                isChecked: false,
                                isOther: data4.levelName == "OTHER",
                                inputValue: '',
                                children: arr6.filter(item6 => item6.parentLevel == data4._id).map((data5, index5) => ({

                                    value: `${data5._id} `,
                                    label: data5.levelName,
                                    isChecked: false,
                                    isOther: data5.levelName == "OTHER",
                                    inputValue: '',
                                }))
                            }))
                        }))
                    }))
                }))


            }
        ))



        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }


    handleProfileModal = () => {
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })
    }



    onSubmitCheck = () => {
  
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })

    }

    handleSecleted = (e) => {

        this.setState({ checked: e })
    }


    countEmailAvaliblity = (email) => {
        _checkEmailAvaliblity({ email })
            .then(resp => {

                this.setState({ emailAvaliblity: false, emailAvaliblityMessage: '' })

            }).catch(err => {

                this.setState({ emailAvaliblity: true, emailAvaliblityMessage: err.error.message })


            })
            .catch(err => { })
    }



    //handle on handleOnChange
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' });

    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
        let req = {
            folderType: 1
        }
        this.props.action.getAllFolders(req)
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
        this.props.closeModalAddTag &&
            this.props.closeModalAddTag(true)
    }

    // clear fields
    clearFields = () => {
        this.setState({
            userId: [],
            clientId: '',
            clientIdError: '',
            // clientUserList: [],
            folderIdError: '',
            // folderId:'',
            fileName: 'Choose file...',
            fileUploadFileError: '',
            coverImg: '', profileError: '',
            checked: [],
            mapContent: '', mapContentError: '', name: '', description: '', address: '', street: '', city: '', state: '', latitude: '', longitude: '', nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: ''
        })


        this.getDataProfileType()
    }

    // on submit form
    handleOnSubmitForm = (e) => {
        try {
            let { name, description, } = this.state;
            
            if (name == "") {
                this.setState({ nameError: "*Please enter name." })
            }
            else if (description == "") {
                this.setState({ descriptionError: "*Please enter description." })
            }
            else if (this.state.folderId == "") {
                this.setState({ folderIdError: "*Please select folder" })
            }
            else if (this.state.coverImg == "") {

                this.setState({ fileUploadFileError: "*Please add a  cover image.", })
            }

            else if (this.state.coverImg != "" && this.state.coverImg.type.split("/")[0] != "image") {

                this.setState({ fileUploadFileError: "*Please add a valid cover image.", })
            }
            else if (this.state.clientId == "") {
                this.setState({ clientIdError: "*Please select client." })

            }


            // else if (this.state.mapContent == "") {
            //     this.setState({ mapContentError: "*Please enter map content." })

            // }

            else if (this.state.checked.length == 0) {
                this.setState({ profileError: "*Please add profile." })

            }


            else {

                let formData = new FormData()
                formData.append("projectName", name);
                formData.append("desc", description)
                formData.append("folder", this.state.folderId)
                formData.append("clientId", this.state.clientId);
                formData.append("userIds", this.state.userId);
                formData.append("tag", this.state.tagId)
                formData.append("cover", this.state.coverImg)
                formData.append("profile", JSON.stringify(this.state.nodeData))

                let req2 = {

                }

                if (e.target.name == "save") {

                    this.props.action.addNewProject(formData, req2)
                    this.closeModal()
                    this.clearFields()

                }
                else {
                    this.props.action.addNewProject(formData, req2)
                    this.clearFields();
                }
            }
        }
        catch (err) {

        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this project" : status == 0 ? "Inactive this project" : "delete this project" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            projectId: [actionArrr].toString()
        }

        _actionForProject(req)
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })

           

                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditProject = (project) => {

        this.props.history.push(`/projects/edit/${this.props.match.params.id}/${project._id}/general`, { state: project });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())

    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.dataCount) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            folder: "",
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }

 



        this.props.action.getAllProjectList(req);
    }



    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }

    onImageUpload = e => {
        try {
            let file = e.target.files[0]
       
            this.setState({ coverImg: file, fileName: file.name, fileUploadFileError: '' })
        } catch (err) {

        }
    }




    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'firstName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = (e) => {
        try {
            if (e.target.name == "folder")
                this.clickChild1()
            else if (e.target.name == "tag")
                this.clickChild()
            this.setState({ inviteNewPopupOpen: false })
         
        }
        catch (err) { }

    }



    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }


    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
    


    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these project" : status == 0 ? "Inactive these project" : "delete these project" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.projectInfo &&
                    this.props.projectInfo.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }





    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }





    handleOnSelect = (data) => {
        if (this.state.userId.indexOf(data) == -1) {
            this.setState({ userId: [...this.state.userId, data] })

        }
        else if (this.state.userId.indexOf(data) != -1) {
            this.setState({ userId: this.state.userId.filter(item => item != data) })
        }

    }

    onSelectClients = (e) => {
        try {
            this.setState({ clientId: e.target.value, userId: [] }, () => {
                this.onSelectClientGetUsers()

            })

        } catch (err) {

        }

    }

    handleChangeMultiSelect = (value) => {

    }

    onSelectClientGetUsers = () => {
        try {
            if (this.state.clientId) {
                let req = { clientId: this.state.clientId, status: "1" }

                _getUsersList(req)
                    .then(resp => {
                        this.setState({ clientUserList: resp.responseData.usersInfo.userInfo.userInfo })

                    })
                    .catch(err => {

                    })
            }
        }
        catch (err) { }
    }



    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )
            
        }
        this.setState({toggleState:!this.state.toggleState}) 
        if( this.state.checked.indexOf(item.value) == -1)
        this.state.checked.push(item.value)      
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }







    render() {
        let { name, description, address, street, city, state, latitude, longitude, nameError, descriptionError, addressError, streetError, cityError, stateError, latitudeError, longitudeError } = this.state;

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        return (
            <>
                {/* {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}           */}
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />
                <AddFolder
                    folderType={1}
                    setClick={click => this.clickChild1 = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                <div>
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>
                        <Modal.Header closeButton>
                            {/* {/ <Modal.Title>Modal heading</Modal.Title> /} */}
                            <h6>Create New Project</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form >

                                <div class="form-group">
                                    <label for="=Name"> Name</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        maxLength={30}
                                        name='name'
                                        value={name}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={nameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="Description">Description</label><span className="impFields">*</span>
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                        id="reportDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name='description'
                                        value={description}
                                        onChange={this.handleChangeAll}

                                    />

                                    <InlineError
                                        message={descriptionError}
                                    />
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Folder</label><span className="impFields">*</span><br />
                                        <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                            {
                                                this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                    <Option value={item._id}>
                                                        <div class="dropdown_flex">
                                                            <div><img src={item.icon} /></div>
                                                            <div><p className="txtClassUpper" >{item.folderName}</p></div>
                                                        </div>
                                                    </Option>

                                                ))
                                            }

                                        </Select>
                                        <InlineError
                                            message={this.state.folderIdError}
                                        />

                                    </div>
                                    <div className="col-md-4"><button name="folder" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Folder</button></div>
                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Cover image</label><span className="impFields">*</span><br />
                                        <div class="custom-file">
                                            <input id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}

                                                accept="image/png,image/jpeg"
                                            />
                                            <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                        </div>
                                        <InlineError
                                            message={this.state.fileUploadFileError}
                                        />
                                    </div>

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Client</label><span className="impFields">*</span>
                                        <select
                                            className={`form-control ${this.state.clientIdError != '' ? 'is-invalid' : ''}`}
                                            id="sel1" name="clientId"
                                            onChange={this.onSelectClients}
                                        >
                                            {this.state.clientId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select client"}</option>}

                                            {
                                                this.props.usersInfo && this.props.usersInfo.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.clientName}</option>
                                                    )

                                                )
                                            }
                                        </select>
                                        <InlineError
                                            message={this.state.clientIdError}
                                        />
                                    </div>


                                </div>

                                <br />
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Users</label><br />
                                        <Select
                                            mode="multiple"
                                            placeholder="Please select client first"
                                            // defaultValue={}
                                            value={this.state.userId}
                                            onChange={this.handleChangeMultiSelect}
                                            onSelect={(i) => this.handleOnSelect(i)}
                                            onDeselect={(i) => this.handleOnSelect(i)}
                                        >
                                            {this.state.clientUserList && this.state.clientUserList.map((item, index) => (
                                                <Option className="txtClassUpper"  value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</Option>

                                            ))}
                                        </Select>
                                    </div>

                                </div>









                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleChangeAll}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option className="txtClassUpper"  key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" name="tag" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <div class="form-group">
                                    <button
                                        type="button"
                                        className={`btn btn-primary btn-block ${this.state.profileError != '' ? 'is-invalid' : ''}`}

                                        onClick={() => this.setState({ showProfileModal: true, inviteNewPopupOpen: false, profileError: '' })} >
                                        Add Profile
                                </button>
                                    <InlineError
                                        message={this.state.profileError}
                                    />
                                </div>                              


                                <br />
                                <div class="form-group text-center">

                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>


                            </form>
                        </Modal.Body>

                    </Modal>
                </div>


                <Modal show={this.state.showProfileModal} onHide={this.handleProfileModal} className="custom_modal treeview_modal" >
                    <Modal.Header closeButton>
                    <h6>Project Profile</h6>
                    
                    </Modal.Header>
                    <Modal.Body>
                    {
                            this.state.isSpinner  &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                        
                        
                        <Accordion defaultActiveKey="00000000">
                            {
                                Object.values(this.state.nodeData).map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" value = {item3.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item5}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item5}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                            {
                                                                                                                item5.children && item5.children.map((item6, index6) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item6}`} >
                                                                                                                                {item6.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item6.isChecked} onClick={() => this.handleCheckedBox(item6)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item6.label} </span>
                                                                                                                                {item6.isOther && item6.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item6.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item6)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item6}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>
                        <br />
                        <div class="form-group">
                            <button type="button" onClick={this.onSubmitCheck} name="save" class="btn btn-primary btn-blue " >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        <h6>Site Action</h6>

                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-default btn-sm mR-20" onClick={this.handleDeleteModal}></button>
                        <button class="btn btn-primary btn-sm btn-blue " onClick={this.handleActionModal}>Yes</button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        projectInfo: state.projects.projectInfo,
        activeProjectDetails: state.projects.activeProject,
        inActiveProjectDetails: state.projects.inactiveProject,
        totalProjectDetails: state.projects.totalProject,
        dataCount: state.projects.dataCount,
        usersInfo: state.users.userInfo,
        foldersList: state.folders.folderList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllFolders, addNewProject, getAllProjectList, getAllSiteList, getAllUserList, addNewSite, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
