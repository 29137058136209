/**
 * @about tags file that create tags and contains all tags that created by admin
 * all tags
 * create new tag
 * edit & delete tag
 */

// react lib
import React, { Component } from 'react';
// react bootstrap
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
// date lib
import dateFormat from "dateformat"
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { addNewTag } from "../../redux/actions/Add";
import { getAllTags } from "../../redux/actions/Get";

// custome components
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import InlineError from "../common/InlineError"
import Loader from "../common/Loader"
import { notify } from "../../components/common/Toaster";

// color picker
import { PhotoshopPicker } from 'react-color';

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";






var actionArr = []

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            deletTagId: '',
            isLoadingState: false,
            dataCount: 10,
            addTagPopupOpen: false,
            tagName: '',
            colorName: '#0efa1a',
            tagNameError: '',
            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            pageNo: 0,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            showDeleteModal: false,
            showColorPicker: false,
            isEdit: false,
            editTagId: '',
            stateLoading: false,

            statusState: 'Delete this tag',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,
            selectAll: false

        }
    }

    componentDidMount() {
        this.onClickPagenation();
    }


    // open add tag modal
    openAddTagPopup = () => {
        this.setState({
            addTagPopupOpen: !this.state.addTagPopupOpen,
            isEdit: false,
            tagName: '',
            colorName: '#0efa1a',
        })
    }


    // close modal
    closeModal = () => {
        this.setState({
            addTagPopupOpen: false,
            tagNameError: ''
        })
    }

    //clear fields
    clearFields = () => {
        this.setState({ tagName: '', colorName: '#0efa1a' })
    }

    // handle new tag
    handleAddTag = (e) => {
        if (this.state.tagName == '') {
            this.setState({ tagNameError: '*Please enter tag name.' })
        }

        else {

            let req = {
                tagName: this.state.tagName,
                color: this.state.colorName
            }

            let req2 = {
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo)
            }

            if (e.target.name == "save") {
                this.props.action.addNewTag(req, req2);
                this.closeModal()
                this.clearFields()
            }
            else {
                this.props.action.addNewTag(req, req2);
                this.clearFields()
            }
        }


    }


    // change tag status 
    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }
    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {
        try {
            if (page == -1 && this.state.pageNo > 0) {
                this.setState({ pageNo: --this.state.pageNo })

            }
            if (page == 1 && (this.props.tagsDataCount) >= this.state.pageNo)
                this.setState({ pageNo: ++this.state.pageNo })

            let req = {
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),

            }


            this.props.action.getAllTags(req);


        }
        catch (err) {


        }
    }

    //  on status 
    onClickChangeStatus = (actionArrr, tagStatus) => {
        this.setState({ showDeleteModal: false, stateLoading: true })

        try {

            let req = {
                tagId: [actionArrr].toString(),
                status: tagStatus
            }

            _Api(Url.ADMIN_CHANGE_TAG_STATUS.method, Url.ADMIN_CHANGE_TAG_STATUS.url, req )
                .then(resp => {
                    actionArr = []
                    this.setState({ showDeleteModal: false, stateLoading: false, selectAll: false })


                    this.onClickPagenation()
                })
                .catch(err => {
                    actionArr = []

                    this.setState({ showDeleteModal: false, stateLoading: false, selectAll: false })

                })

        } catch (err) {

            this.setState({ showDeleteModal: false, stateLoading: false })

        }

    }

    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, tagNameError: '' });

    }




    // onClick Sort
    // firstName,lastName,email,password,countryCode,phoneNumber,
    // role,comName,address,site,folder,profilePic,tags,status,
    handleOnClickSort = (e) => {
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'tagName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }



    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this tag" : status == 0 ? "Inactive this tag" : "delete this tag" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }

    // on handle after select multiple tags
    handleActionModal = () => {
        this.onClickChangeStatus(actionArr, this.state.StatusAction)
    }


    // onEdit
    onEditClick = (tag) => {
        try {
            this.setState({ isEdit: true, tagName: tag.tagName, colorName: tag.color, editTagId: tag._id }, () => this.setState({ addTagPopupOpen: true }))

        } catch (err) {

        }

    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
    }

    // on color picker
    handleChangeComplete = (color, event) => {
        this.setState({ colorName: color.hex });
    };


    // onEdit submit
    handleAddTagOnEdit = () => {
        if (this.state.tagName == '') {
            this.setState({ tagNameError: '*Please enter tag name.' })
        }

        else {
            this.setState({ stateLoading: true })

            let req1 = {
                tagId: this.state.editTagId,
                tagName: this.state.tagName,
                color: this.state.colorName

            }

            _Api(Url.ADMIN_EDIT_TAG.method, Url.ADMIN_EDIT_TAG.url, req1 )
                .then(resq => {
                    this.setState({ stateLoading: false, addTagPopupOpen: false })
                    this.onClickPagenation()
                    notify("success", resq.responseData.message)
                })
                .catch(err => {
                    this.setState({ stateLoading: false })
                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })
                .catch(err1s => {
                    this.setState({ stateLoading: false })
                })




        }

    }

    handleChecked = (e) => {
        let num = e.target.value
        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }
    }


    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

    }


    // open on multiple select
    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these tags" : status == 0 ? "Inactive these tags" : "delete these tags" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })

        }

        )
    }


    // on select check box
    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.tagsList &&
                    this.props.tagsList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })
            }
        } catch (err) {

        }

    }


    render() {

        var pageRange = Math.ceil(this.props.tagsDataCount / this.state.dataCount)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.stateLoading && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header
                        isSearch={true}
                        onClickSearchTest={(e) => this.handleOnSearch(e)}
                    />
                    <div className="body_header folder">
                        <h6 className="headingH6">TAGS</h6>
                        <div className="new_btn text-right">
                            <button className="btn btn-primary btn-blue" onClick={this.openAddTagPopup}>Add New</button>
                        </div>
                    </div>
                    <div className="counter_detail">
                        <div className={`counter_parent ${this.state.activeClass == 'a' ? 'active' : ''}`} onClick={() => this.onChangeTabStstus("a")}>
                            <h4>ACTIVE</h4>
                            <h4>{this.props.tagsActive && this.props.tagsActive}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 'i' ? 'active' : ''}`} onClick={() => this.onChangeTabStstus("i")}>
                            <h4>INACTIVE</h4>
                            <h4 className="yellow">{this.props.tagsInactive && this.props.tagsInactive}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 't' ? 'active' : ''}`} onClick={() => this.onChangeTabStstus("t")}>
                            <h4>TOTAL</h4>
                            <h4 className="green">{this.props.tagsTotal && this.props.tagsTotal}</h4>
                        </div>

                    </div>


                    <hr />
                    {
                        actionArr && actionArr.length != 0 &&

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                                {actionArr.length} Select
                          </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu">
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "1")}>Active</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "0")}>Inactive</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "2")}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th> <input defaultChecked={false} type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                    <th>Sr No.</th>
                                    <th onClick={() => this.handleOnClickSort("firstName")} >Name <span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Color</th>
                                    <th onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i className={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Action</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.tagsList.map && this.props.tagsList.map((item, index) => (
                                        <tr key={index}>
                                            <td><input defaultChecked={false} type="checkbox" className="checkbox" name="time" value={item._id} checked={actionArr.indexOf(item._id) != -1} onClick={this.handleChecked} /></td>

                                            <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                            <td className="txtClassUpper" >{item.tagName}</td>
                                            <td><div style={{ backgroundColor: item.color }} className="color_circle"></div></td>
                                            <td>{dateFormat(item.created, "mm/dd/yyyy")}</td>
                                            <td>
                                                <div>
                                                    <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onEditClick(item)} >Edit</button>&nbsp;&nbsp;&nbsp;
                                                 <button type="button" className="btn btn-primary btn-sm btn-pink" onClick={() => this.handleDeleteModal(item._id, "2")}>Delete</button>
                                                </div>
                                            </td>


                                            <td>
                                                <button type="button" onClick={() => this.handleDeleteModal(item._id, item.status == "1" ? "0" : "1")} className={`btn btn-toggle ${item.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autoComplete="off">
                                                    <div className="handle"></div>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                        {
                            this.props.tagsDataCount == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="textContent"> Sorry no more content </p>
                        }
                        <div className="float-right">
                            {this.props.tagsDataCount >= 10 &&

                                <div className="pagination">
                                    <button className="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                    <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                    <button className="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.tagsDataCount / this.state.dataCount) <= this.state.pageNo + 1} ><i className="fa fa-chevron-right" aria-hidden="true" ></i></button>

                                </div>

                            }
                        </div>
                    </div>
                </div>

                <Modal show={this.state.addTagPopupOpen} onHide={this.closeModal} className="custom_modal">
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6 > {this.state.isEdit ? "EDIT TAG" : "ADD NEW"} </h6>
                    </Modal.Header>
                    <Modal.Body>
                        <form >

                            <div className="form-group">
                                <label for="email">Name</label><span className="impFields">*</span>
                                <input
                                    type="email"
                                    name="tagName"
                                    className={`form-control ${this.state.tagNameError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    placeholder="Enter Tag Name"
                                    value={this.state.tagName}
                                    maxLength={30}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.tagNameError}
                                />
                            </div>
                            <div >
                                <label for="pwd">Color</label><br />
                                {
                                    this.state.showColorPicker &&
                                    <PhotoshopPicker
                                        color={this.state.colorName}
                                        name="colorName"
                                        onChangeComplete={this.handleChangeComplete}
                                        onAccept={() => this.setState({ showColorPicker: false })}
                                        onCancel={() => this.setState({ showColorPicker: false })

                                        }

                                    />
                                }
                                <input
                                    type="button"
                                    name="colorName"
                                    style={{ backgroundColor: this.state.colorName }}
                                    value={this.state.colorName}
                                    className="buttonColorPicker col-sm-8"
                                    onChange={this.handleOnChange}
                                    onClick={() => this.setState({ showColorPicker: true })}
                                    readOnly
                                />

                                <i className="fa fa-eyedropper cm_eyedroper" onClick={() => this.setState({ showColorPicker: true })} aria-hidden="true"></i>
                            </div>
                            <br />
                            <div className="form-group">

                                {
                                    this.state.isEdit ?
                                        <button type="button" name="save" className="btn btn-primary btn-blue mR-20" onClick={this.handleAddTagOnEdit}>Save</button> :
                                        <>
                                            <button type="button" className="btn btn-default mR-20" onClick={this.handleAddTag} >Save & Add New</button>
                                            <button type="button" name="save" className="btn btn-primary btn-blue mR-20" onClick={this.handleAddTag}>Save</button>
                                        </>
                                }
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal" >
                    <Modal.Header closeButton>
                        <h6>Tag Action</h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button variant="primary" className="btn btn-primary btn-sm btn-blue " onClick={() => this.handleActionModal()}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>


            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        tagsDataCount: state.tags.dataCount,
        tagsInactive: state.tags.inactiveTag,
        tagsActive: state.tags.activeTag,
        tagsTotal: state.tags.totalTag,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ addNewTag, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
