import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { ProjectMenuIcon, PortfolioMenuIcon, AngleIcon, CamraIcon, EditIcon, PasswordIcon2, IIcon, HelpIcon, LogoutIcon } from '../Svg';
import {Search , Notification} from '../Svg';


// connect to store
import { connect } from "react-redux";
// bind actions
import { bindActionCreators } from "redux";

//actions
import { logOut } from "../../redux/actions/Auth";

// history
import {history} from "../../Routes";

class Header extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            toggleToDO:false
        }

    }

    // this function handle on click logout btn & log out the user
    handleOnLogOutClick = ()=>{
        this.props.action.logOut()
         history.push("/")
         this.setState({toggleToDO:this.state.toggleToDO})

    }
    componentWillMount(){

        navigator.sayswho= (function(){

            var ua= navigator.userAgent, tem,
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge?)\/(\d+)/);
                if(tem!= null){

            }}
            // ---for safari----
            M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);

            if(M.includes("Safari")){
                document.body.classList.add("safari");
            }

        })();



    }

    render() {
        return (
            <div className="min_height">
                    <div className="primary_menu">
                        <ul>
                           {this.props.isSearch && 
                           <> 
                           <li className="post_rel">
                               
                             <input type = "text" placeholder="Search" className="form-control" onChange = {this.props.onClickSearchTest}/>
                          
                           <span className="search_icon"><Search/></span>
                           </li>
                           </>
                           
                           }
                            <li><span className="menu_icon"></span></li>
                        </ul>
                    </div>

                    <div className="header_dropdown">
                        <Dropdown className="header_dropdown_btn">
                            <Dropdown.Toggle className="no_btn profile_btn" id="dropdown-basic">
                                <img src={require('../../assets/img/gallery.jpg')} />
                                {/* <span>John Doe <span className="down_angle"><AngleIcon /></span></span> */}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul className="profile_menu">
                                    {/* <li><Link to="#">Profile</Link></li> */}
                                    <li><Link to="/changepassword">Change Password</Link></li>
                                    <li onClick = {this.handleOnLogOutClick}><span>Logout</span></li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ logOut }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

