import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import Select from 'react-select';
import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';

//react router dom
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList } from "../../redux/actions/User";
import { getAllTags } from "../../redux/actions/Get";

// validation
import { validateEmail, validateMobileNo1 } from "../../utils/Validation";
// services
import { _viewUser } from "../../config/api/UserService";


// inline error
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader";

import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from "../../utils/MyFilter"

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";



class UserClientsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: true,
            inviteNewPopupOpen: false,
            selectedOption: '',
            created: "",
            email: "",
            firstName: "",
            folder: "",
            lastName: "",
            password: "",
            phoneNumber: "",
            profilePic: "",
            role: 1,
            status: 1,
            tags: "",
            _id: "",
            userEmailError: '',
            userFirstNameError: '',
            userLastNameError: '',
            userPhoneNumberError: '',
            showDeleteModal: false,
            userRole: '',
            folderName: '',
            showUserName: '',
            showTagNamee: '',

            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',
            toggleState:false,
            isSpinner:false,

        }
    }

    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' })
    }


    handleChange = selectedOption => {
        this.setState({ selectedOption });
    }

    componentDidMount() {
        this.props.action.getAllTags({ status: "1" })
        this.getUserDetails()

        // this.getDataProfileType()

    }


    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 3)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)


        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                            }))
                        }))
                    }))
                }))


            }
        ))



        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }




    // getUser details
    getUserDetails = () => {
        try {
            if (this.props.match.params)
                _Api(Url.ADMIN_VIEW_USER.method, Url.ADMIN_VIEW_USER.url, "", this.props.match.params.eIdd)
                    .then(resp => {
                        const { created, email, firstName, folder, lastName, password,
                            phoneNumber,
                            profilePic,
                            profile,
                            role,
                            status,
                            tags,
                            tagName,
                            folderName,
                            _id } = resp.responseData.userData
                        this.setState({
                            showTagNamee: tagName == undefined ? "" : tagName,
                            
                            // checked: profile,
                            checked: profile == undefined || profile.length == 0 ? [] : profile,
                            nodeData:profile,
                            isSpinner:false,

                            showUserName: resp.responseData.userData.firstName, isLoadingState: false, folderName, created, email, firstName, folder, lastName, password, phoneNumber, role, status, tags, _id
                        })

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }





    handleOnSubmitForm = (e) => {
        if (this.state.firstName == "") {
            this.setState({ userFirstNameError: "*Please enter first name." })
        }
        else if (this.state.lastName == "") {
            this.setState({ userLastNameError: "*Please enter last name." })
        }
        else if (this.state.email == "") {
            this.setState({ userEmailError: "*Please enter email." })
        }
        else if (this.state.phoneNumber == "") {
            this.setState({ userPhoneNumberError: "*Please enter phone number." })
        }
        else if (!validateMobileNo1(this.state.phoneNumber).status) {
            this.setState({ userPhoneNumberError: "*Please enter valid phone number." })
        }
        else {

            this.onEditUser()
        }
    }



    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletUserId: id });
    }


    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (statusCode) => {
        try {
            this.setState({ showDeleteModal: false, isLoadingState: true })
            let req = {
                status: statusCode,
                activeId: this.state._id
            }

            _Api(Url.ADMIN_ACTION_FOR_USER.method, Url.ADMIN_ACTION_FOR_USER.url, req)
                .then(resp => {
                    this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '' })
                    notify("success", "User delete successfully")
                    this.props.history.goBack();

                }).catch(err => {
                    this.setState({ isLoadingState: false, showDeleteModal: false })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)
                })

        } catch (error) {

        }
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    // close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    // on edit submit user
    onEditUser = () => {
        this.setState({ isLoadingState: true, })

        try {
            let req = {
                userId: this.state._id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                tags: this.state.tags,
                profile: this.state.nodeData,
                role: this.state.role


            }
            _Api(Url.ADMIN_EDIT_USER.method, Url.ADMIN_EDIT_USER.url, req)
                .then(resp => {
                    notify("success", "User edit successfully")
                    // this.getUserDetails()
                    this.setState({ isLoadingState: false, })
                    this.props.history.goBack();


                }).catch(err => {
                    this.setState({ isLoadingState: false, })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })


        } catch (err) {

        }
    }

    handleSecleted = (e) => {
        this.setState({ checked: e })
    }


    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )
            
        }
        this.setState({toggleState:!this.state.toggleState})       
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }


    render() {
        const { selectedOption } = this.state;
        return (
            <>
                {this.state.isLoadingState && <Loader />}

                <div className="col-sm-12">
                    {/* <a href="#" > <i class="fa fa-pencil float-right edit_pencil" aria-hidden="true"></i></a> */}
                </div>
                <div className="row">
                    <div className="col-sm-5 ">
                        <form >
                            <div class="form-group">
                                <label for="email">First Name</label><span className="impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.userFirstNameError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    name="firstName"
                                    placeholder="First Name"
                                    maxLength={20}
                                    value={this.state.firstName}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.userFirstNameError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">Last Name</label><span className="impFields">*</span>
                                <input type="text"
                                    className={`form-control ${this.state.userLastNameError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    name="lastName"
                                    maxLength={20}
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    onChange={this.handleOnChange}
                                />
                                <InlineError
                                    message={this.state.userLastNameError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">Email</label><span className="impFields">*</span>
                                <input type="email"
                                    className={`form-control ${this.state.userEmailError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    name="email"
                                    maxLength={50}
                                    placeholder="Email"
                                    // onChange={this.handleOnChange}
                                    value={this.state.email}
                                    readOnly
                                />
                                <InlineError
                                    message={this.state.userEmailError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">Phone Number</label><span className="impFields">*</span>
                                <input type="tel"
                                    className={`form-control ${this.state.userPhoneNumberError != '' ? 'is-invalid' : ''}`}
                                    id="email"
                                    maxLength={20}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleOnChange}
                                    required
                                />
                                <InlineError
                                    message={this.state.userPhoneNumberError}
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">Tag</label>
                                <select class="form-control" id="sel1" name="tags" onChange={this.handleOnChange}>
                                    {this.state.showTagNamee ? <option key={-2} selected hidden value={this.state.reportTagId} >{this.state.showTagNamee}</option> : <option key={-1} value={"select"} selected hidden  >{"Select tag"}</option>}
                                    {
                                        this.props.tagsList && this.props.tagsList.map((item, index) =>
                                            (
                                                <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                            )
                                        )
                                    }
                                </select>
                            </div>


                            <br />
                            <div class="form-group">
                                <button type="button" class="btn btn-danger mR-20" onClick={this.handleDeleteModal}>Delete</button>
                                <button type="button" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                            </div>

                        </form>

                    </div>
                    <div className="col-sm-5 offset-sm-1">
                        <h6>User Profile</h6>
                        <Accordion defaultActiveKey="00000000">
                            {
                                this.state.nodeData.map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" value = {item3.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>

                    </div>
                </div>


                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want delete this user ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button className="btn btn-primary btn-sm btn-blue " onClick={() => this.onUserAction("2")}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllUserList, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserClientsView);

