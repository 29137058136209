
import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_LIST_ALL_SITE_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// api services
import { _addSites, _getSitesList } from "../../config/api/SiteServices";
import { getTagForFIlters } from "./Tag"

import { _getReports } from "../../config/api/GetServices"

// url
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";


// get site list
export const getAllSiteList = req => dispatch => {
    try {
        dispatch({ type: IS_LOADING_START })
        getTagForFIlters({ tagType: req.tagType, folder: req.folder })(dispatch)
        _Api(Url.ADMIN_GET_SITE.method, Url.ADMIN_GET_SITE.url, req)
            .then(resp => {
                let payload = {
                    siteInfo: resp.responseData
                }
                dispatch({ type: GET_LIST_ALL_SITE_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })
            }).catch(err => {

                dispatch({ type: IS_LOADING_STOP })

            })
    } catch (err) {

    }

}


// add new site
export const addNewSite = (req, req2) => dispatch => {
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_SITE.method, Url.ADMIN_ADD_SITE.url, req)
        .then(resp => {
            getAllSiteList(req2)(dispatch)
            dispatch({ type: IS_LOADING_STOP })
            setTimeout(() => { notify("success", resp.responseData.message) }, 300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}
