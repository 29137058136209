
import {
    IS_LOADING_START,
    IS_LOADING_STOP,

    GET_LIST_MEDIA_REPORT_SUCCESS,
    GET_LIST_MEDIA_REPORT_FAILURE,
} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";


// api services
import {_addMedia, _getMediaList} from "../../config/api/MediaServices";


// url
import Url from "../../config/env/BaseUrl";
import {_Api} from "../../config/api/Api";


// action for getMedia list
export const getAllMediaList = req => dispatch => {
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_MEDIA.method, Url.ADMIN_GET_MEDIA.url, req)
        .then(resp => {
            
            let payload = {
                mediaInfo: resp.responseData
            }
            dispatch({ type: GET_LIST_MEDIA_REPORT_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })

        }).catch(err => {

            dispatch({ type: IS_LOADING_STOP })

        })

}




// add media
export const addNewMedia = (req, req2) => dispatch => {   
    dispatch({ type: IS_LOADING_START })
    _Api(Url.ADMIN_ADD_MEDIA.method, Url.ADMIN_ADD_MEDIA.url, req)
        .then(resp => {
            getAllMediaList(req2)(dispatch)
            dispatch({ type: IS_LOADING_STOP })
           setTimeout(()=>{ notify("success", resp.responseData.message)},300)
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

        })

}
