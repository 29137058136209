import React, { Component } from 'react';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { addNewMap } from "../../redux/actions/Map";
import { getAllTags } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
// inline error
import InlineError from "../common/InlineError";
// notify
import { notify } from "../common/Toaster";
// loader
import Loader from "../common/Loader";
import renderHTML from 'react-render-html';

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




class SiteMapView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: false,
            mapName: '',
            mapNameError: '',
            mapSite: '',
            mapTag: '',
            coordinates: [],
            tags: '',
            layerType: 'poline',
            siteId: '',
            mapContent: '',
            mapContentError: '',
            isEdited:false



        }
    }

    //handle on handleOnChange
    handleOnChange = (e) => {
      
        this.setState({ [e.target.name]: e.target.value, mapNameError: '', mapContentError: '' })
    }


    handleChange = selectedOption => {
        this.setState({ selectedOption });
     
    }

    componentDidMount() {

        this.props.action.getAllTags()
        this.props.action.getAllSiteList();
        


        this.setState({showUserName:this.props.location.state && this.props.location.state.state})
        this.getSiteDetails();

    }
    getSiteDetails = () => {
        try {
            if (this.props.match.params)
            _Api(Url.ADMIN_VIEW_SITE.method, Url.ADMIN_VIEW_SITE.url, "",this.props.match.params.id  )
                    .then(resp => {
       
                        const {                        
                           code,
                            _id } = resp.responseData.siteData

                        this.setState({
                          mapContent: code == undefined ? "" : code, _id
                        }, () => this.setState({ isLoadingState: false }))

                    })
                    .catch(err => {
                     

                    })

        } catch (error) {

        }
    }

    onSaveSite = () => {

        try {
            const {
                name,
                description,
                address,
                street,
                city,
                state,
                latitude,
                longitude,
                userId,
                user,
                _id, challanges,
                strengths,
                mapContent

            } = this.state




            if (false) {
                // this.setState({ mapContentError: "*Please enter map content." })
            }
            // else if (description == "") {
            //     this.setState({ descriptionError: "*Please enter description." })
            // }

            // else if (address == "") {
            //     this.setState({ addressError: "*Please enter address." })
            // }
            // // else if (street == "") {
            // //     this.setState({ streetError: "*Please enter street." })
            // // }
            // else if (city == "") {
            //     this.setState({ cityError: "*Please enter city." })
            // }
            // else if (state == "") {
            //     this.setState({ stateError: "*Please enter state." })
            // }
            // else if (longitude == "") {
            //     this.setState({ longitudeError: "*Please enter longitude." })
            // }
            // else if (latitude == "") {
            //     this.setState({ latitudeError: "*Please enter latitude." })
            // }

            else {
                this.setState({ isLoadingState: true })
                let req = {
                   
                    siteId: this.state._id,
                   code:this.state.mapContent,
                }
                let formData = new FormData()
                formData.append("siteId", this.state._id);         
                formData.append("code", this.state.mapContent) 

                _Api(Url.ADMIN_EDIT_SITE.method, Url.ADMIN_EDIT_SITE.url, formData  ).then(resp => {
                    notify("success", resp.responseData.message)
                    this.setState({ isLoadingState: false, isEdited:false})
                }).catch(err => {
                 
                    this.setState({ isLoadingState: false, })
                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)
                })
            }

        } catch (err) {
            this.setState({ isLoadingState: false })

        }




    }








    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletUserId: id });
    }



    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    // on sketch complete
    onMapSketch = (data) => {
        if (data.type == "point") {

            let req = {
                layerType: data.type,
                lat: data.latitude,
                lng: data.longitude,
                centerMap: [data.latitude, data.longitude]
            }


            this.setState({ coordinates: req })


        }
        if (data.type == "polygon") {

            let req = {
                layerType: data.type,
                centerMap: [data.rings[0][0][0], data.rings[0][0][1]],
                coordinates: data.rings
            }
            this.setState({ coordinates: req })

     



        }
     


    }


    render() {
        const { selectedOption } = this.state;
        return (
            <>
                {this.state.isLoadingState && <Loader />}
                
            
                    
                    <div className="counter_detail">
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12">
                        {
                            this.state.isEdited &&
                            <div class="form-group">
                            <label for="email">Map content</label> 
                            <textarea rows="4" cols="50"
                                className={`form-control ${this.state.mapContentError != '' ? 'is-invalid' : ''}`}
                                id="email"

                                placeholder="Map content"
                                name="mapContent"
                                autocomplete="off"
                                value={this.state.mapContent}
                                onChange={this.handleOnChange}
                            />
                            <InlineError
                                message={this.state.mapContentError}
                            />
                            <div class="form-group" style={{marginTop:10}}>
                                    <button type="button" class="btn btn-danger mR-20" onClick={()=> this.setState({isEdited:false})}>Cancel</button>
                                    <button type="button" class="btn btn-primary btn-blue " onClick={this.onSaveSite}>Save</button>
                                </div>
                          

                        </div>
                        }

                            <div className="cm_map_wrapper">
                               {  <h5>Map preview {!this.state.isEdited && <i onClick={()=> this.setState({isEdited:true})} class="fa fa-pencil edit_record" aria-hidden="true"></i>}</h5>}

                                <div className='app'>
                                    {renderHTML(this.state.mapContent)}
                                </div>
                            </div>

                            <br />
                            

                    
                        </div>
                    </div>
            
            </>
        );
    }
}


// get store data
const mapStateToProps = state => {

    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
        siteInfo: state.sites.siteInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ addNewMap, getAllTags, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapView);

