/**
 * @about this file contains side nav bar
 */
// react
import React, { Component } from 'react';
import { Dashboard, Projects, Sites, Users, Cummunity, Resources, Reports, Media, Sifter, Mapmanagement, Tags, Rules } from '../Svg';

// react router dom
import { NavLink } from "react-router-dom"

class SideNav extends React.Component {
  constructor(props) {
    super(props)
  }

  handleMenuOpen = () => document.body.classList.toggle('menu_open')
  handleCloseMenu = () => document.body.classList.remove('menu_open')

  componentDidMount() {
  }

  render() {

    return (
      <>
        <div className="mobile_trigger" onClick={this.handleMenuOpen}>
          <i className="fa fa-bars"></i>
          <i className="cross" aria-hidden="true">X</i>
        </div>
        <div className="menuRespo">
          <div className="sidenav">
            <img src={require('../../../src/assets/img/logo.png')} />
             <div className="sidenav_categories">
              <NavLink to="/dashboard" className=""><Dashboard />Dashboard</NavLink>
              <NavLink to="/clients" className=""><Cummunity />Clients</NavLink>
              <NavLink to="/projects" ><Projects />Projects</NavLink>
              <NavLink to="/sites" ><Sites />Sites</NavLink>
              {/* <NavLink to="/communities" ><Cummunity />Communities</NavLink> */}
              {/* <NavLink to="/users/categories" ><Users />Users</NavLink> */}
              <NavLink to="/resources/folders" ><Resources />Resources</NavLink>
              <NavLink to="/reports/folders" > <Reports /> Reports</NavLink>
              <NavLink to="/media" ><Media /> Media</NavLink>
              <NavLink to="/tags" > <Tags />Tags</NavLink>
            </div>
          </div>
        </div>
      </>
    );

  }
}

export default SideNav;
