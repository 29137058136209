/**
 * @about site edit file edit site that already created
 * 
 */

// lib
import React, { Component } from 'react';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// errror show
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";
// site edit screen
class SiteEditOverView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: false,
            inviteNewPopupOpen: false,
            chips: [],
            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',
            tagId: '',
            challanges: '',
            strengths: '',
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',
            user: []
        }

    }


    onChange = chips => {
        this.setState({ chips });
    }


    componentDidMount() {
        
        this.getSiteDetails();
    }

    // getUser details
    getSiteDetails = () => {
        try {
            if (this.props.match.params)
            _Api(Url.ADMIN_VIEW_SITE.method, Url.ADMIN_VIEW_SITE.url, "",this.props.match.params.id  )
                    .then(resp => {
                       
                        const {
                            created,
                            firstName,
                            address,
                            city,
                            desc,
                            folder,
                            lastName,
                            password,
                            phoneNumber,
                            role,
                            street,
                            status,
                            state,
                            tags,
                            user,
                            lat,
                            long,
                            challanges,
                            strengths,
                            _id } = resp.responseData.siteData

                        this.setState({
                            challanges,
                            strengths,
                            latitude: lat,
                            longitude: long,
                            address,
                            city,
                            state,
                            street,
                            description: desc,
                            user, showUserName: resp.responseData.siteData.siteName, name: resp.responseData.siteData.siteName, isLoadingState: false, folderName: folder.folderName, created, firstName, folder, lastName, password, phoneNumber, role, status, tags, _id
                        }, () => this.setState({ isLoadingState: false }))

                    })
                    .catch(err => {
                        

                    })

        } catch (error) {

        }
    }

    //handle on handleChangeAll
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '' });

    }

    // on submit
    onSaveSite = () => {

        try {
            const {
                name,
                description,
                address,
                street,
                city,
                state,
                latitude,
                longitude,
                userId,
                user,
                _id, challanges,
                strengths,

            } = this.state

            


            if (name == "") {
                this.setState({ nameError: "*Please enter site name." })
            }
            else if (description == "") {
                this.setState({ descriptionError: "*Please enter description." })
            }

            // else if (address == "") {
            //     this.setState({ addressError: "*Please enter address." })
            // }
            // // else if (street == "") {
            // //     this.setState({ streetError: "*Please enter street." })
            // // }
            // else if (city == "") {
            //     this.setState({ cityError: "*Please enter city." })
            // }
            // else if (state == "") {
            //     this.setState({ stateError: "*Please enter state." })
            // }
            // else if (longitude == "") {
            //     this.setState({ longitudeError: "*Please enter longitude." })
            // }
            // else if (latitude == "") {
            //     this.setState({ latitudeError: "*Please enter latitude." })
            // }

            else {
                this.setState({ isLoadingState: true })
                // let req = {
                //     siteName: name,
                //     siteId: this.state._id,
                //     desc: description,
                //     tag: this.state.tagId,
                //     project: '',
                //     user: [this.state.userId],
                //     street: street,
                //     city: city,
                //     state: state,
                //     lat: latitude,
                //     long: longitude,
                //     address: address,
                //     challanges,
                //     strengths,
                // }

                // this.setState({ isLoadingState: true })
                let formData = new FormData()               
                formData.append("siteId", this.state._id);               
                formData.append("desc", description)          
                formData.append("challanges", challanges)
                formData.append("strengths", strengths)
                // formData.append("code", this.state.mapContent) 
                formData.append("projectType", 'S')


                _Api(Url.ADMIN_EDIT_SITE.method, Url.ADMIN_EDIT_SITE.url,formData  ).then(resp => {
                    notify("success", resp.responseData.message)
                    this.setState({ isLoadingState: false, })
                   this.goBack()


                }).catch(err => {
                    
                    this.setState({ isLoadingState: false, })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })



                
            }


        } catch (err) {
            this.setState({ isLoadingState: false })

        }




    }



    // redirect to
    goBack = () => {
        this.props.history.push(`/sites/${this.props.match.params.folderId}`, { state: this.state.folderName });
    }
    // adding popup
    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })

    }
    render() {
        return (
            <>
            {this.state.isLoadingState && <Loader/>}
                <div className="row">
                    <div className="col-sm-8">
                        <form>
                            <div class="form-group">
                                <label for="email"><b>Description</b></label> <span className="impFields">*</span><br />
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder="Enter description"
                                    maxLength={256}
                                    name='description'
                                    value={this.state.description}
                                    onChange={this.handleChangeAll}

                                />

                                <InlineError
                                    message={this.state.descriptionError}
                                />
                            </div><br />

                            <div class="form-group">
                                <label for="email"><b>Strengths</b></label><br />
                                <textarea
                                    rows="5" cols="100"
                                    class="form-control" name="strengths"
                                    form="usrform"
                                    value={this.state.strengths}
                                    onChange={this.handleChangeAll}
                                />
                            </div>
                            <br />

                            <div class="form-group">
                                <label for="email"><b>Challenges</b></label><br />
                                <textarea
                                    rows="5"
                                    cols="100"
                                    class="form-control"
                                    name="challanges" form="usrform"
                                    value={this.state.challanges}
                                    onChange={this.handleChangeAll}
                                />
                            </div>
                            <div class="form-group float-right">
                                <button type="button" class="btn btn-pink mR-20" onClick={this.goBack}>Exit</button>
                                <button type="button" class="btn btn-primary btn-blue" onClick={this.onSaveSite}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteEditOverView);