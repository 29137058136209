/**
 * @about this file , create users, users list ,
 * 
 * 
 */

// react
import React from 'react';
import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
// date lib
import dateFormat from "dateformat"
// react router
import { Link } from 'react-router-dom';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { getAllTags } from "../../redux/actions/Get";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";
// custome components
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import Loader from "../common/Loader";
import { notify } from "../../components/common/Toaster";
import InlineError from "../../components/common/InlineError";
import AddTag from "../../components/common/add_tag/AddTag"
import { validateEmail, validateMobileNo1 } from "../../utils/Validation";
import { getTagForFIlters } from "../../redux/actions/Tag";
import { Filter, Rightarrow } from '../Svg';
import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from "../../utils/MyFilter"
import { resetStore } from "../../redux/actions/Common";


const arrrr = [1, 2, 3, 4, 5, 6, 7, 8, 9]

var actionArr = []

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',
            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this client',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,
            toggle: false,
            selectAll: false,
            sortTag: '',

            showTagName: 'All Tag',
            showTagColor: '#ffffff',
            dataShow: false,

            roleType: '',
            roleTypeError: '',

            showProfileModal: false,
            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',

            checkedArr: [],
            expendArr: [],
            toggleState:false,
            isSpinner:true,
        }
    }

    handleSetExpendArray1 = (index) => {
        if (this.state.expendArr.indexOf(index) == -1) {
            this.setState({ expendArr: [...this.state.expendArr, index] }, () => {
                console.log("arrayyyyyyyyyyyExpenn", this.state.expendArr);
            })

        }
        else {

            this.setState({ expendArr: this.state.expendArr.filter(item => item != index) })
        }



    }


    componentDidMount() {
        this.onClickPagenation()
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getTagForFIlters({ tagType: 3, folder: this.props.match.params.id })
        this.getDataProfileType()
    }
    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 1)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)

        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                isChecked: false,
                isOther: item1.levelName == "OTHER",
                inputValue: '',
                label: item1.levelName,
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({
                    value: `${data._id}`,
                    isChecked: false,
                        isOther: data.levelName == "OTHER",
                    inputValue: '',
                    label: data.levelName,
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({
                        value: `${data2._id} `,
                        isChecked: false,
                        isOther: data2.levelName == "OTHER",
                        inputValue: '',
                                label: data2.levelName,
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({
                            value: `${data3._id} `,
                            isChecked: false,
                            inputValue: '',
                                        isOther: data3.levelName == "OTHER",
                            label: data3.levelName,
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({
                                value: `${data4._id} `,
                                isChecked: false,
                                inputValue: '',
                                isOther: data4.levelName == "OTHER",
                                 label: data4.levelName,
                            }))
                        }))
                    }))
                }))


            }
        ))



        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }



    countEmailAvaliblity = (email) => {
        _Api(Url.ADMIN_CHECK_EMAIL.method, Url.ADMIN_CHECK_EMAIL.url, "", email)
            .then(resp => {

                this.setState({ emailAvaliblity: false, emailAvaliblityMessage: '' })

            }).catch(err => {

                this.setState({ emailAvaliblity: true, emailAvaliblityMessage: err.error.message })


            })
            .catch(err => { })
    }



    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, roleTypeError: '', userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '', profileError: "" });
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }


    // open invite user popup
    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }


    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            userPhoneNumber: '',
            userEmailError: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumberError: '',
            roleType: '',
            tagId: '',
            roleTypeError: '',
            checked: []



        })

        this.getDataProfileType()


    }

    // on submit form
    handleOnSubmitForm = (e) => {
        if (this.state.userFirstName == "") {
            this.setState({ userFirstNameError: "*Please enter  name." })
        }
        else if (this.state.roleType == "") {
            this.setState({ roleTypeError: "*Please select client type." })
        }

        else if (this.state.userLastName == "") {
            this.setState({ userLastNameError: "*Please enter contact name." })
        }



        else if (this.state.userEmail == "") {
            this.setState({ userEmailError: "*Please enter email." })
        }
        else if (!validateEmail(this.state.userEmail).status) {
            this.setState({ userEmailError: "*Please enter valid email." })

        }

        else if (this.state.userPhoneNumber == "") {
            this.setState({ userPhoneNumberError: "*Please enter phone number." })
        }
        else if (this.state.emailAvaliblity) {
            this.setState({ userEmailError: this.state.emailAvaliblityMessage })


        }
        else if (!validateMobileNo1(this.state.userPhoneNumber).status) {
            this.setState({ userPhoneNumberError: "*Please enter valid phone number." })

        }
        else if (this.state.checked.length == 0) {
            this.setState({ profileError: "*Please add profile." })

        }

        else {

            let req = {
                clientName: this.state.userFirstName,
                contactName: this.state.userLastName,
                email: this.state.userEmail,
                phoneNumber: this.state.userPhoneNumber,
                role: this.state.roleType,
                folder: this.props.match.params.id,
                tags: this.state.tagId,
                profile: this.state.nodeData,
            }

            let req2 = {
                folder: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
                tag: this.state.sortTag,
                tagType: 4,
            }

            this.props.action.addNewUser(req, req2)
            this.clearFields()


            if (e.target.name == "save")
                this.closeModal()

        }
    }

    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this client" : status == 0 ? "Inactive this client" : "delete this client" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal, selectAll: false })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            activeId: [actionArrr].toString()
        }

        _Api(Url.ADMIN_ACTION_FOR_USER.method, Url.ADMIN_ACTION_FOR_USER.url, req)
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })



                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditUser = (user) => {

        this.props.history.push(`/clients/${this.props.match.params.id}/${user._id}`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.state.dataCount) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            folder: this.props.match.params.id,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
            tag: this.state.sortTag,

            tagType: 3,
            // role:"2"
        }
        this.props.action.getAllUserList(req);

    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'clientName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })

    }



    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }


    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }


    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these clients" : status == 0 ? "Inactive these clients" : "delete these clients" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.usersInfo &&
                    this.props.usersInfo.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })
            }

        } catch (err) {

        }

    }


    sortByTag = (item) => {

        this.setState({
            sortTag: item._id,
            showTagName: item.tagName,
            showTagColor: item.color
        }, () => this.onClickPagenation())

    }

    handleRedirect = (user) => {
        // :id/:eId/details/profile
        this.props.history.push(`/clients/${this.props.match.params.id}/${user._id}/details/profile`)
    }

    handleProfileModal = () => {
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })
    }
    onSubmitCheck = () => {
        this.setState({ showProfileModal: false, inviteNewPopupOpen: true })

    }

    // to re set folder & re set store that pre data not show
    componentWillUnmount() {
        this.props.action.resetStore();
    }

    handleSecleted = (e) => {

        this.setState({ checked: e })
    }




    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked

        

        
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )

            
            
        }
        this.setState({toggleState:!this.state.toggleState})
        if( this.state.checked.indexOf(item.value) == -1)
        this.state.checked.push(item.value)       
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }

    render() {

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)
        setTimeout(() => this.setState({ dataShow: true }), 4000)

        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header
                        isSearch={true}
                        onClickSearchTest={(e) => this.handleOnSearch(e)}
                    />
                    <div className="body_header folder">
                        <h6 className="headingH6" > <Link to="/clients">Clients </Link> &nbsp;

                        <span className="cm_arrow"><Rightarrow /></span>
                            &nbsp; {this.props.location.state && this.props.location.state.state}</h6>
                        <div className="new_btn text-right">
                            <button className="btn btn-primary btn-blue" onClick={this.openInviteNewPopupOpen}>Add New Client</button>
                        </div>
                    </div>
                    <div className="counter_detail">
                        <div className={`counter_parent ${this.state.activeClass == 'a' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("a")}>
                            <h4>ACTIVE</h4>
                            <h4>{this.props.activeUserDetails && this.props.activeUserDetails}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 'i' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("i")}>
                            <h4>INACTIVE</h4>
                            <h4 className="yellow">{this.props.inActiveUserDetails && this.props.inActiveUserDetails}</h4>
                        </div>

                        <div className={`counter_parent ${this.state.activeClass == 't' ? 'active' : ''} `} onClick={() => this.onChangeTabStstus("t")}>
                            <h4>TOTAL</h4>
                            <h4 className="green">{this.props.totalUserDetails && this.props.totalUserDetails}</h4>
                        </div>
                        <Dropdown className="filter_dropdown" alignRight>
                            <Dropdown.Toggle className="ext-css" variant="success" id="dropdown-basic"   >
                                <>
                                    <div style={{ backgroundColor: this.state.showTagColor }} className="filter_dropdown_option"></div>
                                    &nbsp;&nbsp;
                                   <div className="tagText">{this.state.showTagName}</div>
                                </>

                            </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu" alignRight>
                                <>
                                    <Dropdown.Item onSelect={() => this.sortByTag({ _id: '', tagName: 'All Tag', color: '#ffffff' })}  >
                                        <div className="filter_dropdown_parent" >

                                            {/* <div style={{ backgroundColor: 'white' }} className="filter_dropdown_option"></div> */}
                                            &nbsp;&nbsp;
                                                <div>All Tag
                                                </div>
                                        </div>

                                    </Dropdown.Item>

                                </>
                                {
                                    this.props.filterTagData && this.props.filterTagData.map((item, index) => (
                                        <>
                                            <Dropdown.Item  >
                                                <div className="filter_dropdown_parent" onClick={() => this.sortByTag(item)}>

                                                    <div style={{ backgroundColor: item.color }} className="filter_dropdown_option"></div>
                                                    &nbsp;&nbsp; <div>{item.tagName}</div>
                                                </div>

                                            </Dropdown.Item>

                                        </>

                                    ))
                                }

                            </Dropdown.Menu>
                        </Dropdown>

                    </div>

                    <hr />
                    {
                        actionArr && actionArr.length != 0 &&

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                                {actionArr.length} Select
                          </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu">
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "1")}>Active</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "0")}>Inactive</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "2")}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                    <>
                                        {/* <div style={{ backgroundColor: this.state.showTagColor }} className="filter_dropdown_option"></div>
                                    &nbsp;&nbsp;
                                <div className="tagText">{this.state.showTagName}</div> */}
                                    </>
                                    <th>Sr No.</th>
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("firstName")}>Client Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Type</th>
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("email")} >Email<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleEmail ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Phone Number</th>
                                    <th className="cur_pointer" onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i className={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Tag</th>
                                    <th>Action</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.props.usersInfo &&
                                    this.props.usersInfo.map((user, index) => (
                                        <tr key={index}>

                                            <td><input type="checkbox" className="checkbox" name="time" value={user._id} checked={actionArr.indexOf(user._id) != -1} onClick={this.handleChecked} /></td>
                                            

                                            <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                            <td onClick={() => this.handleRedirect(user)} className="txtClassUpper cur_pointer">{`${user.clientName}`}</td>
                                            <td className="txtClassUpper">{`${user.role == 2 ? "Comprehensive" : "Individual"}`}</td>
                                            {/* <td><Link to={`/user-profile/${this.props.match.params.id}/${user._i}` }>{`${user.firstName} ${user.lastName}`}</Link></td> */}
                                            <td>{user.email}</td>
                                            <td>{user.phoneNumber}</td>
                                            <td>{dateFormat(user.created, "mm/dd/yyyy")}</td>
                                            <td>
                                                {
                                                    user.tagName == undefined ?
                                                        <>
                                                            <i className="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                            &nbsp;
                                                                                <i className="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                            &nbsp;
                                                                                <i className="fa fa-window-minimize" aria-hidden="true">
                                                            </i>
                                                        </>
                                                        :
                                                        <div className="display-flex">
                                                            <div style={{ backgroundColor: user.color }} className="color_circle"></div>
                                                            &nbsp;&nbsp;
                                                                                <div>{user.tagName}</div>
                                                        </div>
                                                }
                                            </td>
                                            <td>
                                                <div>
                                                    <button type="submit" className="btn btn-primary btn-sm btn-blue" onClick={() => this.handleRedirect(user)}>View</button>&nbsp;&nbsp;&nbsp;
                                                    {/* <button type="submit" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onClickEditUser(user)}>Edit</button>&nbsp;&nbsp;&nbsp; */}
                                                    {/* <button type="submit" className="btn btn-primary btn-sm btn-pink" onClick={() => this.handleDeleteModal(user._id, "2")}>Delete</button> */}
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" onClick={() => this.handleDeleteModal(user._id, user.status == "1" ? "0" : "1")} className={`btn btn-toggle ${user.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autoComplete="off">
                                                    <div className="handle"></div>
                                                </button>
                                            </td>

                                            {/* <td>{user.status == 1 ? <span className="cm_active" onClick = {()=> this.onUserAction("0", user._id)}>Active</span> : <span style={{ color: 'red' }} className="cm_active" onClick = {()=> this.onUserAction("1", user._id)}>InActive</span>}</td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                        {
                            this.state.dataShow && this.props.dataCount == 0 && this.props.isLoading != true && <p className="cm_center_text" > Sorry no more content </p>
                        }





                        <div className="float-right">
                            {this.props.dataCount >= 10 &&
                                <div className="pagination">
                                    <button className="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                    <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                    <button className="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.dataCount / this.state.dataCount) <= this.state.pageNo + 1} ><i className="fa fa-chevron-right" aria-hidden="true" ></i></button>

                                </div>

                            }
                        </div>
                    </div>

                </div>
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                <div>
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal">
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                            <h6>Invite New</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={this.handleOnSubmitForm}>

                                <div className="form-group">
                                    <label for="email">Client Name </label><span className="impFields">*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${this.state.userFirstNameError != '' ? 'is-invalid' : ''}`}
                                        id="userFirstName"
                                        name="userFirstName"
                                        placeholder="Client Name"
                                        maxLength={20}
                                        value={this.state.userFirstName}
                                        autoComplete="off"
                                        onChange={this.handleOnChange}
                                        required

                                    />
                                    <InlineError
                                        message={this.state.userFirstNameError}
                                    />
                                </div>
                                <div className="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Type</label><span className="impFields">*</span>
                                        <select
                                            className={`form-control ${this.state.roleTypeError != '' ? 'is-invalid' : ''}`}
                                            id="sel1" name="roleType" onChange={this.handleOnChange}>
                                            {this.state.roleType == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select type"}</option>}
                                            <option value={1}>{"Individual"}</option>
                                            <option value={2}>{"Comprehensive"}</option>
                                        </select>
                                        <InlineError
                                            message={this.state.roleTypeError}
                                        />
                                    </div>
                                    {/* <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div> */}

                                </div>
                                {/* <br /> */}
                                <div className="form-group">
                                    <label for="email">Contact Name </label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userLastNameError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Contact Name"
                                        maxLength={20}
                                        name="userLastName"
                                        autoComplete="off"
                                        required
                                        value={this.state.userLastName}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.userLastNameError}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="email">Contact Email </label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userEmailError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        maxLength={50}
                                        placeholder="Contact Email"
                                        autoComplete="off"
                                        required
                                        name="userEmail"
                                        value={this.state.userEmail}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userEmailError}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="email">Contact Phone Number</label><span className="impFields">*</span>
                                    <input type="tel"
                                        className={`form-control ${this.state.userPhoneNumberError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        required
                                        maxLength={20}
                                        placeholder="Contact Phone Number"
                                        name="userPhoneNumber"
                                        autoComplete="off"
                                        value={this.state.userPhoneNumber}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userPhoneNumberError}
                                    />
                                </div>                               
                                <div className="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select className="form-control" id="sel1" name="tagId" onChange={this.handleOnChange}>
                                            {this.state.tagId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>}
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" className="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className={`btn btn-primary btn-block ${this.state.profileError != '' ? 'is-invalid' : ''}`}

                                        onClick={() => this.setState({ showProfileModal: true, inviteNewPopupOpen: false, profileError: '' })} >
                                        Add Profile
                                </button>
                                    <InlineError
                                        message={this.state.profileError}
                                    />
                                </div>

                                <div className="form-group">
                                    <button type="button" className="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Invite & Invite New</button>
                                    <button type="button" name="save" className="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Invite</button>
                                </div>

                            </form>
                        </Modal.Body>

                    </Modal>
                </div>


                <Modal show={this.state.showProfileModal} onHide={this.handleProfileModal} className="custom_modal treeview_modal" >
                    <Modal.Header closeButton>
                        <h6>Client Profile</h6>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p>Industry/Sector</p> */}
                        {/* <CheckboxTree
                            nodes={this.state.nodeData}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(e) => this.handleSecleted(e)}
                            onExpand={expanded => this.setState({ expanded })}
                        /> */}
                    
                    {
                            this.state.isSpinner  &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                        
                        <Accordion defaultActiveKey="00000000">
                            {
                                this.state.nodeData.map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input placeholder = "Enter here..." type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" placeholder = "Enter here..." value = {item3.inputValue} className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" placeholder = "Enter here..." value = {item4.inputValue} className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" placeholder = "Enter here..." value = {item5.inputValue} className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>

                        <br />
                        <div className="form-group">
                            <button type="button" onClick={this.onSubmitCheck} name="save" className="btn btn-primary btn-blue " >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal" >
                    <Modal.Header closeButton>
                        <h6>User Action</h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>

                            No
          </button>
                        <button variant="primary" className="btn btn-primary btn-sm btn-blue " onClick={this.handleActionModal}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
        activeUserDetails: state.users.activeUser,
        inActiveUserDetails: state.users.inactiveUser,
        filterTagData: state.tags.filterInfo,
        totalUserDetails: state.users.totalUser,
        dataCount: state.users.dataCount
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ resetStore, getTagForFIlters, getAllUserList, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

