import {
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAILURE,
    GET_LIST_ALL_TASK_SUCCESS,
    GET_LIST_ALL_TASK_FAILURE 

} from "../actions/Types";

import redexState from "./InitalState";




// get All list users
export const tasks = (state = redexState.taskInitalState, action) => {
    switch (action.type) {
      
        case GET_LIST_ALL_TASK_SUCCESS:
            return {
                ...state,
                ...action.payload.taskInfo
            }
        case GET_LIST_ALL_TASK_FAILURE:
            return {
                ...state,
            }
        default:
            return {...state}
    }

}