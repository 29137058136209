
/**
 * @about add services api file that contains add services function
 * add user
 * add tags
 * add folders
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="


// add tags by admin
export const _addUser = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_USER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}





// add tags by admin
export const _addTags = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_TAG,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })


}

export const _editTag = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_EDIT_TAG,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

} 


// change status tags by admin
export const _changeTagStatus = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_CHANGE_TAG_STATUS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

} 

 



// add folder by admin
export const _addFolder = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_FOLDER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

// add folder by admin
export const _editFolder = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_EDIT_FOLDER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add report by admin
export const _addReport = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_REPORT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// delete report by admin
export const _deleteReport = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_CHANGE_STATUS_REPORT,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}






// delete folder by admin
export const _deleteFolder = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_DELETE_FOLDER,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}



// add multiple Items
export const _addMultiplesItem = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_ADD_ITEMS,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

