/**
 * @about auth api file that contains login function
 * that get data from servers
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="



// login 
export const _login = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_LOGIN_URL,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}





// forgot password 
export const _forgotPassword = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_FORGOT_PASSWORD,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// check reset password token
export const _checkValidToken = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.ADMIN_CHECK_TOKEN,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:request.accessToken
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}




// forgot password 
export const _resetPassword = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.ADMIN_RESET_PASSWORD,
            data: request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:request.accessToken
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}

//change password

export const _changePassword = (request) => {
    return new Promise((resolve, reject) => {
    axios({
    method: 'POST',
    url: Url.ADMIN_CHANGE_PASSWORD,
    data: request,
    headers: {
    "Authorization": authKey,
    'content-type': 'application/json',
    accessToken: localStorage.getItem("accessToken")
    }
    }).then(resp => {
    if (resp.data.statusCode)
    resolve(resp.data)
    else
    reject(resp.data)
    }).catch(err => reject(err))
    })
    }

