

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions

import { addNewResource, getAllResourcesList } from "../../redux/actions/Resources";
import { getAllTags } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
import { resetStore } from "../../redux/actions/Common";


// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
import AddTag from "../../components/common/add_tag/AddTag"
import { CopyToClipboard } from 'react-copy-to-clipboard';
// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




var actionArr = []

class ResourceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,

            tagId: '',
            showDeleteModal: false,

            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,

            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError: '',
            emptyFileError: '',

            statusState: 'Delete this resource',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,
            selectAll: false,

            fileUploadFileError: '',





        }
    }

    componentDidMount() {

        this.onClickPagenation();
        this.props.action.getAllTags();
        this.props.action.getAllSiteList();
        // this.totalPageCount()

    }

    // count pages
    totalPageCount = () => {
        try {

            this.setState({ totalNextPage: Math.floor(this.props.totalUserDetails / this.state.dataCount) })

        } catch (err) {

        }
    }



    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            reportNameError: '',
            reportDescriptionError: '',
            reportUrlError: '',
            emptyFileError: '',


        });
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({
            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError: '',
            fileUploadError: '',
            urlError: '',
            statusOk: false,
            emptyFileError: '',


        })

    }

    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
    
        this.setState({ reportFile: file, fileUploadFileError: '' })
    }

    // on submit form
    handleOnSubmitForm = (e) => {
        const UrlRegx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    
        if (this.state.reportName == "") {
            this.setState({ reportNameError: "*Please enter resource name." })
        }
        else if (this.state.reportDescription == "") {
            this.setState({ reportDescriptionError: "*Please enter description.." })
        }
        else if (this.state.reportUrl != "" && !UrlRegx.test(this.state.reportUrl)) {
            this.setState({ reportUrlError: "*Please enter valid url." })
        }
        else if (!this.state.reportFile) {
            this.setState({ fileUploadFileError: "*Please add a file or url" })
        }

        else if (this.state.reportFile.type.split("/")[0] != "text" && this.state.reportFile.type.split("/")[0] != "application") {


            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile.type.split("/")[1] == "exe" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage") {


            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile.type.split("/")[1] == "zip" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage") {

            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }


        else {

            

            let formData = new FormData()
            formData.append("resourceName", this.state.reportName);
            formData.append("desc", this.state.reportDescription)
            formData.append("folder", this.props.match.params.id)
            formData.append("url", this.state.reportUrl);
            formData.append("project", this.state.reportProjectId) 
            formData.append("tag", this.state.reportTagId)
            formData.append("fileData", this.state.reportFile)
            formData.append("site", this.state.reportSiteId) 





            let req2 = {
                folder: this.props.match.params.rId,
                site: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
            }


            if (e.target.name == "save") {

                this.props.action.addNewResource(formData, this.props.match.params.id, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewResource(formData, this.props.match.params.id, req2)
                this.clearFields();
            }
        }
    }

    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this resource" : status == 0 ? "Inactive this resource" : "delete this resource" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


   


    //edit user
    onClickEditUser = (resource) => {
          this.props.history.push(`${this.props.match.params.id}/${resource._id}/edit`, { state: resource });

    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.props.resourceData) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            unassigned:1,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }

        this.props.action.getAllResourcesList(req);
    }


    // on search
    handleOnSearch = (e) => {

        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }

    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click
    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'resourceName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })
        
    }

    handleChecked = (e) => {
        let num = e.target.value
        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }
    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
    unCheck = () => {
        var x = document.getElementsByClassName("checkbox");
        
        // for(let i=0; i<=x.length; i++) {
        //    x[i].checked = false;
        //  }   
    }


    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these resources" : status == 0 ? "Inactive these resources" : "delete these resources" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }

    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.resourceList &&
                    this.props.resourceList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }


    handleTOAddItems = ()=>{
        try{
            let req= {
                dataId:actionArr.toString(),
                assignId:this.props.match.params.id,
                assignType:'S',
                dataType:2

            }
            _Api(Url.ADMIN_ADD_ITEMS.method, Url.ADMIN_ADD_ITEMS.url, req )
            .then(rsp=>{
                this.setState({isLoadingState:false, selectAll:false})
                
                actionArr = [];

               this.props.history.goBack();


            })
            .catch(err=>{
        
            })
            
        }catch(err){

        }
    }

 // to re set folder & re set store that pre data not show
 componentWillUnmount() {
    this.props.action.resetStore();
}




    render() {
        // this.totalPageCount() 
        var pageRange = Math.ceil(this.props.resourceData / this.state.dataCount)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
              
    
                   
                    <div className="body_header">
                        {/* <h6 className="headingH6"> <Link to="/resources/folders">Resources</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}
                        
                        <div className="new_btn text-right btn_parent">
                            <div className="btn_child">
                            <button className="btn btn-primary btn-blue" onClick={()=> this.props.history.goBack()}>Back</button>
                            </div>
                        </div>
                    </div>
                    <div className="counter_detail">                     
                    {
                         
                            actionArr && actionArr.length !=0 &&
    
                            <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                            {actionArr.length} Select
                            </Dropdown.Toggle>
    
                            <Dropdown.Menu className="action_menu">
                                <Dropdown.Item onSelect={this.handleTOAddItems}>Add to site</Dropdown.Item>                           
                            </Dropdown.Menu>
                        </Dropdown>                         
                        
                    }
                    </div>

        
                    <div className="table-responsive">
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                    <th>Sr No.</th>
                                    <th onClick={() => this.handleOnClickSort("report")}>Report Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Created By </th>
                                    <th>Type</th>
                                    <th onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                    <th>Action</th>
                            

                                </tr>
                            </thead>
                            <tbody>

                                {

                                    this.props.resourceList &&
                                    this.props.resourceList.map((resource, index) => (
                                        <tr key={index}>
                                            <td><input type="checkbox" className="checkbox" name="time" value={resource._id} checked={actionArr.indexOf(resource._id) != -1} onClick={this.handleChecked} /></td>
                                            <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                            <td    className="txtClassUpper">{`${resource.resourceName}`}</td>
                                            {/* <td><Link to={`/resource-profile/${this.props.match.params.id}/${resource._i}` }>{`${resource.firstName} ${resource.lastName}`}</Link></td> */}
                                            <td>{"Admin"}</td>
                                            <td>{resource.fileType}</td>
                                            <td>{dateFormat(resource.created, "mm/dd/yyyy")}</td>
                                            <td>
                                                <div>
                                                    {/* <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onClickEditUser(resource)}>Edit</button>&nbsp;&nbsp;&nbsp; */}
                                                    {/* <button type="button" className="btn btn-primary btn-sm btn-pink" onClick={() => this.handleDeleteModal(resource._id, "2")}>Delete</button> &nbsp;&nbsp;&nbsp; */}


                                                  {
                                                        resource.fileType == "url" ? <CopyToClipboard text={resource.fileData}
                                                            onCopy={() => notify("success", "copied")}>
                                                            <button className="btn  btn-sm btn-default">Copy</button>
                                                        </CopyToClipboard> :
                                                            <a href={resource.fileData} className="btn  btn-sm btn-default" download>Export</a>
                                                    }


                                                </div>
                                            </td>
                                            {/* <td>
                                                <button type="button" onClick={() => this.handleDeleteModal(resource._id, resource.status == "1" ? "0" : "1")} class={`btn btn-toggle ${resource.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autocomplete="off">
                                                    <div class="handle"></div>
                                                </button>
                                            </td> */}


                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                        {
                            this.props.resourceData == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="cm_center_text" > Sorry no more content </p>
                        }



                        <div className="float-right">
                            {this.props.resourceData >= 10 &&
                                <div class="pagination">
                                    <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                    <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                    <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.resourceData / this.state.dataCount) <= this.state.pageNo + 1} ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>

                                </div>

                            }
                        </div>
                    </div>
                

                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />


                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>

                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6>Add New</h6>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={this.handleOnSubmitForm}>
                         

                            <div class="form-group">
                                <label for="email">Resource Name</label><span className="impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.reportNameError != '' ? 'is-invalid' : ''}`}
                                    id="reportName"
                                    name="reportName"
                                    placeholder="Enter resource Name"
                                    maxLength={30}
                                    value={this.state.reportName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.reportNameError}
                                />
                            </div>


                            <div class="form-group">
                                <label for="email">Description</label><span className="impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.reportDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder="Enter description"
                                    maxLength={256}
                                    name="reportDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.reportDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.reportDescriptionError}
                                />
                            </div>


                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">URL</label>
                                    <input
                                        type="url"
                                        className={`form-control ${this.state.reportUrlError != '' ? 'is-invalid' : ''}`}
                                        id="reportUrl"
                                        placeholder="Enter url"
                                        maxLength={256}
                                        name="reportUrl"
                                        autocomplete="off"
                                        required
                                        value={this.state.reportUrl}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.reportUrlError}
                                    />

                                </div>
                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <InlineError
                                        message={this.state.emptyFileError}
                                    />
                                    <label className="text-center display-b" >OR</label>

                                    <input type="file"
                                        //  accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf,.excel, image/*"
                                        accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        className={`form-control ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}
                                        ref="fileUploader"
                                        onChange={this.onImageUpload} />


                                    <InlineError
                                        message={this.state.fileUploadFileError}
                                    />
                                    {/* <div className="col-md-4"></div> */}

                                    {/* </div>
                                <button type="button" onClick={() => this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-4">Browse</button>
                            </div> */}
                                </div>
                                <div className="col-md-4"><button type="button" onClick={() => this.refs.fileUploader.click()} class="btn btn btn-default P1016 col-sm-12">Browse</button></div>

                            </div>
                            <div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Project</label>
                                        <select class="form-control" id="sel1" name="reportProjectId" onChange={this.handleOnChange}>
                                            {/* {
                                            this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.tagName}</option>
                                                )




                                            )
                                        } */}
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12" >Add Project</button></div>

                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Sites</label>
                                        <select class="form-control" id="sel1" name="reportSiteId" onChange={this.handleOnChange}>
                                        <option key={-1} value={"select"} disabled selected hidden  >{"Select site"}</option>
                                        {
                                            this.props.siteInfo && this.props.siteInfo.map((item, index) =>
                                                (
                                                    <option key={index} value={item._id}>{item.siteName}</option>
                                                )
                                            )
                                        }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12" >Add Sites</button></div>

                                </div>


                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="reportTagId" onChange={this.handleOnChange}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="submit" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <br />
                                <div class="form-group">
                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>

               

            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        resourceList: state.resources.resourceInfo,
        resourceActive: state.resources.activeresource,
        resourceInactive: state.resources.inactiveResource,
        resourceTotal: state.resources.totalResource,
        resourceData: state.resources.dataCount
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getAllTags, addNewResource, getAllResourcesList, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList);

